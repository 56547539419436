import { ListClinic } from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';

const clinicService = () => {
  const list = async (params: {
    search?: string;
    limit?: number;
    skip?: number;
  }) => {
    return ChiroUpAPI.get('api', `/clinics`, {
      queryParams: params,
    });
  };

  const findOne = async (ID?: string): Promise<ListClinic> => {
    const res = await ChiroUpAPI.get('api', `/clinics/${ID}`, {});
    return res || {};
  };

  const update = async (clinic: Partial<ListClinic>) => {
    const { ID, ...restOfClinic } = clinic;
    const res = await ChiroUpAPI.put('api', `/clinics/${ID}`, {
      body: restOfClinic,
    });
    return res || {};
  };

  const del = (clinicID: string) => {
    return ChiroUpAPI.del('api', `/clinic/${clinicID}`, {});
  };

  const create = async (val: Partial<ListClinic>) => {
    const res = await ChiroUpAPI.post('api', `/clinics`, {
      body: val,
    });
    return res || {};
  };

  const listIcons = async () => {
    const res = await ChiroUpAPI.get('api', `/icons`, {});
    return res;
  };

  return { list, findOne, update, del, create, listIcons };
};

export default clinicService();
