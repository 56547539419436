import React, { ReactNode, useState } from 'react';

type Props = {
  main: ReactNode;
  additional?: ReactNode;
};

const Filters: React.FC<Props> = ({ main, additional }) => {
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);

  const toggleAdditionalFilters = () => {
    setShowAdditionalFilters((prev) => !prev);
  };

  return (
    <>
      <div className="flex-col pt-2 pb-2 bg-white dark:bg-darkGray-900">
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
          {main}
        </div>
        {showAdditionalFilters && (
          <div className="flex space-x-4 pt-4">{additional}</div>
        )}
      </div>
      {!!additional && (
        <span
          onClick={toggleAdditionalFilters}
          className="text-primary-600 hover:text-primary-500 flex justify-end text-sm cursor-pointer"
        >
          {showAdditionalFilters ? (
            <>Less filters &uarr;</>
          ) : (
            <>More filters &darr;</>
          )}
        </span>
      )}
    </>
  );
};

export default Filters;
