import { OpenClosedStates } from '@chiroup/components';
import { useOnClickOutside } from '@chiroup/hooks';
import { Transition } from '@headlessui/react';
import React, { useRef, useState } from 'react';

type Props = {
  className?: string;
  options: {
    onClick: () => void;
    icon: React.ReactNode;
    text: string;
    className?: string;
  }[];
};

const MoreButton: React.FC<Props> = ({ className = '', options }) => {
  const [dropdownState, setDropdownState] = useState<OpenClosedStates>(
    OpenClosedStates.Closed,
  );
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setDropdownState(OpenClosedStates.Closed));

  const toggleDropdown = () => {
    setDropdownState((v) =>
      v === OpenClosedStates.Open
        ? OpenClosedStates.Closed
        : OpenClosedStates.Open,
    );
  };

  const closeDropdown = () => {
    setDropdownState(OpenClosedStates.Closed);
  };

  return (
    <div
      ref={ref}
      className={['relative inline-block text-left', className].join(' ')}
    >
      <button
        id="project-options-menu-0"
        type="button"
        className="w-8 h-8 inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition ease-in-out duration-150"
        onClick={toggleDropdown}
      >
        <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
        </svg>
      </button>
      <Transition
        show={dropdownState === OpenClosedStates.Open}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="z-10 rounded-md bg-white ring-1 ring-black ring-opacity-5"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="project-options-menu-0"
          >
            <div
              className="py-1 z-50"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {options.map((option, i) => (
                <div
                  key={i}
                  className={[
                    'group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 cursor-pointer',
                    option.className,
                  ].join(' ')}
                  role="menuitem"
                  onClick={() => {
                    closeDropdown();
                    option.onClick?.();
                  }}
                >
                  {option.icon}
                  {option.text}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default MoreButton;
