import React from 'react';
import { Route, Routes } from 'react-router-dom';
import FormList from './FormList';

type Props = {
  user?: any;
};

const FormsLibrary = ({ user }: Props) => {
  return (
    <Routes>
      <Route path="/" element={<FormList user={user?.user} />} />
      <Route path={`/:type`} element={<FormList user={user?.username} />} />
      <Route path="*" element={<span>no match</span>} />
    </Routes>
  );
};

export default FormsLibrary;
