import { Input, OpenClosedStates, Textarea } from '@chiroup/components';
import { Form } from '@chiroup/core';
import { useForm } from '@chiroup/hooks';
import { ChangeEvent, useContext, useState } from 'react';
import { ToastContext, ToastTypes } from '../../../contexts/toast.context';
import formService from '../../../services/form.service';
import Button, { ButtonColors } from '../../common/Button';
import SlideOver from '../../common/SlideOver';

type Props = {
  slideOverState: OpenClosedStates;
  updateSlideOverState: (val: OpenClosedStates) => void;
  defaultValues?: Partial<Form>;
  setCurrentForm?: (val: Partial<Form>) => void;
  user?: any;
  close: () => void;
  refetch: () => void;
};

const validation = {
  title: {
    required: {
      message: 'Title is required',
    },
  },
  category: {
    required: {
      message: 'Category is required',
    },
  },
  type: {
    required: {
      message: 'Type is required',
    },
  },
  file: {
    required: {
      message: 'File is required',
    },
  },
};

const FormEditSlideOverPanel = ({
  slideOverState,
  updateSlideOverState,
  defaultValues,
  setCurrentForm,
  user,
  close,
  refetch,
}: Props) => {
  const { value, onChange, patchValue, registerSubmit, isDirty, errors } =
    useForm<Partial<Form>>(defaultValues || {}, validation);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { createToast } = useContext(ToastContext);

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);
    try {
      const files = e.target.files;
      if (!files?.length) {
        return;
      }
      const file = files[0];
      const res = await formService.initiateUploadAttachment(
        '0',
        file.type,
        value.file ? value.file.split('/').pop() : null,
      );
      const payload = res?.signedUrlResponse;
      const form: FormData = new FormData();

      for (const field in payload.fields) {
        form.append(field, payload.fields[field]);
      }
      form.append('Content-Type', file.type);
      form.append('file', file);

      await fetch(payload.url, {
        method: 'POST',
        body: form,
      });
      const url = `${payload.url}/${payload.fields.key}`;
      patchValue({ file: url });
      setIsUploading(false);
    } catch (err) {
      console.error(err);
      setIsUploading(false);
    }
  };

  const onSubmit = async (values: Partial<Form>) => {
    setIsUpdating(true);
    if (values.ID) {
      await formService.updateForm(values.ID, values);
    } else {
      await formService.createForm(values, user);
    }
    setIsUpdating(false);
  };

  const onSuccess = () => {
    createToast({
      title: `Successfully ${value.ID ? 'updated' : 'created'} the form!`,
      description: '',
      type: ToastTypes.Success,
      duration: 5000,
    });
    refetch();
    close();
  };

  const onFail = () => {
    createToast({
      title: `Failed to ${value.ID ? 'updated' : 'created'} the form`,
      description: '',
      type: ToastTypes.Fail,
      duration: 5000,
    });
  };

  return (
    <SlideOver
      title="Edit form"
      slideOverState={slideOverState}
      updateSlideOverState={updateSlideOverState}
      buttons={
        <>
          <Button
            text="Close"
            onClick={() => updateSlideOverState(OpenClosedStates.Closed)}
            color={ButtonColors.plain}
          />
          <Button
            text="Save"
            disabled={!isDirty || isUpdating}
            loading={isUpdating}
            onClick={registerSubmit(onSubmit, {
              onSuccess: onSuccess,
              onFail: onFail,
            })}
          />
        </>
      }
    >
      <div className="mt-3 text-center sm:mt-5 p-6">
        <div className="mt-2 text-left">
          <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-300">
            <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5">
              <Input
                name="title"
                label="Title"
                onChange={onChange('title')}
                className="col-span-4"
                value={value.title}
                errors={errors?.fieldErrors?.title}
              />
              <Input
                name="category"
                label="Category"
                onChange={onChange('category')}
                className="col-span-2"
                value={value.category}
                errors={errors?.fieldErrors?.category}
              />
              <Input
                name="type"
                label="Type"
                onChange={onChange('type')}
                className="col-span-2"
                value={value.type}
                errors={errors?.fieldErrors?.type}
              />

              <Textarea
                name="descr"
                label="Description"
                onChange={onChange('descr')}
                className="col-span-4"
                value={value.descr}
                errors={errors?.fieldErrors?.descr}
              />
              {value.file && (
                <div className="col-span-1 font-medium flex items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>
                  <a href={value.file} target="_blank" rel="noreferrer">
                    Form
                  </a>
                </div>
              )}

              <Button
                text={value.file ? 'Replace file' : 'Upload file'}
                loading={isUploading}
                color={ButtonColors.primary}
                fileUpload
                onSelectFile={onSelectFile}
              />
            </div>
          </div>
        </div>
      </div>
    </SlideOver>
  );
};

export default FormEditSlideOverPanel;
