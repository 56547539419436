import { Input } from '@chiroup/components';
import { HomePageSection } from '@chiroup/core';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import appDataService from '../../services/app-data.service';
import Button from '../common/Button';
import Header from '../layout/Header';
import HomePageSortableItem from './HomePageSortableItem';
import HomePageSubsections from './HomePageSubsections';
// import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

// export const dropAnimation: DropAnimation = {
//   sideEffects: defaultDropAnimationSideEffects({
//     styles: {
//       active: {
//         opacity: '1',
//       },
//     },
//   }),
// };

const emptyData: HomePageSection[] = [];

const HomePage = () => {
  const [data, setData] = useState(emptyData);

  useEffect(() => {
    let isDestroyed = false;
    const doIt = async () => {
      const res = await appDataService.get('homePage');
      if (!isDestroyed) {
        setData(res);
      }
    };
    doIt();
    return () => {
      isDestroyed = true;
    };
  }, []);

  const save = async () => {
    await appDataService.update('homePage', data);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    const isMain = active.data.current.sortable.containerId === 'main';
    if (active.id !== over.id) {
      if (isMain) {
        setData((prev) => {
          const oldIndex = prev.findIndex((item) => item.id === active.id);
          const newIndex = prev.findIndex((item) => item.id === over.id);
          prev = arrayMove(prev, oldIndex, newIndex);
          return prev;
        });
      } else {
        const isLink =
          active.data.current.sortable.containerId.includes('links-');
        const isListItem =
          active.data.current.sortable.containerId.includes('list-');
        if (isLink || isListItem) {
          setData((prev) => {
            const allSubsections = prev.flatMap((item) => item.subsections);
            const subsection = allSubsections.find(
              (item) =>
                item.id ===
                active.data.current.sortable.containerId
                  .replace('links-', '')
                  .replace('list-', ''),
            );
            if (!subsection) {
              return prev;
            }
            const type = isListItem ? 'list' : 'links';
            const oldLinkIndex = subsection.typeData?.[type]?.findIndex(
              (item) => item.id === active.id,
            );
            const newLinkIndex = subsection.typeData?.[type]?.findIndex(
              (item) => item.id === over.id,
            );
            (subsection.typeData as any)[type] =
              (subsection.typeData as any)[type] || [];
            if (oldLinkIndex === undefined || newLinkIndex === undefined) {
              return prev;
            }
            (subsection.typeData as any)[type] = arrayMove(
              (subsection.typeData as any)[type],
              oldLinkIndex,
              newLinkIndex,
            );
            return JSON.parse(JSON.stringify(prev));
          });
        } else {
          setData((prev) => {
            const activeSection = prev.find(
              (item) => item.id === active.data.current.sortable.containerId,
            );
            if (!activeSection) {
              return prev;
            }
            const oldSubIndex = activeSection.subsections.findIndex(
              (item) => item.id === active.id,
            );
            const newSubIndex = activeSection.subsections.findIndex(
              (item) => item.id === over.id,
            );
            activeSection.subsections = arrayMove(
              activeSection.subsections,
              oldSubIndex,
              newSubIndex,
            );
            return JSON.parse(JSON.stringify(prev));
          });
        }
      }
    }
  };

  const addSection = () => {
    setData((prev) => {
      return prev.concat({
        id: v4(),
        title: 'New section',
        subsections: [],
      });
    });
  };

  const removeSection = (idToRemove: string) => {
    setData((prev) => {
      return prev.filter((item) => item.id !== idToRemove);
    });
  };

  return (
    <>
      <Header
        title="Home Page"
        rightSide={<Button text="Save" onClick={save} />}
      />
      <div className="pb-4">
        <div className="m-4">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              id="main"
              items={data}
              strategy={verticalListSortingStrategy}
            >
              {(data || []).map((item) => (
                <HomePageSortableItem
                  key={item.id}
                  id={item.id}
                  removeItem={removeSection}
                >
                  <div className="mb-4 relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm items-center hover:border-gray-400 flex flex-col gap-2 w-full">
                    <div className="flex flex-col gap-4 w-full">
                      <Input
                        name="text"
                        label="Title *"
                        className="w-full"
                        value={item.title}
                        onChange={(val) => {
                          setData((prev) => {
                            prev = prev.map((v) => {
                              if (v.id === item.id) {
                                return {
                                  ...v,
                                  title: val,
                                };
                              } else {
                                return v;
                              }
                            });
                            return prev;
                          });
                        }}
                      />
                      <Input
                        name="text"
                        label="Button Text"
                        className="w-full"
                        value={item.buttonText}
                        onChange={(val) => {
                          setData((prev) => {
                            prev = prev.map((v) => {
                              if (v.id === item.id) {
                                return {
                                  ...v,
                                  buttonText: val,
                                };
                              } else {
                                return v;
                              }
                            });
                            return prev;
                          });
                        }}
                      />
                      <Input
                        name="text"
                        label="Button Link"
                        className="w-full"
                        value={item.buttonLink}
                        onChange={(val) => {
                          setData((prev) => {
                            prev = prev.map((v) => {
                              if (v.id === item.id) {
                                return {
                                  ...v,
                                  buttonLink: val,
                                };
                              } else {
                                return v;
                              }
                            });
                            return prev;
                          });
                        }}
                      />
                      <HomePageSubsections item={item} setData={setData} />
                    </div>
                  </div>
                </HomePageSortableItem>
              ))}
            </SortableContext>
            {/* {createPortal(
              <DragOverlay
                dropAnimation={dropAnimation}
                modifiers={[restrictToVerticalAxis]}
              />,
              document.body
            )} */}
          </DndContext>
          <PlusCircleIcon
            className="w-10 h-10 text-primary-500 hover:text-primary-400 cursor-pointer"
            onClick={addSection}
          />
        </div>
      </div>
    </>
  );
};

export default HomePage;
