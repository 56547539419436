import React from 'react';
import DashboardDivider from './DashboardDivider';

type Link = {
  link: string;
  icon: string;
};

type Props = {
  links?: Link[];
  title: string;
};

const DashboardSection = ({ links, title }: Props) => {
  return (
    <div>
      <DashboardDivider title={title} />
      <div className=" w-full">
        <ul className="grid grid-cols-4 gap-4 p-4">
          {links?.map((link, i) => (
            <li key={i} className="col-span-2 sm:col-span-1">
              <a href={`${link.link}`} target="_blank" rel="noreferrer">
                <img
                  src={`${import.meta.env.VITE_ADMIN_ICONS_URL}/${
                    link.icon
                  }.png`}
                  alt={`${link.icon}`}
                  className="w-full"
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DashboardSection;
