export const translateIncomingFilters = (filters: any, article: any) => {
  for (const key in filters) {
    switch (key) {
      case 'search':
        break;
      case 'faceBook':
        if (filters.faceBook === 1) {
          article.fbExisting = true;
        } else if (filters.faceBook === 2) {
          article.fbComplete = true;
        } else if (filters.faceBook === 3) {
          article.fbPublished = true;
          article.fbComplete = true;
        }
        break;
      case 'CSM':
        if (filters.CSM === 1) {
          article.csmExisting = true;
        } else if (filters.CSM === 2) {
          article.csmComplete = true;
        } else if (filters.CSM === 3) {
          article.csmPublished = true;
          article.csmComplete = true;
        }
        break;
      case 'newsFeed':
        if (filters.newsFeed === 1) {
          article.newsFeedExisting = true;
        } else if (filters.newsFeed === 2) {
          article.newsFeedComplete = true;
        } else if (filters.newsFeed === 3) {
          article.newsFeedPublished = true;
          article.newsFeedComplete = true;
        }
        break;
      case 'protocol':
        if (filters.protocol > 0) {
          article.protocolExisting = true;
        }
        if (filters.protocol === 3) {
          article.protocolPublished = true;
        }
        break;
      case 'blog':
        if (filters.blog > 0) {
          article.blogExisting = true;
        }
        if (filters.blog === 3) {
          article.blogPublished = true;
        }
        break;
      case 'mdNewsletter':
        if (filters.mdNewsletter > 0) {
          article.mdNewsletterExisting = true;
        }
        if (filters.mdNewsletter === 3) {
          article.mdNewsletterPublished = true;
        }
        break;
      case 'legalNewsletter':
        if (filters.legalNewsletter > 0) {
          article.legalNewsletterExisting = true;
        }
        if (filters.legalNewsletter === 3) {
          article.legalNewsletterPublished = true;
        }
        break;
      case 'nfPriority':
        if (filters.nfPriority === 1) {
          article.nfPriority = true;
        }
    }
  }
  delete article.unclassified;
  delete article.newsFeed;
  delete article.faceBook;
  delete article.protocol;
  delete article.CSM;
  delete article.blog;
  delete article.mdNewsletter;
  delete article.legalNewsletter;
  return article;
};

export const translateOutgoingFilters = (article: any) => {
  for (const key in article) {
    switch (key) {
      // Facebook
      case 'fbExisting':
        if (article.fbExisting === true) {
          article.faceBook = 1;
        } else {
          article.faceBook = 0;
        }
        break;
      case 'fbPublished':
        if (article.fbPublished === true) {
          article.faceBook = 3;
        }
        break;
      case 'fbComplete':
        if (article.fbComplete === true) {
          article.faceBook = 2;
        }
        break;

      // CSM
      case 'csmExisting':
        if (article.csmExisting === true) {
          article.CSM = 1;
        } else {
          article.CSM = 0;
        }
        break;
      case 'csmPublished':
        if (article.csmPublished === true) {
          article.CSM = 3;
        }
        break;
      case 'csmComplete':
        if (article.csmComplete === true) {
          article.CSM = 2;
        }
        break;

      // Newsfeed
      case 'newsFeedExisting':
        if (article.newsFeedExisting === true) {
          article.newsFeed = 1;
        } else {
          article.newsFeed = 0;
        }
        break;
      case 'newsFeedPublished':
        if (article.newsFeedPublished === true) {
          article.newsFeed = 3;
        }
        break;
      case 'newsFeedComplete':
        if (article.newsFeedComplete === true) {
          article.newsFeed = 2;
        }
        break;
      case 'nfPriority':
        if (article.nfPriority === true) {
          article.nfPriority = 1;
        }
        break;

      // Protocol
      case 'protocolExisting':
        if (article.protocolExisting === true) {
          article.protocol = 1;
        } else {
          article.protocol = 0;
        }
        break;
      case 'protocolPublished':
        if (article.protocolPublished === true) {
          article.protocol = 3;
        }
        break;

      // Blog
      case 'blogExisting':
        if (article.blogExisting === true) {
          article.blog = 1;
        } else {
          article.blog = 0;
        }
        break;
      case 'blogPublished':
        if (article.blogPublished === true) {
          article.blog = 3;
        }
        break;

      // MD newsletter
      case 'mdNewsletterExisting':
        if (article.mdNewsletterExisting === true) {
          article.mdNewsletter = 1;
        } else {
          article.mdNewsletter = 0;
        }
        break;
      case 'mdNewsletterPublished':
        if (article.mdNewsletterPublished === true) {
          article.mdNewsletter = 3;
        }
        break;

      // Legal newsletter
      case 'legalNewsletterExisting':
        if (article.legalNewsletterExisting === true) {
          article.legalNewsletter = 1;
        } else {
          article.legalNewsletter = 0;
        }
        break;
      case 'legalNewsletterPublished':
        if (article.legalNewsletterPublished === true) {
          article.legalNewsletter = 3;
        }
        break;
    }
  }
  delete article.fbExisting;
  delete article.fbComplete;
  delete article.fbPublished;
  delete article.csmExisting;
  delete article.csmComplete;
  delete article.csmPublished;
  delete article.newsFeedExisting;
  delete article.newsFeedComplete;
  delete article.newsFeedPublished;
  delete article.protocolExisting;
  delete article.protocolPublished;
  delete article.blogExisting;
  delete article.blogPublished;
  delete article.mdNewsletterExisting;
  delete article.mdNewsletterPublished;
  delete article.legalNewsletterExisting;
  delete article.legalNewsletterPublished;
  return article;
};

export const blankFilters = {
  newsFeedExisting: false,
  newsFeedComplete: false,
  newsFeedPublished: false,
  fbExisting: false,
  fbComplete: false,
  fbPublished: false,
  protocolExisting: false,
  protocolPublished: false,
  csmExisting: false,
  csmComplete: false,
  csmPublished: false,
  blogExisting: false,
  blogPublished: false,
  mdNewsletterExisting: false,
  mdNewsletterPublished: false,
  legalNewsletterExisting: false,
  legalNewsletterPublished: false,
};
