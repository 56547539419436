import { Input, InputMasked, OpenClosedStates } from '@chiroup/components';
import { useForm } from '@chiroup/hooks';
import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import Button, { ButtonColors } from '../common/Button';
import Modal from '../common/Modal';
import { Marketing } from '@chiroup/core';
import marketingAddOnService from '../../services/marketingAddOn.service';
import dayjs from 'dayjs';

const urlRegex =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
const isValidUrl = (url: string) => {
  return urlRegex.test(url);
};
const validation = {
  deliveryDate: {
    required: { message: 'Delivery date is required.' },
  },
  premiumA: {
    function: {
      value: (values: Partial<Marketing>) => {
        const { premiumA } = values;
        if (!premiumA) {
          return 'URL is required.';
        }
        if (premiumA && !isValidUrl(premiumA)) {
          return 'URL is not valid.';
        }
        return false;
      },
    },
  },
  premiumB: {
    function: {
      value: (values: Partial<Marketing>) => {
        const { premiumB } = values;
        if (!premiumB) {
          return 'URL is required.';
        }
        if (premiumB && !isValidUrl(premiumB)) {
          return 'URL is not valid.';
        }
        return false;
      },
    },
  },
  plusA: {
    function: {
      value: (values: Partial<Marketing>) => {
        const { plusA } = values;
        if (!plusA) {
          return 'URL is required.';
        }
        if (plusA && !isValidUrl(plusA)) {
          return 'URL is not valid.';
        }
        return false;
      },
    },
  },
  plusB: {
    function: {
      value: (values: Partial<Marketing>) => {
        const { plusB } = values;
        if (!plusB) {
          return 'URL is required.';
        }
        if (plusB && !isValidUrl(plusB)) {
          return 'URL is not valid.';
        }
        return false;
      },
    },
  },
};

type Props = {
  state: OpenClosedStates;
  setState: React.Dispatch<SetStateAction<OpenClosedStates>>;
  marketingItem?: Marketing | null;
  setSelectedMarketingItem: React.Dispatch<SetStateAction<Marketing | null>>;
  refetch: () => void;
};

const defaultValues = {
  premiumA: '',
  premiumB: '',
  plusA: '',
  plusB: '',
  deliveryDate: '',
};
const MarketingCreateModal: React.FC<Props> = ({
  state,
  setState,
  marketingItem,
  setSelectedMarketingItem,
  refetch,
}) => {
  const {
    value,
    registerSubmit,
    isDirty,
    errors,
    onChange,
    patchValue,
    setValue,
    setErrors,
  } = useForm<Marketing>(defaultValues, validation);

  useEffect(() => {
    patchValue({
      ...marketingItem,
      deliveryDate: dayjs(marketingItem?.deliveryDate ?? '').format(
        'MM/DD/YYYY',
      ),
    });
  }, [marketingItem, patchValue]);

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { createToast } = useContext(ToastContext);
  const onClose = () => {
    setState(OpenClosedStates.Closed);
    setSelectedMarketingItem(null);
    setValue(defaultValues);
    setErrors({});
  };

  const onSubmit = async () => {
    try {
      setSaving(true);
      marketingItem?.id
        ? await marketingAddOnService.update(marketingItem.id, value)
        : await marketingAddOnService.create(value);
      refetch();
      onClose();
    } catch (err) {
      console.error(err);
    } finally {
      setSaving(false);
    }
  };

  const onSuccess = (
    title = 'Success',
    message = 'Successfully saved marketing item!',
  ) => {
    createToast({
      title,
      description: message,
      type: ToastTypes.Success,
      duration: 5000,
    });
  };

  const onFail = (
    title = 'Error',
    message = 'Error saving marketing item!',
  ) => {
    createToast({
      title,
      description: message,
      type: ToastTypes.Fail,
      duration: 5000,
    });
  };
  function isInPast() {
    if (marketingItem?.deliveryDate) {
      const currentDate = dayjs().startOf('day');
      const inputDate = dayjs(marketingItem.deliveryDate as Date).startOf(
        'day',
      );

      return inputDate.isBefore(currentDate);
    } else {
      return false;
    }
  }
  const handleDateChange = (deliveryDate: any) => {
    if (!isInPast()) {
      patchValue({ deliveryDate });
    }
  };

  const onDelete = async () => {
    try {
      setDeleting(true);
      await marketingAddOnService.deleteMarketing(marketingItem?.id as number);
      refetch();
      onSuccess('Success', 'Successfully deleted marketing item!');
      onClose();
    } catch (err) {
      console.error(err);
      onFail('Error', 'Error deleting marketing item!');
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Modal isOpen={state === OpenClosedStates.Open} close={onClose}>
      <div>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-darkGray-500">
          <svg
            className="h-6 w-6 text-gray-600 dark:text-darkGray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
          </svg>
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3
            className="text-lg leading-6 font-medium text-gray-900 dark:text-darkGray-100"
            id="modal-headline"
          >
            Add Marketing Item
          </h3>
          <div className="mt-2 text-left">
            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-300">
              <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5">
                <InputMasked
                  name={'deliveryDate'}
                  label={'Delivery Date *'}
                  className={`col-span-4`}
                  value={value.deliveryDate as string}
                  onChange={isInPast() ? () => null : handleDateChange}
                  errors={errors.fieldErrors?.deliveryDate}
                  disabled={isInPast()}
                  patternFormat="##/##/####"
                  placeholder="MM/DD/YYYY"
                />
                <Input
                  name={'plusA'}
                  label={'Plus A *'}
                  value={value.plusA}
                  onChange={onChange('plusA')}
                  className={'col-span-2'}
                  errors={errors.fieldErrors?.plusA}
                  disabled={isInPast()}
                />
                <Input
                  name={'plusB'}
                  label={'Plus B *'}
                  value={value.plusB}
                  onChange={onChange('plusB')}
                  className={'col-span-2'}
                  errors={errors.fieldErrors?.plusB}
                  disabled={isInPast()}
                />
                <Input
                  name={'premiumA'}
                  label={'Premium A *'}
                  value={value.premiumA}
                  onChange={onChange('premiumA')}
                  className={'col-span-2'}
                  errors={errors.fieldErrors?.premiumA}
                  disabled={isInPast()}
                />
                <Input
                  name={'premiumB'}
                  label={'Premium B *'}
                  value={value.premiumB}
                  onChange={onChange('premiumB')}
                  className={'col-span-2'}
                  errors={errors.fieldErrors?.premiumB}
                  disabled={isInPast()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <span className="flex w-full gap-x-4">
          <Button
            text="Close"
            onClick={onClose}
            fullWidth
            color={ButtonColors.plain}
            className="border border-gray-300 dark:border-darkGray-500"
          />
          {marketingItem?.id && (
            <Button
              text={'Delete'}
              fullWidth
              loading={deleting}
              color={ButtonColors.red}
              onClick={onDelete}
              disabled={saving || isInPast() || deleting}
            />
          )}
          <Button
            text={marketingItem ? 'Update' : 'Create'}
            onClick={registerSubmit(onSubmit, { onSuccess, onFail })}
            fullWidth
            loading={saving}
            disabled={!isDirty || saving || isInPast() || deleting}
          />
        </span>
      </div>
    </Modal>
  );
};

export default MarketingCreateModal;
