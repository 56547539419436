import { Icons, TiptapEditorBase, tiptapExtensions } from '@chiroup/components';
import { FormError } from '@chiroup/core';
// import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji';
import type { JSONContent } from '@tiptap/react';
import { forwardRef, useMemo } from 'react';

// export const tiptapExtensions = [
//   Document,
//   Paragraph,
//   Text,
//   StarterKit,
//   Placeholder.configure({
//     placeholder: 'Enter a description...',
//   }),
//   // Emoji.configure({
//   //   emojis: gitHubEmojis,
//   //   enableEmoticons: true,
//   //   suggestion: emojiSuggestion,
//   // }),
// ];

type Props = {
  value?: JSONContent | string;
  name?: string;
  label?: string;
  onChange?: (val?: any) => any;
  className?: string;
  containerClassName?: string;
  small?: boolean;
  toolbar?: boolean;
  html?: boolean;
  text?: boolean;
  disabled?: boolean;
  extraButtons?: {
    icon: Icons;
    type: 'setContent';
    fn: () => any;
  }[];
  errors?: FormError;
  labelColor?: boolean;
  disableOrderedList?: boolean;
};

export const TiptapEditor = forwardRef<any, Props>(
  (
    {
      value,
      name,
      label,
      className = 'border block w-full border-gray-300 rounded-b-md transition duration-150 ease-in-out sm:leading-5 dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200 outline-none p-2',
      containerClassName,
      onChange,
      small,
      toolbar = true,
      html = false,
      text = false,
      disabled = false,
      extraButtons,
      errors,
      labelColor = false,
      disableOrderedList = false,
    },
    ref,
  ) => {
    const extensionsToUse = useMemo(
      () =>
        tiptapExtensions.filter(
          (ext) => !disableOrderedList || ext.name !== 'orderedList',
        ),
      [disableOrderedList],
    );

    return (
      <TiptapEditorBase
        value={value}
        name={name}
        label={label}
        className={className}
        containerClassName={containerClassName}
        onChange={onChange}
        small={small}
        toolbar={toolbar}
        html={html}
        text={text}
        disabled={disabled}
        extraButtons={extraButtons}
        errors={errors}
        labelColor={labelColor}
        extensions={extensionsToUse}
        ref={ref}
      />
    );
  },
);
