import { Any } from '@chiroup/core';
import React from 'react';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import EndOfList from '../EndOfList';
import LoadingPage from '../LoadingPage';

type Props = {
  isFetching: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: (options?: FetchNextPageOptions | undefined) => Promise<
    InfiniteQueryObserverResult<
      {
        data: Any[];
        skip?: number;
        lastKey?: string;
      },
      unknown
    >
  >;
  hasNextPage?: boolean;
  className?: string;
  children?: React.ReactNode;
  clientSide?: boolean;
};

const List: React.FC<Props> = ({
  children,
  isFetching,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  className = 'relative z-0 divide-y divide-gray-300 dark:divide-darkGray-600 border-b border-gray-300 dark:border-darkGray-600',
  clientSide = false,
}) => {
  return isFetching && !isFetchingNextPage ? (
    <LoadingPage />
  ) : (
    <>
      <ul className={className}>{children}</ul>
      <EndOfList
        isFetching={isFetching}
        isFetchingNextPage={!!isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
      />
    </>
  );
};

export default List;
