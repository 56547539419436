import React from 'react';
import StarIcon from '../icons/StarIcon';

type Props = {
  label: string;
  value?: string | React.ReactNode;
  star?: boolean;
  show?: boolean;
  className?: string;
};

const InfoItem: React.FC<Props> = ({
  label,
  value,
  star = false,
  show = true,
  className = 'sm:col-span-1',
}) => {
  if (!show || !value) {
    return null;
  }
  return (
    <div className={className}>
      <dt className="text-sm leading-5 font-medium text-gray-500 dark:text-darkGray-400">
        {label}
      </dt>
      <dd className="mt-1 text-sm leading-5 text-gray-900 dark:text-darkGray-100 flex">
        {value} {star && <StarIcon />}
      </dd>
    </div>
  );
};

export default InfoItem;
