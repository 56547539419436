import React from 'react';
import Tabs from '../../layout/Tabs';

type Props = {
  active: string;
  id?: string;
};

const ClinicTabs: React.FC<Props> = ({ active, id }) => {
  return (
    <Tabs
      tabs={[
        {
          name: 'General',
          href: `/clinics/${id}`,
          active: active === `/clinics/${id}`,
        },
        {
          name: 'Users',
          href: `/clinics/${id}/users`,
          active: active === `/clinics/${id}/users`,
        },
        {
          name: 'Locations',
          href: `/clinics/${id}/locations`,
          active: active === `/clinics/${id}/locations`,
        },
        {
          name: 'Billing',
          href: `/clinics/${id}/billing`,
          active: active === `/clinics/${id}/billing`,
        },
      ]}
    />
  );
};

export default ClinicTabs;
