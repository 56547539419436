import { useDebounce } from '@chiroup/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import formService, { FormsObj } from '../../../../services/form.service';
import { useLocation } from 'react-router-dom';

const getFormsQuery = () => {
  return async () => {
    return formService.list();
  };
};

const useForms = () => {
  const { pathname } = useLocation();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const debouncedSearch = useDebounce(searchQuery);
  const { status, data, error, isFetching, refetch } = useQuery<{
    data: FormsObj;
    tabNames: { [key: string]: string };
  }>(['forms'], getFormsQuery(), {
    refetchOnWindowFocus: false,
  });
  const [filteredData, setFilteredData] = useState<FormsObj>();

  const onSearch = (searchTerm: string) => {
    setSearchQuery((searchTerm || '').toLowerCase());
  };

  const tabs = useMemo(() => {
    if (!data?.data) {
      return [];
    }

    return Object.keys(data.data)
      ?.sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      })
      ?.map((key) => {
        const lowerHyphenKey = key.toLowerCase().replace(' ', '-');
        return {
          name: data.tabNames[key],
          href: `/practice-resources/forms-library/${lowerHyphenKey}`,
          active:
            pathname === `/practice-resources/forms-library/${lowerHyphenKey}`,
        };
      });
  }, [data, pathname]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (debouncedSearch) {
      setFilteredData({
        search: Object.values(data.data)
          .flat()
          .filter((item: any) =>
            item.title.toLowerCase().includes(debouncedSearch),
          ),
      });
    } else {
      const items = data.data[selectedType]?.reduce(
        (obj: FormsObj, item: any) => {
          const category = item.category;
          obj[category] = obj[category] || [];
          obj[category].push(item);
          return obj;
        },
        {},
      );
      const sortedCategories = Object.keys(items || {})?.sort();
      const sortedItems: FormsObj = {};
      sortedCategories?.forEach((category) => {
        sortedItems[category] = items[category];
      });
      setFilteredData(sortedItems);
    }
  }, [debouncedSearch, data, selectedType]);

  // const onSearch = (searchTerm: string) => {
  //   const dataCopy = { ...data };
  //   if (!dataCopy) {
  //     return {};
  //   }

  //   setFilteredData(
  //     Object.keys(dataCopy).reduce((obj: FormsObj, type: string) => {
  //       obj[type] = dataCopy[type].filter((item) =>
  //         item.title.includes(searchTerm)
  //       );
  //       return obj;
  //     }, {})
  //   );
  //   // return true;
  // };

  const onSelectType = (type: string) => {
    setSelectedType(type);
  };

  return {
    status,
    data: filteredData,
    error,
    isFetching,
    refetch,
    onSearch,
    onSelectType,
    tabs,
  };
};

export default useForms;
