import React from 'react';
import SectionHeader from '../layout/SectionHeader';
import SectionContainer from '../layout/SectionContainer';
import Button, { ButtonColors } from '../common/Button';
import MarketingCreateModal from './MarketingCreateModal';
import { OpenClosedStates } from '@chiroup/components';
import useMarketing from '../../hooks/useMarketing';
import List from '../common/list/List';
import ListItem from '../common/list/ListItem';
import dayjs from 'dayjs';
import { Marketing as MarketingType } from '@chiroup/core';

const Marketing = () => {
  const [state, setState] = React.useState<OpenClosedStates>(
    OpenClosedStates.Closed,
  );
  const [selectedMarketingItem, setSelectedMarketingItem] =
    React.useState<MarketingType | null>(null);
  const {
    data,
    isFetching,
    refetch,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useMarketing();
  return (
    <>
      <SectionContainer className="sm:shadow border border-gray-300 dark:border-darkGray-800 mx-6">
        <SectionHeader
          title={'Marketing'}
          rightSide={
            <Button
              text="Add"
              color={ButtonColors.plainWithBorder}
              className="cursor-pointer"
              onClick={() => setState(OpenClosedStates.Open)}
              icon={
                <path
                  fillRule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              }
            />
          }
        />
        <List
          isFetching={isFetching}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
        >
          {data?.pages?.map((page, pageNumber) => (
            <React.Fragment key={`users-page-${pageNumber}`}>
              {page?.data?.map((row) => (
                <ListItem index={row.id}>
                  <div
                    className="flex items-center justify-between space-x-4 cursor-pointer"
                    onClick={() => {
                      setSelectedMarketingItem(row);
                      setState(OpenClosedStates.Open);
                    }}
                  >
                    <div className="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
                      <div>
                        <span className="text-gray-500 text-sm leading-4">
                          Delivery Date:&nbsp;
                        </span>
                        <span className="text-gray-500 text-sm leading-4">
                          {dayjs(row.deliveryDate as Date).format('MMM YYYY')}
                        </span>
                      </div>
                    </div>
                  </div>
                </ListItem>
              ))}
            </React.Fragment>
          ))}
        </List>
      </SectionContainer>
      <MarketingCreateModal
        state={state}
        setState={setState}
        marketingItem={selectedMarketingItem}
        setSelectedMarketingItem={setSelectedMarketingItem}
        refetch={refetch}
      />
    </>
  );
};

export default Marketing;
