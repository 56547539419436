import React from 'react';
import Tabs from '../../layout/Tabs';

type Props = {
  active: string;
  id: string;
};

const UserTabs: React.FC<Props> = ({ active, id }) => {
  return (
    <Tabs
      tabs={[
        {
          name: 'General',
          href: `/users/${id}`,
          active: active === `/users/${id}`,
        },
        {
          name: 'Clinics',
          href: `/users/${id}/clinics`,
          active: active === `/users/${id}/clinics`,
        },
      ]}
    />
  );
};

export default UserTabs;
