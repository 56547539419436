import { MarketingCampaignDetail } from '@chiroup/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useMarketingCampaign from '../../../../hooks/useMarketingCampaign';
import ErrorPage from '../../../common/ErrorPage';
import Header from '../../../layout/Header';
import MarketingGeneral from './MarketingGeneral';

const MarketingContainer = () => {
  const { id } = useParams();
  const { data, save, queryErrors, del } = useMarketingCampaign(id);
  const [marketing, setMarketing] =
    useState<Partial<MarketingCampaignDetail>>();

  useEffect(() => {
    if (data) {
      setMarketing(data);
    }
  }, [data]);

  if (queryErrors) {
    return (
      <ErrorPage
        title={queryErrors.title}
        description={queryErrors.description}
        link={queryErrors.link}
      />
    );
  }

  return marketing ? (
    <>
      <Header title={`${marketing?.title}`} />
      <div className="px-6">
        <MarketingGeneral marketing={marketing} save={save} del={del} />
      </div>
    </>
  ) : null;
};

export default MarketingContainer;
