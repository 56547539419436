import { Input, OpenClosedStates, Select } from '@chiroup/components';
import { useForm } from '@chiroup/hooks';
import { useContext, useState } from 'react';
import { REGIONS } from '../../constants';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import disabilitiesService from '../../services/disabilities.service';
import Button, { ButtonColors } from '../common/Button';
import SlideOver from '../common/SlideOver';

type DisabilityForm = {
  ID: string;
  name: string;
  region: string;
};

type Props = {
  defaultValues?: Partial<any>;
  slideOverState: OpenClosedStates;
  updateSlideOverState: (val: OpenClosedStates) => void;
  setCurrentDisability: (val: Partial<any>) => void;
};

const DisabilityEditSlideOverPanel = ({
  defaultValues,
  slideOverState,
  updateSlideOverState,
  setCurrentDisability,
}: Props) => {
  const { value, onChange, patchValue, isDirty, registerSubmit } = useForm<
    Partial<DisabilityForm>
  >(defaultValues || { name: '', region: '' });
  const [saving, setSaving] = useState(false);
  const { createToast } = useContext(ToastContext);

  const onSubmit = async (val: Partial<any>) => {
    setSaving(true);
    let disability;
    if (defaultValues?.ID) {
      try {
        disability = await disabilitiesService.update(val);
      } catch (err) {
        console.error(err);
        setSaving(false);
      }
    } else {
      try {
        const res = await disabilitiesService.create(val);

        disability = await disabilitiesService.findOne(res);
      } catch (err) {
        console.error(err);
        setSaving(false);
      }
    }
    setSaving(false);
    return disability;
  };

  const onSuccess = async (val: any) => {
    if (!defaultValues?.ID) {
      updateSlideOverState(OpenClosedStates.Closed);
    } else {
      patchValue(val);
    }
    createToast({
      title: `Successfully ${
        !defaultValues?.ID ? 'created new' : 'updated the'
      } article!`,
      description: (
        <>
          Click{' '}
          <span
            onClick={() => setCurrentDisability(val)}
            className="text-primary-600 font-semibold hover:text-primary-500 cursor-pointer"
          >
            here
          </span>{' '}
          to view the article.
        </>
      ),
      type: ToastTypes.Success,
      duration: 5000,
    });
  };

  return (
    <SlideOver
      title="Edit disability"
      slideOverState={slideOverState}
      updateSlideOverState={updateSlideOverState}
      buttons={
        <>
          <Button
            text="Close"
            onClick={() => updateSlideOverState(OpenClosedStates.Closed)}
            color={ButtonColors.plain}
          />
          <Button
            text="Save"
            disabled={!isDirty || saving}
            loading={saving}
            onClick={registerSubmit(onSubmit, { onSuccess })}
          />
        </>
      }
    >
      <form>
        <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-300">
          <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
            <Input
              type="text"
              name="name"
              value={value?.name}
              label="Name"
              onChange={onChange('name')}
              className="col-span-4"
            />
            <Select
              name="region"
              label="Region"
              className="col-span-2"
              value={value?.region}
              onChange={onChange('region')}
              options={REGIONS}
              limit={1}
            />
          </div>
        </div>
      </form>
    </SlideOver>
  );
};

export default DisabilityEditSlideOverPanel;
