import React, { Dispatch, SetStateAction } from 'react';
import { OpenClosedStates } from '@chiroup/components';

type Props = {
  sidenavState: OpenClosedStates;
  setSidenavState: Dispatch<SetStateAction<OpenClosedStates>>;
};

const MobileMenuCloseButton: React.FC<Props> = ({
  sidenavState,
  setSidenavState,
}) => {
  return (
    <div
      className="absolute top-0 right-0 -mr-14 p-1"
      hidden={sidenavState !== OpenClosedStates.Open}
    >
      <button
        className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
        aria-label="Close sidebar"
        onClick={() => setSidenavState(OpenClosedStates.Closed)}
      >
        <svg
          className="h-6 w-6 text-white"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
};

export default MobileMenuCloseButton;
