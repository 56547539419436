import { OpenClosedStates } from '@chiroup/components';
import { Clinic, ListClinic } from '@chiroup/core';
import { useForm } from '@chiroup/hooks';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import clinicService from '../../services/clinic.service';
import Button, { ButtonColors } from '../common/Button';
import Modal from '../common/Modal';
import ClinicGeneralFormElements from './detail/ClinicGeneralFormElements';

const validation = {
  name: {
    required: {
      message: 'Clinic name is required.',
    },
  },
  plan: {
    required: {
      message: 'Plan is required',
    },
  },
  phone: {
    function: {
      value: (value: Partial<ListClinic & { phone: string }>) => {
        const { phone } = value;
        if (
          phone?.[1] === '1' &&
          phone?.split(' ')?.[0]?.length === 6 &&
          phone?.length < 13
        ) {
          return 'Phone number must be 7 digits.';
        } else if (phone?.[1] === '1' && phone?.length < 13) {
          return 'Phone number must be 10 digits.';
        }
        return false;
      },
    },
  },
};

type Props = {
  state: OpenClosedStates;
  close: (val?: Clinic) => void;
  onCreate: () => void;
  // lastName?: string;
};

const ClinicCreateModal: React.FC<Props> = ({
  state,
  close,
  onCreate,
  // lastName,
}) => {
  const { value, registerSubmit, isDirty, errors, onChange, patchValue } =
    useForm<ListClinic>(
      {
        surveyOptOut: false,
        theme: 'default-theme',
        plan: 'trial',
        billDate: dayjs().add(2, 'w').format('MM/DD/YYYY'),
        premiumUntil: '',
        plusUntil: '',
      },
      validation,
    );
  const [saving, setSaving] = useState(false);
  const { createToast } = useContext(ToastContext);

  const onSubmit = async (clinic: Partial<ListClinic>) => {
    setSaving(true);
    try {
      const res = await clinicService.create(clinic);
      setSaving(false);
      close(res);
      onCreate();
      return res;
    } catch (err) {
      console.error(err);
      setSaving(false);
    }
    return clinic;
  };

  const onSuccess = (data: Partial<ListClinic>) => {
    createToast({
      title: 'Successfully created new clinic!',
      description: (
        <>
          Click{' '}
          <Link
            to={`/clinics/${data.ID}`}
            className="text-primary-600 font-semibold hover:text-primary-500"
          >
            here
          </Link>{' '}
          to view the clinic.
        </>
      ),
      type: ToastTypes.Success,
      duration: 5000,
    });
    close();
    onCreate?.();
  };

  return (
    <Modal isOpen={state === OpenClosedStates.Open} close={close}>
      <div>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-darkGray-500">
          <svg
            className="h-6 w-6 text-gray-600 dark:text-darkGray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
          </svg>
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3
            className="text-lg leading-6 font-medium text-gray-900 dark:text-darkGray-100"
            id="modal-headline"
          >
            Add clinic
          </h3>
          <div className="mt-2 text-left">
            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-300">
              <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5">
                <ClinicGeneralFormElements
                  fieldErrors={errors.fieldErrors}
                  value={value}
                  onChange={onChange}
                  patchValue={patchValue}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <span className="flex w-full gap-x-4">
          <Button
            text="Close"
            onClick={() => close()}
            fullWidth
            color={ButtonColors.plain}
            className="border border-gray-300 dark:border-darkGray-500"
          />
          <Button
            text="Create"
            onClick={registerSubmit(onSubmit, {
              onSuccess,
            })}
            fullWidth
            loading={saving}
            disabled={!isDirty}
          />
        </span>
      </div>
    </Modal>
  );
};

export default ClinicCreateModal;
