import { OpenClosedStates } from '@chiroup/components';
import { ErrorResponse, ListClinic } from '@chiroup/core';
import { useForm } from '@chiroup/hooks';
import React from 'react';
import { UseMutateFunction } from 'react-query';
import Button, { ButtonColors } from '../../common/Button';
import SlideOver from '../../common/SlideOver';
import ClinicGeneralFormElements from './ClinicGeneralFormElements';

type Props = {
  editSlideOverState: OpenClosedStates;
  updateSlideOverState: (val: OpenClosedStates) => void;
  defaultValues: Partial<ListClinic>;
  update: UseMutateFunction<
    Partial<ListClinic>,
    ErrorResponse,
    Partial<ListClinic>,
    unknown
  >;
  isUpdating: boolean;
};

const validators = {
  zip: {
    function: {
      value: (value: Partial<ListClinic>) => {
        const { zip, country } = value;
        //Zip is not a required field here right now so...
        // if (!zip)
        //   return country === 'USA'
        //     ? 'Zip code is required.'
        //     : 'Postal code is required.';
        if (country === 'USA' && !!zip) {
          if (zip.length !== 5 && zip.length !== 10) {
            return 'Zip code must be 5 or 9 digits.';
          }
        }
        return false;
      },
    },
  },
};

const ClinicGeneralEditPanel: React.FC<Props> = ({
  editSlideOverState,
  updateSlideOverState,
  defaultValues,
  update,
  isUpdating,
}) => {
  const { value, registerSubmit, isDirty, errors, onChange, patchValue } =
    useForm<ListClinic>(defaultValues, validators);

  const onSubmit = async (clinic: Partial<ListClinic>) => {
    if (defaultValues.ID) {
      clinic.ID = defaultValues.ID;
    }
    await update(clinic);
    return clinic;
  };

  return (
    <SlideOver
      title="Edit clinic"
      slideOverState={editSlideOverState}
      updateSlideOverState={updateSlideOverState}
      buttons={
        <>
          <Button
            text="Close"
            onClick={() => updateSlideOverState(OpenClosedStates.Closed)}
            color={ButtonColors.plain}
          />
          <Button
            text="Save"
            disabled={!isDirty || isUpdating}
            loading={isUpdating}
            onClick={registerSubmit(onSubmit, {})}
          />
        </>
      }
    >
      <form>
        <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-300">
          <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
            <ClinicGeneralFormElements
              fieldErrors={errors.fieldErrors}
              value={value}
              onChange={onChange}
              patchValue={patchValue}
            />
          </div>
        </div>
      </form>
    </SlideOver>
  );
};

export default ClinicGeneralEditPanel;
