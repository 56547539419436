import { Test } from '@chiroup/core';
import { useContext, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ToastContext, ToastTypes } from '../contexts/toast.context';
import testService from '../services/test.service';
import dayjs from 'dayjs';
import { v4 } from 'uuid';

const getQuery = function (id = '') {
  return async () => {
    if (id === 'add') {
      return {} as Test;
    }
    return testService.findOne(id);
  };
};

const useTest = function (id: string) {
  const toast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const [key, setKey] = useState<string | null>(null);

  const { status, data, error, isFetching, refetch } = useQuery(
    ['test', id],
    getQuery(id),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const save = async (val: Partial<Test>) => {
    const res: Test = await testService.save(id, val);
    const newItemId = res.ID;
    if (+newItemId !== +id) {
      const url = `/tests/${newItemId}`;
      navigate(url);
    }

    queryClient.setQueryData(['test', id], res);
    setKey(v4());
    return res;
  };

  const del = async (id: number) => {
    setIsDeleting(true);
    try {
      await testService.save(id, {
        deleted: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      });
      setIsDeleting(false);

      navigate(`/tests`);
    } catch (err) {
      setIsDeleting(false);
      toast.createToast({
        type: ToastTypes.Fail,
        title: 'Failed to delete',
        description: (
          <>An error ocurred trying to delete this item. Please try again.</>
        ),
      });
    }
  };

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
    save,
    del,
    isDeleting,
    key,
  };
};

export default useTest;
