import React, { useMemo } from 'react';
import ReactPlayer from 'react-player';

const VIDEO_URL = import.meta.env.VITE_VIDEO_URL;

type Props = {
  url: string;
};

const UploadedVideo: React.FC<Props> = ({ url }) => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const urlToUse = useMemo(() => {
    if (url.includes('https://')) {
      return url;
    } else if (url) {
      return `${VIDEO_URL}/${url}.m3u8`;
    }
    return '';
  }, [url]);

  return (
    <ReactPlayer
      url={urlToUse}
      controls
      config={{
        file: {
          attributes: {
            controlsList: 'nodownload',
          },
          forceVideo: true,
          forceHLS: urlToUse?.includes('.m3u8') && !isSafari,
        },
      }}
      playsinline
    />
  );
};

export default UploadedVideo;
