import { Transition } from '@headlessui/react';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { OpenClosedStates } from '@chiroup/components';
import Overlay from '../common/Overlay';
import Logo from './Logo';
import MobileMenuCloseButton from './MobileMenuCloseButton';
import SidenavMenu from './SidenavMenu';

type Props = {
  sidenavState: OpenClosedStates;
  setSidenavState: Dispatch<SetStateAction<OpenClosedStates>>;
  hide: boolean;
  user: any;
};

const Sidenav: React.FC<Props> = ({
  sidenavState,
  setSidenavState,
  hide,
  user,
}) => {
  const [showSidenav, setShowSidenav] = useState(false);

  useEffect(() => {
    setShowSidenav(sidenavState === OpenClosedStates.Open);
  }, [sidenavState]);

  return (
    <>
      <div
        className="lg:hidden print:hidden"
        hidden={sidenavState === OpenClosedStates.Closed}
      >
        <div className="fixed inset-0 flex z-40">
          <Overlay show={showSidenav} opacity={90} />
          <Transition
            show={showSidenav}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-3 pb-4 bg-gray-50">
              <MobileMenuCloseButton
                sidenavState={sidenavState}
                setSidenavState={setSidenavState}
              />

              <div className="px-4">
                <Logo />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2">
                  <SidenavMenu user={user} />
                </nav>
              </div>
            </div>
          </Transition>
          <div className="flex-shrink-0 w-14" />
        </div>
      </div>
      <div
        className={[
          hide
            ? 'hidden'
            : 'hidden lg:flex lg:flex-shrink-0 bg-white dark:bg-darkGray-900',
        ].join(' ')}
      >
        <div className="flex flex-col w-64 border-r border-gray-300 dark:border-darkGray-700 print:hidden">
          <div className="h-0 flex-1 flex flex-col overflow-y-auto">
            <div className="mt-5 flex-grow flex flex-col">
              <nav className="flex-1 px-2">
                <SidenavMenu user={user} />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidenav;
