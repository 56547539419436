import { OpenClosedStates } from '@chiroup/components';
import { useOnClickOutside } from '@chiroup/hooks';
import { Transition } from '@headlessui/react';
import React, { ChangeEvent, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import Loading from './Loading';

export enum ButtonColors {
  primary = 'primary',
  red = 'red',
  plain = 'plain',
  plainWithBorder = 'plainWithBorder',
  plainIcon = 'plainIcon',
  plainTransparent = 'plainTransparent',
}

type ButtonClassValues = {
  always: string;
  disabled: string;
  enabled: string;
};

type ButtonClasses = {
  [key in ButtonColors]: ButtonClassValues; // Note that "key in".
};

const buttonClasses: ButtonClasses = {
  primary: {
    always:
      'border border-transparent text-sm leading-5 font-medium text-white py-2 px-4',
    disabled: 'text-gray-500 bg-gray-300 cursor-not-allowed',
    enabled:
      'text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:ring-primary active:bg-primary-700',
  },
  red: {
    always:
      'border border-transparent text-sm leading-5 font-medium text-white py-2 px-4',
    disabled: 'text-gray-500 bg-gray-300 cursor-not-allowed',
    enabled:
      'text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:ring-red active:bg-red-700',
  },
  plain: {
    always:
      'inline-flex items-center px-4 py-2 text-sm leading-5 font-medium text-gray-700 dark:text-darkGray-400 bg-white dark:bg-darkGray-600 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 dark:active:bg-darkGray-600 transition duration-150 ease-in-out',
    disabled: 'text-gray-500 bg-gray-300 cursor-not-allowed',
    enabled: '',
  },
  plainTransparent: {
    always:
      'inline-flex items-center px-4 py-2 text-sm leading-5 font-medium text-gray-700 dark:text-darkGray-400 bg-transparent hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 dark:active:bg-darkGray-600 transition duration-150 ease-in-out',
    disabled: 'text-gray-500 bg-gray-300 cursor-not-allowed',
    enabled: '',
  },
  plainWithBorder: {
    always:
      'border border-gray-300 dark:border-darkGray-800 inline-flex items-center px-4 py-2 text-sm leading-5 font-medium text-gray-700 dark:text-darkGray-400 bg-white dark:bg-darkGray-600 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 dark:active:bg-darkGray-600 transition duration-150 ease-in-out',
    disabled: 'text-gray-500 bg-gray-300 cursor-not-allowed',
    enabled: '',
  },
  plainIcon: {
    always:
      'inline-flex items-center text-sm leading-5 font-medium text-gray-700 dark:text-darkGray-400 bg-white dark:bg-darkGray-600 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 dark:active:bg-darkGray-600 transition duration-150 ease-in-out',
    disabled: 'text-gray-500 bg-gray-300 cursor-not-allowed',
    enabled: '',
  },
};

type Props = {
  text?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  color?: ButtonColors;
  icon?: React.ReactNode;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  extraOptions?: { text: string; onClick: (e?: any) => void }[];
  tooltip?: string;
  fileUpload?: boolean;
  onSelectFile?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Button: React.FC<Props> = ({
  text,
  onClick,
  disabled,
  loading,
  fullWidth,
  fullHeight,
  color = ButtonColors.primary,
  icon,
  className,
  type = 'button',
  extraOptions,
  tooltip,
  fileUpload,
  onSelectFile,
}) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const buttonClass = buttonClasses[color];
  const [extraOptionsState, setExtraOptionsState] = useState(
    OpenClosedStates.Closed,
  );
  const ref = useRef<HTMLDivElement>(null);
  const id = uuidv4();
  useOnClickOutside(ref, () => setExtraOptionsState(OpenClosedStates.Closed));

  const toggleSaveDropdown = () => {
    setExtraOptionsState((v) =>
      v === OpenClosedStates.Open
        ? OpenClosedStates.Closed
        : OpenClosedStates.Open,
    );
  };

  const onClickStep = (e: any) => {
    if (fileUpload) {
      fileInput.current?.click();
    } else {
      onClick?.(e);
    }
  };

  return (
    <>
      {extraOptions?.length && !onClick ? (
        <div ref={ref}>
          <span
            id={`tt-${id}`}
            className={[
              '-ml-px relative block',
              fullWidth ? 'w-full' : '',
              fullHeight ? 'h-full' : '',
            ].join(' ')}
          >
            <button
              type={type}
              onClick={toggleSaveDropdown}
              className={[
                'inline-flex justify-center items-center py-2 pl-4 pr-2 rounded-md',
                buttonClass.always,
                disabled ? buttonClass.disabled : buttonClass.enabled,
                fullWidth ? 'w-full' : '',
                fullHeight ? 'h-full' : '',
                className,
              ].join(' ')}
              disabled={disabled || loading}
            >
              {loading && <Loading className="-ml-1 mr-3" />}
              {text}
              <svg
                className="h-5 w-5 ml-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>

            <Transition
              show={extraOptionsState === OpenClosedStates.Open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <div className="origin-top-right absolute bottom-12 right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <div
                  className="py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="option-menu"
                >
                  {extraOptions.map((opt, i) => (
                    <div
                      key={i}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                      role="menuitem"
                      onClick={() => {
                        opt.onClick();
                        toggleSaveDropdown();
                      }}
                    >
                      {opt.text}
                    </div>
                  ))}
                </div>
              </div>
            </Transition>
          </span>
        </div>
      ) : (
        // <span className="relative z-0 inline-flex rounded-md">
        <label
          id={`tt-${id}`}
          className={[
            'relative inline-flex',
            fullWidth ? 'w-full' : '',
            fullHeight ? 'h-full' : '',
            icon && !text ? 'h-5' : '',
          ].join(' ')}
          htmlFor="file-upload"
        >
          <button
            type={type}
            onClick={onClickStep}
            className={[
              'inline-flex justify-center items-center',
              extraOptions?.length ? 'rounded-l-md' : 'rounded-md',
              buttonClass.always,
              disabled ? buttonClass.disabled : buttonClass.enabled,
              fullWidth ? 'w-full' : '',
              fullHeight ? 'h-full' : '',
              className,
            ].join(' ')}
            disabled={disabled || loading}
          >
            {loading ? (
              <Loading className="-ml-1 mr-3 text-gray-500 dark:text-darkGray-400" />
            ) : icon ? (
              <svg
                className={['h-5 w-5', text ? '-ml-1 mr-2' : ''].join(' ')}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                {icon}
              </svg>
            ) : null}
            {text}
          </button>
          {extraOptions?.length && (
            <span className="-ml-px relative block">
              <button
                type="button"
                className={[
                  'relative inline-flex items-center px-2 py-2 rounded-r-md text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 border-r-0 border-t-0 border-b-0 border-l border-white h-full',
                  buttonClass.always,
                  disabled ? buttonClass.disabled : buttonClass.enabled,
                  className,
                ].join(' ')}
                id="option-menu"
                aria-expanded="true"
                aria-haspopup="true"
                onClick={toggleSaveDropdown}
              >
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <Transition
                show={extraOptionsState === OpenClosedStates.Open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div
                  className="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="option-menu"
                >
                  <div className="py-1" role="none">
                    {extraOptions.map((opt, i) => (
                      <div
                        key={i}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                        onClick={() => {
                          opt.onClick();
                          toggleSaveDropdown();
                        }}
                      >
                        {opt.text}
                      </div>
                    ))}
                  </div>
                </div>
              </Transition>
            </span>
          )}
          {fileUpload && (
            <input
              ref={fileInput}
              id="file-upload"
              name="file-upload"
              type="file"
              className="sr-only"
              onChange={onSelectFile}
            />
          )}
        </label>
        // </span>
      )}

      {tooltip && (
        <Tooltip anchorId={`tt-${id}`}>
          <span>{tooltip}</span>
        </Tooltip>
      )}
    </>
  );
};

export default Button;
