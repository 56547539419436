import { OpenClosedStates, InfoItemHtml } from '@chiroup/components';
import { Test } from '@chiroup/core';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColors } from '../../common/Button';
import SectionContainer from '../../layout/SectionContainer';
import SectionHeader from '../../layout/SectionHeader';
import TestDetailEditPanel from './TestDetailEditPanel';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query';

type Props = {
  data: Test;
  save: (val: Partial<Test>) => Promise<Test>;
  // tags?: SelectOption[];
  clinicVersion?: boolean;
  refetch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<Test, unknown>>;
  del: () => void;
  isDeleting?: boolean;
};

const TestDetail: React.FC<Props> = ({
  data,
  save,
  // tags = [],
  clinicVersion,
  refetch,
  del,
  isDeleting,
}) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { itemId } = useParams();
  const [editSlideOverState, setEditSlideOverState] =
    useState<OpenClosedStates>(OpenClosedStates.Closed);

  useEffect(() => {
    const queryParams = qs.parse(search);
    const editOpen = queryParams.open?.includes('edit');
    setEditSlideOverState(
      editOpen ? OpenClosedStates.Open : OpenClosedStates.Closed,
    );
  }, [search]);

  const updateSlideOverState = () => {
    navigate(`/tests/${itemId}${clinicVersion ? '/clinic' : ''}`);
  };

  return (
    <>
      <SectionContainer className="border border-gray-300 dark:border-darkGray-800 sm:shadow">
        <SectionHeader
          title={data?.name || ''}
          rightSide={
            <Link to="?open=edit">
              <Button
                text="Edit"
                icon={
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                }
                color={ButtonColors.plainWithBorder}
              />
            </Link>
          }
        />
        <div className="px-4 py-5 sm:px-6">
          {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            {/*<InfoItem label="Tags" value={data?.tags?.join(', ')} />*/}
            <InfoItemHtml
              className="col-span-2"
              label="Description"
              value={data?.descr}
            />
            <InfoItemHtml
              className="col-span-2"
              label="Associated Billing Codes"
              value={data?.referenceCodes}
              optional={true}
            />
          </dl>
        </div>
      </SectionContainer>
      {editSlideOverState === OpenClosedStates.Open && (
        <TestDetailEditPanel
          editSlideOverState={editSlideOverState}
          updateSlideOverState={updateSlideOverState}
          defaultValues={data}
          save={save}
          // tags={tags}
          refetch={refetch}
          del={del}
          isDeleting={isDeleting}
        />
      )}
    </>
  );
};
export default TestDetail;
