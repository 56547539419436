import React, { useMemo } from 'react';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import { Link } from 'react-router-dom';
import { CustomizationListItem } from '../../practice-resources/forms-library/CustomizationListItem.type';
import List from './List';
import ListItem from './ListItem';
import { GlobeAltIcon, HomeIcon } from '@heroicons/react/24/outline';

type Props = {
  data?: {
    data: CustomizationListItem[];
    skip?: number;
  }[];
  isFetching: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: (options?: FetchNextPageOptions | undefined) => Promise<
    InfiniteQueryObserverResult<
      {
        data: CustomizationListItem[];
        skip: number;
      },
      unknown
    >
  >;
  hasNextPage?: boolean;
  pathPrefix?: string;
  clientSide?: boolean;
  suppressImage?: boolean;
};

const StandardList: React.FC<Props> = ({
  data,
  isFetching,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  pathPrefix,
  clientSide = false,
  suppressImage = false,
}) => {
  return (
    <List
      isFetching={isFetching}
      isFetchingNextPage={!!isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      clientSide={clientSide}
    >
      {data?.map((page, i: number) => (
        <React.Fragment key={i}>
          {page?.data?.map((row, rowKey: number) => (
            <Link
              to={`${pathPrefix}/${
                row.userVersion || row.clinicVersion || row.ID
              }`}
              key={rowKey}
            >
              <ListItem index={rowKey} newItem={row.$new}>
                <div className="relative group flex items-center space-x-4">
                  {!suppressImage && (
                    <div
                      className="w-12 h-12"
                      style={{ minHeight: '3rem', minWidth: '3rem' }}
                    >
                      {row.image || row.icon || row.img ? (
                        <img
                          className="w-full h-auto rounded-md"
                          src={row.image || row.icon || row.img}
                          alt=""
                        />
                      ) : row?.online ? (
                        <GlobeAltIcon color="#89CFF0" />
                      ) : row?.online === false ? (
                        <HomeIcon color="#F4CEAF" />
                      ) : (
                        <svg
                          className="flex-shrink-0 w-full h-full text-gray-400 group-hover:text-gray-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="2 2 16 16"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                  <div className="flex items-center justify-between space-x-4">
                    <div className="min-w-0 space-y-3">
                      <div className="flex items-center space-x-3">
                        <span className="block">
                          <h2 className="text-sm font-medium leading-5">
                            <span className="absolute inset-0" />
                            {row.title || row.name}
                          </h2>
                        </span>
                      </div>
                      <span className="relative group flex items-center space-x-2.5">
                        <div className="text-sm leading-5 text-gray-500 group-hover:text-gray-900 font-medium truncate md:whitespace-normal">
                          {row.descr}
                        </div>
                      </span>
                    </div>
                    <div className="lg:hidden">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </ListItem>
            </Link>
          ))}
        </React.Fragment>
      ))}
    </List>
  );
};

export default StandardList;
