import { Checkbox } from '@chiroup/components';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { ResearchFilterQueries } from './ResearchList';

type Props = {
  onChange: (val: keyof ResearchFilterQueries) => any;
  patchValue: (values: Partial<ResearchFilterQueries>) => void;
  value?: Partial<ResearchFilterQueries>;
};

const ResearchFilters = ({ onChange, patchValue, value }: Props) => {
  const today = dayjs().format('MMM YYYY');
  const lastMonth = dayjs().subtract(1, 'month').format('MMM YYYY');
  const twoMonthsAgo = dayjs().subtract(2, 'month').format('MMM YYYY');

  const [months, setMonths] = useState({
    twoMonthsAgo: false,
    lastMonth: false,
    thisMonth: false,
  });

  const setMonth = (val: any) => {
    if (!val.twoMonthsAgo && !val.lastMonth && !val.thisMonth) {
      patchValue({ minDate: undefined, maxDate: undefined, skipMonth: false });
      setMonths(val);
      return;
    }

    let startDate = dayjs(twoMonthsAgo).startOf('month').toDate();
    let endDate = dayjs(today).endOf('month').toDate();
    let skipMonth = false;

    if (val.twoMonthsAgo) {
      if (!val.lastMonth && !val.thisMonth) {
        endDate = dayjs(twoMonthsAgo).endOf('month').toDate();
      } else if (val.lastMonth && !val.thisMonth) {
        endDate = dayjs(lastMonth).endOf('month').toDate();
      } else if (val.thisMonth && !val.lastMonth) {
        skipMonth = true;
      }
    }

    if (!val.twoMonthsAgo && val.lastMonth) {
      startDate = dayjs(lastMonth).startOf('month').toDate();
      if (!val.thisMonth) {
        endDate = dayjs(lastMonth).endOf('month').toDate();
      }
    }

    if (!val.twoMonthsAgo && !val.lastMonth && val.thisMonth) {
      startDate = dayjs(today).startOf('month').toDate();
    }
    patchValue({ minDate: startDate, maxDate: endDate, skipMonth });
    setMonths(val);
  };

  return (
    <>
      <div className="flex flex-col flex-wrap justify-start gap-1 h-1/2">
        <span className="text-md text-gray-500">Category</span>
        <Checkbox
          name="unclassified"
          label="Unclassified"
          onChange={onChange('unclassified')}
          value={value?.unclassified}
        />
        <Checkbox
          name="newsfeed"
          label="News feed"
          onChange={onChange('newsFeed')}
          value={value?.newsFeed}
        />
        <Checkbox
          name="csm"
          label="CSM"
          onChange={onChange('CSM')}
          value={value?.CSM}
        />
        <Checkbox
          name="facebook"
          label="Clinical Asset"
          onChange={onChange('faceBook')}
          value={value?.faceBook}
        />
        <Checkbox
          name="protocol"
          label="Protocol"
          onChange={onChange('protocol')}
          value={value?.protocol}
        />

        <Checkbox
          name="blog"
          label="Blog topic"
          onChange={onChange('blog')}
          value={value?.blog}
        />
        <Checkbox
          name="mdNewsletter"
          label="M.D. newsletter"
          onChange={onChange('mdNewsletter')}
          value={value?.mdNewsletter}
        />
        <Checkbox
          name="legalNewsletter"
          label="Attorney newsletter"
          onChange={onChange('legalNewsletter')}
          value={value?.legalNewsletter}
        />
      </div>
      <div className="flex flex-col flex-wrap justify-start gap-1">
        <span className="text-md text-gray-500">Status</span>
        <Checkbox
          name="pending"
          label="Pending"
          onChange={onChange('pending')}
          value={value?.pending}
          disabled={
            !value?.unclassified &&
            !value?.newsFeed &&
            !value?.faceBook &&
            !value?.protocol &&
            !value?.CSM &&
            !value?.blog &&
            !value?.mdNewsletter &&
            !value?.legalNewsletter
          }
        />
        <Checkbox
          name="complete"
          label="Complete"
          onChange={onChange('complete')}
          value={value?.complete}
          disabled={
            !value?.unclassified &&
            !value?.newsFeed &&
            !value?.faceBook &&
            !value?.protocol &&
            !value?.CSM &&
            !value?.blog &&
            !value?.mdNewsletter &&
            !value?.legalNewsletter
          }
        />
        <Checkbox
          name="published"
          label="Published"
          onChange={onChange('published')}
          value={value?.published}
          disabled={
            !value?.unclassified &&
            !value?.newsFeed &&
            !value?.faceBook &&
            !value?.protocol &&
            !value?.CSM &&
            !value?.blog &&
            !value?.mdNewsletter &&
            !value?.legalNewsletter
          }
        />
      </div>
      <div className="flex flex-col flex-wrap justify-start gap-2 pl-5">
        <span className="text-md text-gray-500">Month(s)</span>
        <Checkbox
          label={twoMonthsAgo}
          value={months.twoMonthsAgo}
          onChange={(e) => {
            setMonth({
              ...months,
              twoMonthsAgo: e,
            });
          }}
        />
        <Checkbox
          label={lastMonth}
          onChange={(e) => {
            setMonth({
              ...months,
              lastMonth: e,
            });
          }}
          value={months.lastMonth}
        />
        <Checkbox
          label={today}
          onChange={(e) => {
            setMonth({
              ...months,
              thisMonth: e,
            });
          }}
          value={months.thisMonth}
        />
      </div>
    </>
  );
};

export default ResearchFilters;
