import React from 'react';
import SectionHeader from '../layout/SectionHeader';

type Props = {
  title: string;
  open: boolean;
  onToggle: () => void;
  hugTop?: boolean;
  className?: string;
  children: React.ReactNode;
};

const Accordion: React.FC<Props> = ({
  title,
  open,
  onToggle,
  children,
  hugTop = false,
  className = '',
}) => {
  return (
    <div className={className}>
      <SectionHeader
        backgroundColor=""
        accordionGroup
        open={open}
        onClick={onToggle}
        title={title}
        rightSide={
          open ? (
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          )
        }
      />
      <div
        className={[
          'px-4 pb-4 sm:px-6',
          open ? 'block' : 'hidden',
          hugTop ? '' : 'pt-4 sm:pt-6',
        ].join(' ')}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
