import React from 'react';

type Props = {
  text: string;
  mobileText?: string;
  icon?: React.ReactNode;
  dismiss?: () => void;
  rightSide?: React.ReactNode;
  error?: boolean;
};

const Announcement: React.FC<Props> = ({
  text,
  mobileText,
  icon,
  dismiss,
  rightSide,
  error,
}) => {
  return (
    <div
      className={[
        'px-4 sm:px-6 lg:px-8',
        error ? 'bg-red-600' : 'bg-primary-600',
      ].join(' ')}
    >
      <div className="py-3">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            {icon && (
              <span
                className={[
                  'flex p-2 rounded-lg mr-3',
                  error ? '' : 'bg-primary-800',
                ].join(' ')}
              >
                {icon}
              </span>
            )}
            <p className="font-medium text-white">
              {mobileText ? (
                <>
                  <span className="md:hidden">{mobileText}</span>
                  <span className="hidden md:inline">{text}</span>
                </>
              ) : (
                <span className="inline">{text}</span>
              )}
            </p>
          </div>
          {rightSide}
          <div
            className={[
              'order-2 flex-shrink-0 sm:order-3',
              dismiss ? 'sm:ml-3' : '',
            ].join(' ')}
          >
            {dismiss ? (
              <button
                type="button"
                className={[
                  '-mr-1 flex p-2 rounded-md focus:outline-none sm:-mr-2 transition ease-in-out duration-150',
                  error
                    ? 'focus:bg-red-500 hover:bg-red-500'
                    : 'focus:bg-primary-500 hover:bg-primary-500',
                ].join(' ')}
                aria-label="Dismiss"
                onClick={dismiss}
              >
                <svg
                  className="h-6 w-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            ) : (
              <div
                className={[
                  '-mr-1 flex p-2 rounded-md focus:outline-none sm:-mr-2 transition ease-in-out duration-150',
                  error
                    ? 'focus:bg-red-500 hover:bg-red-500'
                    : 'focus:bg-primary-500 hover:bg-primary-500',
                ].join(' ')}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Announcement;
