import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MarketingContainer from './detail/MarketingContainer';
// import MarketingContainer from './detail/MarketingContainer';
import MarketingList from './MarketingList';

const MarketingCampaigns = () => {
  return (
    <Routes>
      <Route path="/" element={<MarketingList />} />
      <Route path={`/:id`} element={<MarketingContainer />} />
      <Route path="*" element={<span>no match</span>} />
    </Routes>
  );
};

export default MarketingCampaigns;
