import React from 'react';
import UploadedImage from './UploadedImage';
import UploadedVideo from './UploadedVideo';

type Props = {
  type: 'image' | 'video';
  url: string;
};

const UploadedItem: React.FC<Props> = ({ type, url }) => {
  return type === 'image' ? (
    <UploadedImage url={url} />
  ) : type === 'video' ? (
    <UploadedVideo url={url} />
  ) : (
    <>Invalid upload.</>
  );
};

export default UploadedItem;
