import { ChiroUpAPI } from '@chiroup/client-core';

const appDataService = () => {
  const get = async (key: string): Promise<any> => {
    return ChiroUpAPI.get('api', `/app-data/${key}`, {});
  };

  const update = async (key: string, data: any) => {
    return ChiroUpAPI.put('api', `/app-data/${key}`, {
      body: data,
    });
  };

  return { get, update };
};

export default appDataService();
