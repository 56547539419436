import { CustomizationListItem } from '../components/practice-resources/forms-library/CustomizationListItem.type';
import { Test } from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';

const testService = () => {
  const list = async (): Promise<{
    data: CustomizationListItem[];
    skip: number;
  }> => {
    return ChiroUpAPI.get('api', `/tests`, {});
  };

  const add = async (body: Partial<Test>): Promise<Test> => {
    return ChiroUpAPI.post('api', `/tests`, {
      body,
    });
  };

  const findOne = async (id: string): Promise<Test> => {
    return ChiroUpAPI.get('api', `/tests/${id}`, {});
  };

  const save = async (
    id: string | number,
    body: Partial<Test>,
  ): Promise<Test> => {
    return await ChiroUpAPI.patch('api', `/tests/${id}`, {
      body,
    });
  };

  return {
    list,
    add,
    findOne,
    save,
  };
};

export default testService();
