import { MarketingCampaignDetail } from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';

const marketingService = () => {
  const list = async (params: {
    search?: string;
    limit?: number;
    skip?: number;
  }) => {
    return ChiroUpAPI.get('api', `/marketing`, {
      queryParams: params,
    });
  };

  const findOne = async (id?: string): Promise<MarketingCampaignDetail> => {
    const res = await ChiroUpAPI.get('api', `/marketing/${id}`, {});
    return res || {};
  };

  const update = async (marketing: Partial<MarketingCampaignDetail>) => {
    const { ID, ...restOfMarketing } = marketing;
    return ChiroUpAPI.put('api', `/marketing/${ID}`, {
      body: restOfMarketing,
    });
  };

  const del = (marketingId: string) => {
    return ChiroUpAPI.del('api', `/marketing/${marketingId}`, {});
  };

  const create = async (val: Partial<MarketingCampaignDetail>) => {
    return ChiroUpAPI.post('api', `/marketing`, {
      body: val,
    });
  };

  const save = async (marketing: Partial<MarketingCampaignDetail>) => {
    if (marketing.ID) {
      return update(marketing);
    } else {
      return create(marketing);
    }
  };

  return {
    list,
    findOne,
    update,
    del,
    create,
    save,
  };
};

export default marketingService();
