import { Input } from '@chiroup/components';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  ChevronUpDownIcon,
  PlusCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import React, { useEffect, useState } from 'react';
import appDataService from '../../services/app-data.service';
import Button from '../common/Button';
import DashboardDivider from '../dashboard/DashboardDivider';
import Header from '../layout/Header';

const emptyData = {
  videos: [
    {
      id: 'f692a514-fa43-4098-b434-26fdcb4f28f9',
      title: '',
      text: '',
      link: '',
    },
    {
      id: '6fefd64e-d144-4909-9be3-5f9f6d75a4c4',
      title: '',
      text: '',
      link: '',
    },
    {
      id: 'b42089ac-45a8-433d-8811-9b5de97b7802',
      title: '',
      text: '',
      link: '',
    },
  ],
  knowledgebase: [
    {
      id: 'd8ed10ef-e9fa-4d55-91ed-2b1b81c3d6de',
      title: '',
      text: '',
      link: '',
    },
    {
      id: '07367361-d6ba-4241-a952-4441c9bfc70a',
      title: '',
      text: '',
      link: '',
    },
    {
      id: '2f182805-0dd0-400f-82a6-1fcb8c4ebed5',
      title: '',
      text: '',
      link: '',
    },
  ],
  walkthroughs: [
    {
      id: '355be11f-acf5-4597-9090-e0f171c66d43',
      title: '',
      text: '',
      link: '',
    },
    {
      id: 'd922897f-bbc0-40d9-bb63-7a26de36cc30',
      title: '',
      text: '',
      link: '',
    },
    {
      id: 'ec8d1a2b-e8ed-4e7a-9977-e30b5d63a88a',
      title: '',
      text: '',
      link: '',
    },
  ],
};

const NewUserDashboard = () => {
  const [data, setData] = useState(emptyData);

  useEffect(() => {
    let isDestroyed = false;
    const doIt = async () => {
      const res = await appDataService.get('newUserDashboard');
      if (!isDestroyed) {
        setData(res);
      }
    };
    doIt();
    return () => {
      isDestroyed = true;
    };
  }, []);

  const save = async () => {
    await appDataService.update('newUserDashboard', data);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (
    section: 'videos' | 'knowledgebase' | 'walkthroughs',
    event: any,
  ) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setData((d) => {
        const oldIndex = d[section].findIndex((item) => item.id === active.id);
        const newIndex = d[section].findIndex((item) => item.id === over.id);
        const newStuff = arrayMove(d[section], oldIndex, newIndex);
        d[section] = newStuff;
        return JSON.parse(JSON.stringify(d));
      });
    }
  };

  const addKnowledgebase = () => {
    setData((d) => {
      return {
        ...d,
        knowledgebase: (d.knowledgebase || []).concat({
          id: `${Math.random()}`,
          title: '',
          text: '',
          link: '',
        }),
      };
    });
  };

  const removeKnowledgebase = (idToRemove: string) => {
    setData((d) => {
      return {
        ...d,
        knowledgebase: (d.knowledgebase || []).filter(
          (item) => item.id !== idToRemove,
        ),
      };
    });
  };

  const addWalkthrough = () => {
    setData((d) => {
      return {
        ...d,
        walkthroughs: (d.walkthroughs || []).concat({
          id: `${Math.random()}`,
          title: '',
          text: '',
          link: '',
        }),
      };
    });
  };

  const removeWalkthrough = (idToRemove: string) => {
    setData((d) => {
      return {
        ...d,
        walkthroughs: (d.walkthroughs || []).filter(
          (item) => item.id !== idToRemove,
        ),
      };
    });
  };

  return (
    <>
      <Header
        title="New user dashboard"
        rightSide={<Button text="Save" onClick={save} />}
      />
      <div className="pb-4">
        <DashboardDivider title="Videos" />
        <div className="m-4">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd.bind(null, 'videos')}
          >
            <SortableContext
              items={data.videos}
              strategy={verticalListSortingStrategy}
            >
              {data.videos.map((item) => (
                <SortableItem key={item.id} id={item.id}>
                  <div className="mb-4 relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm items-center hover:border-gray-400 flex flex-col gap-2 w-full">
                    <div className="flex flex-col sm:flex-row gap-4 w-full">
                      <Input
                        name="text"
                        label="Title"
                        className="w-full"
                        value={item.title}
                        onChange={(val) => {
                          setData((d) => {
                            d.videos = d.videos.map((v) => {
                              if (v.id === item.id) {
                                return {
                                  ...v,
                                  title: val,
                                };
                              } else {
                                return v;
                              }
                            });
                            return JSON.parse(JSON.stringify(d));
                          });
                        }}
                      />
                      <Input
                        name="text"
                        label="Text"
                        className="w-full"
                        value={item.text}
                        onChange={(val) => {
                          setData((d) => {
                            d.videos = d.videos.map((v) => {
                              if (v.id === item.id) {
                                return {
                                  ...v,
                                  text: val,
                                };
                              } else {
                                return v;
                              }
                            });
                            return JSON.parse(JSON.stringify(d));
                          });
                        }}
                      />
                    </div>
                    <Input
                      name="link"
                      label="Video"
                      className="w-full"
                      value={item.link}
                      onChange={(val) => {
                        setData((d) => {
                          d.videos = d.videos.map((v) => {
                            if (v.id === item.id) {
                              return {
                                ...v,
                                link: val,
                              };
                            } else {
                              return v;
                            }
                          });
                          return JSON.parse(JSON.stringify(d));
                        });
                      }}
                    />
                  </div>
                </SortableItem>
              ))}
            </SortableContext>
          </DndContext>
        </div>
        <DashboardDivider title="Knowlegebase" />
        <div className="m-4">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd.bind(null, 'knowledgebase')}
          >
            <SortableContext
              items={data.knowledgebase}
              strategy={verticalListSortingStrategy}
            >
              {data.knowledgebase.map((item) => (
                <SortableItem
                  key={item.id}
                  id={item.id}
                  removeItem={removeKnowledgebase}
                >
                  <div className="mb-4 relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm items-center hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500 flex flex-col gap-2 w-full">
                    <div className="flex flex-col sm:flex-row gap-4 w-full">
                      <Input
                        name="text"
                        label="Title"
                        className="w-full"
                        value={item.title}
                        onChange={(val) => {
                          setData((d) => {
                            d.knowledgebase = d.knowledgebase.map((v) => {
                              if (v.id === item.id) {
                                return {
                                  ...v,
                                  title: val,
                                };
                              } else {
                                return v;
                              }
                            });
                            return JSON.parse(JSON.stringify(d));
                          });
                        }}
                      />
                      <Input
                        name="text"
                        label="Text"
                        className="w-full"
                        value={item.text}
                        onChange={(val) => {
                          setData((d) => {
                            d.knowledgebase = d.knowledgebase.map((v) => {
                              if (v.id === item.id) {
                                return {
                                  ...v,
                                  text: val,
                                };
                              } else {
                                return v;
                              }
                            });
                            return JSON.parse(JSON.stringify(d));
                          });
                        }}
                      />
                    </div>
                    <Input
                      name="link"
                      label="Link"
                      className="w-full"
                      value={item.link}
                      onChange={(val) => {
                        setData((d) => {
                          d.knowledgebase = d.knowledgebase.map((v) => {
                            if (v.id === item.id) {
                              return {
                                ...v,
                                link: val,
                              };
                            } else {
                              return v;
                            }
                          });
                          return JSON.parse(JSON.stringify(d));
                        });
                      }}
                    />
                  </div>
                </SortableItem>
              ))}
            </SortableContext>
          </DndContext>
          <PlusCircleIcon
            className="h-8 w-8 text-primary-500 cursor-pointer"
            onClick={addKnowledgebase}
          />
        </div>
        <DashboardDivider title="Walkthroughs" />
        <div className="m-4">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd.bind(null, 'walkthroughs')}
          >
            <SortableContext
              items={data.walkthroughs}
              strategy={verticalListSortingStrategy}
            >
              {data.walkthroughs.map((item) => (
                <SortableItem
                  key={item.id}
                  id={item.id}
                  removeItem={removeWalkthrough}
                >
                  <div className="mb-4 relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm items-center hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500 flex flex-col gap-2 w-full">
                    <div className="flex flex-col sm:flex-row gap-4 w-full">
                      <Input
                        name="text"
                        label="Title"
                        className="w-full"
                        value={item.title}
                        onChange={(val) => {
                          setData((d) => {
                            d.walkthroughs = d.walkthroughs.map((v) => {
                              if (v.id === item.id) {
                                return {
                                  ...v,
                                  title: val,
                                };
                              } else {
                                return v;
                              }
                            });
                            return JSON.parse(JSON.stringify(d));
                          });
                        }}
                      />
                      <Input
                        name="text"
                        label="Text"
                        className="w-full"
                        value={item.text}
                        onChange={(val) => {
                          setData((d) => {
                            d.walkthroughs = d.walkthroughs.map((v) => {
                              if (v.id === item.id) {
                                return {
                                  ...v,
                                  text: val,
                                };
                              } else {
                                return v;
                              }
                            });
                            return JSON.parse(JSON.stringify(d));
                          });
                        }}
                      />
                    </div>
                    <Input
                      name="link"
                      label="Link"
                      className="w-full"
                      value={item.link}
                      onChange={(val) => {
                        setData((d) => {
                          d.walkthroughs = d.walkthroughs.map((v) => {
                            if (v.id === item.id) {
                              return {
                                ...v,
                                link: val,
                              };
                            } else {
                              return v;
                            }
                          });
                          return JSON.parse(JSON.stringify(d));
                        });
                      }}
                    />
                  </div>
                </SortableItem>
              ))}
            </SortableContext>
          </DndContext>
          <PlusCircleIcon
            className="h-8 w-8 text-primary-500 cursor-pointer"
            onClick={addWalkthrough}
          />
        </div>
      </div>
    </>
  );
};

export default NewUserDashboard;

type SortableProps = {
  id: string;
  children?: React.ReactNode;
  removeItem?: (id: string) => void;
};

const SortableItem: React.FC<SortableProps> = ({
  id,
  children,
  removeItem,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="relative">
      <ChevronUpDownIcon
        className="h-6 w-6 text-gray-400 focus:outline-none absolute left-1 top-2 z-40"
        {...listeners}
        {...attributes}
      />
      {removeItem && (
        <XMarkIcon
          className="h-6 w-6 text-gray-400 focus:outline-none absolute right-1 top-2 z-40 cursor-pointer"
          onClick={() => removeItem(id)}
        />
      )}
      {children}
    </div>
  );
};
