import React from 'react';
import { Link } from 'react-router-dom';

export type Tab = {
  name: string;
  href: string;
  active?: boolean;
};

type Props = {
  tabs: Tab[];
};

const Tabs: React.FC<Props> = ({ tabs }) => {
  return (
    <div className="bg-white dark:bg-darkGray-900">
      <div className="border-b border-gray-300 dark:border-darkGray-700 px-6">
        <nav className="-mb-px flex mx-auto">
          {(tabs || []).map((tab) => (
            <Link
              key={tab.href}
              to={tab.href}
              className={[
                'whitespace-nowrap mr-8 py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 dark:text-darkGray-400',
                tab.active
                  ? 'border-primary-500 font-medium text-sm leading-5 text-primary-600 focus:outline-none'
                  : 'hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300 dark:hover:border-gray-600 focus:outline-none focus:text-gray-700 focus:border-gray-300',
              ].join(' ')}
            >
              {tab.name}
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Tabs;
