import React from 'react';

type Props = {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  leftSide?: React.ReactNode;
  rightSide?: React.ReactNode;
  onClick?: () => void;
  open?: boolean;
  accordionGroup?: boolean;
  backgroundColor?: string;
  adminImgClass?: string;
};

const SectionHeader: React.FC<Props> = ({
  title,
  subtitle,
  leftSide,
  rightSide,
  onClick,
  open = true,
  accordionGroup = false,
  backgroundColor = 'bg-white dark:bg-darkGray-600',
  adminImgClass,
}) => {
  return (
    <div
      onClick={onClick}
      className={[
        'px-4 py-5 border-b border-gray-300 dark:border-darkGray-600 sm:px-6',
        backgroundColor,
        onClick ? 'cursor-pointer' : '',
        open ? 'rounded-t-lg' : accordionGroup ? '' : 'rounded-lg',
      ].join(' ')}
    >
      <div
        className={[
          'flex sm:items-center justify-between',
          accordionGroup ? '' : 'mt-2',
        ].join(' ')}
      >
        <div
          className={['flex flex-row', rightSide ? 'gap-1' : 'w-full'].join(
            ' ',
          )}
        >
          {!!leftSide && <div style={{ marginTop: 2 }}>{leftSide}</div>}
          <div className={`mb-6 sm:mb-0 ${adminImgClass ? adminImgClass : ''}`}>
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-darkGray-100">
              {title}
            </h3>
            {subtitle && (
              <div className="mt-1 text-sm leading-5 text-gray-500 dark:text-darkGray-400">
                {subtitle}
              </div>
            )}
          </div>
        </div>
        {!!rightSide && <span>{rightSide}</span>}
      </div>
    </div>
  );
};

export default SectionHeader;
