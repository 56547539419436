import { useForm } from '@chiroup/hooks';
import React, { useEffect, useState } from 'react';
import {
  InfoItemHtml,
  OpenClosedStates,
  tiptapExtensions,
} from '@chiroup/components';
import SearchInput from '../common/fields/inputs/SearchInput';
import Filters from '../common/Filters';
import List from '../common/list/List';
import ListItem from '../common/list/ListItem';
import Header from '../layout/Header';
import useResearchList from '../../hooks/useResearchList';
import Button from '../common/Button';
import ResearchFilters from './ResearchFilters';
import ResearchEditPanel from './ResearchEditPanel';
import researchService from '../../services/research/research.service';
import { compile } from 'html-to-text';
import { saveAs } from 'file-saver';
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  HeadingLevel,
  SectionType,
  PageBreak,
} from 'docx';
import dayjs from 'dayjs';
import { generateText } from '@tiptap/react';

export type ResearchFilterQueries = {
  search: string;
  unclassified?: boolean;
  newsFeed?: boolean;
  faceBook?: boolean;
  protocol?: boolean;
  CSM?: boolean;
  blog?: boolean;
  mdNewsletter?: boolean;
  legalNewsletter?: boolean;
  pending?: boolean;
  complete?: boolean;
  published?: boolean;
  minDate?: Date;
  skipMonth?: boolean;
  maxDate?: Date;
};

const defaultValue = {
  search: '',
  unclassified: false,
  newsFeed: false,
  faceBook: false,
  protocol: false,
  CSM: false,
  blog: false,
  mdNewsletter: false,
  legalNewsletter: false,
  pending: false,
  complete: false,
  published: false,
  minDate: undefined,
  maxDate: undefined,
  skipMonth: undefined,
};

const emptyArticle = {
  CSMQuote: '',
  createDate: '',
  fbQuote: '',
  feedIcon: '',
  feedQuote: '',
  feedTitle: '',
  link: '',
  quote: '',
  van6: '',
  unclassfied: false,
  newsFeedExisting: false,
  newsFeedComplete: false,
  newsFeedPublished: false,
  fbExisting: false,
  fbComplete: false,
  fbPublished: false,
  protocolExisting: false,
  protocolPublished: false,
  csmExisting: false,
  csmComplete: false,
  csmPublished: false,
  blogExisting: false,
  blogPublished: false,
  mdNewsletterExisting: false,
  mdNewsletterPublished: false,
  legalNewsletterExisting: false,
  legalNewsletterPublished: false,
};

const ResearchList = () => {
  const {
    data,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    onSearch,
    refetch,
  } = useResearchList();
  const { value, onChange, patchValue } =
    useForm<ResearchFilterQueries>(defaultValue);
  const [currentArticle, setCurrentArticle] = useState<Partial<any>>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onSearch(value || defaultValue);
  }, [value, onSearch]);

  const exportAll = async () => {
    setLoading(true);
    const res = await researchService.list({
      filters: { ...value } || defaultValue,
      limit: 1000,
      skip: 0,
    });

    const convert = compile({
      preserveNewlines: true,
    });
    const children: any = [];

    res.data.forEach((article: any) => {
      let feedTitle = article.feedTitle;
      let feedQuote = article.feedQuote;
      const articleQuote = (
        !article.quote
          ? null
          : typeof article.quote === 'object'
            ? generateText(article.quote, tiptapExtensions)
            : convert(article.quote)
      )?.split(/\n\n/g);
      const articleVan6 = !article.van6
        ? null
        : typeof article.van6 === 'object'
          ? generateText(article.van6, tiptapExtensions)
          : convert(article.van6);
      const articleLink = !article.link
        ? null
        : typeof article.link === 'object'
          ? generateText(article.link, tiptapExtensions)
          : convert(article.link);
      feedTitle = !article.feedTitle
        ? null
        : typeof article.feedTitle === 'object'
          ? generateText(article.feedTitle, tiptapExtensions)
          : convert(article.feedTitle);
      feedQuote = (
        !article.feedQuote
          ? null
          : typeof article.feedQuote === 'object'
            ? generateText(article.feedQuote, tiptapExtensions)
            : convert(article.feedQuote)
      )?.split(/\n\n/g);
      children.push(
        new Paragraph({
          text: 'Article',
          heading: HeadingLevel.HEADING_1,
          children: [
            new TextRun({
              text: '',
              break: 1,
            }),
          ],
        }),
        ...(articleQuote || []).map((quote: string, index: number) => {
          if (index === (articleQuote || []).length - 1) {
            return new Paragraph({
              text: quote,
            });
          } else {
            return new Paragraph({
              text: quote,
            });
          }
        }),
        new Paragraph({
          children: [
            new TextRun({ text: articleVan6 || '', break: 1 }),
            new TextRun({ text: 'Link: ' + articleLink, break: 1 }),
            new TextRun({ text: '', break: 1 }),
          ],
        }),
        new Paragraph({
          text: 'News Feed',
          heading: HeadingLevel.HEADING_2,
          children: [
            new TextRun({
              text: '',
              break: 1,
            }),
          ],
        }),
        new Paragraph({
          text: feedTitle,
          heading: HeadingLevel.HEADING_3,
          children: [new TextRun({ text: '', break: 1 })],
        }),
        ...(feedQuote || []).map((quote: string) => {
          return new Paragraph({
            text: quote,
          });
        }),
        new Paragraph({
          children: [
            new TextRun({ text: '', break: 1 }),
            new TextRun({ text: '', break: 1 }),
          ],
        }),
      );
    });
    const doc = new Document({
      sections: [
        {
          properties: {
            type: SectionType.NEXT_PAGE,
          },
          children,
        },
      ],
    });

    Packer.toBuffer(doc).then((buffer) => {
      saveAs(new Blob([buffer]), `export_${dayjs().format('MM-DD-YYYY')}.docx`);
    });
    setLoading(false);
  };
  return (
    <>
      <Header
        title="Research"
        rightSide={
          <>
            <Button
              text="Export"
              onClick={() => exportAll()}
              loading={loading}
              disabled={loading}
            />
            <Button
              text="Add new"
              onClick={() => setCurrentArticle(emptyArticle)}
            />
          </>
        }
      >
        <Filters
          main={
            <SearchInput onChange={onChange('search')} value={value.search} />
          }
          additional={
            <ResearchFilters
              onChange={onChange}
              patchValue={patchValue}
              value={value}
            />
          }
        />
      </Header>
      <List
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
      >
        {data?.pages?.map((page, i) => (
          <React.Fragment key={i}>
            {page.data.map((row, i) => (
              <div
                className="cursor-pointer"
                key={row.ID}
                onClick={() => setCurrentArticle(row)}
              >
                <ListItem index={i}>
                  {' '}
                  <div className="p-6 xl:py-8 h-36 overflow-hidden">
                    <div className="relative mb-8 md:mb-2">
                      <h2 className="leading-6 text-primary-500 font-semibold tracking-wide uppercase">
                        {row.createDate}
                      </h2>
                      <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-darkGray-200 sm:text-4xl">
                        {row.feedTitle}
                      </p>
                    </div>
                    {row.quote && <InfoItemHtml value={row.quote} />}
                  </div>
                </ListItem>
              </div>
            ))}
          </React.Fragment>
        ))}
      </List>
      {currentArticle && (
        <ResearchEditPanel
          slideOverState={
            currentArticle ? OpenClosedStates.Open : OpenClosedStates.Closed
          }
          updateSlideOverState={(val) =>
            val === OpenClosedStates.Closed
              ? setCurrentArticle(undefined)
              : () => {}
          }
          defaultValues={currentArticle}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default ResearchList;
