import React from 'react';

type Props = {
  className?: string;
  noPadding?: boolean;
  children: React.ReactNode;
};

const SectionContainer: React.FC<Props> = ({
  className,
  children,
  noPadding = false,
}) => {
  return (
    <div className={[noPadding ? '' : 'py-6'].join(' ')}>
      <div className={`rounded-lg ${className}`}>{children}</div>
    </div>
  );
};

export default SectionContainer;
