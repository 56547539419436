import { useForm } from '@chiroup/hooks';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import useUsers from '../../hooks/useUsers';
import SearchInput from '../common/fields/inputs/SearchInput';
import Filters from '../common/Filters';
import List from '../common/list/List';
import ListItem from '../common/list/ListItem';
import Header from '../layout/Header';
import UserCreate from './UserCreate';

const UserList = () => {
  const {
    data,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
    onSearch,
  } = useUsers();
  const { value, onChange } = useForm<{ search: string }>(
    {
      search: '',
    },
    {},
  );

  useEffect(() => {
    onSearch(value?.search || '');
  }, [value, onSearch]);

  const onCreate = () => {
    refetch();
  };

  return (
    <>
      <Header title="Users" rightSide={<UserCreate onCreate={onCreate} />}>
        <Filters
          main={
            <SearchInput onChange={onChange('search')} value={value.search} />
          }
        />
      </Header>
      <List
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
      >
        {data?.pages?.map((page, pageNumber) => (
          <React.Fragment key={`users-page-${pageNumber}`}>
            {page?.data?.map((row, i) => (
              <Link to={`/users/${row.ID}`} key={row.ID}>
                <ListItem index={i}>
                  <div className="flex items-center justify-between space-x-4">
                    <div className="min-w-0">
                      <div className="relative group flex items-center space-x-4">
                        <svg
                          className="flex-shrink-0 w-12 h-12 text-gray-400 group-hover:text-gray-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="2 2 16 16"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <div className="space-y-3">
                          <div className="flex items-center space-x-3">
                            <span className="block">
                              <h2 className="text-sm font-medium leading-4">
                                <span className="absolute inset-0" />
                                {row.lastFirst}
                              </h2>
                            </span>
                          </div>
                          <div className="text-sm leading-4 text-gray-500 group-hover:text-gray-900 font-medium truncate">
                            {row.email}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sm:hidden">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
                      <p className="flex items-center space-x-4">
                        <span className="relative text-sm leading-4 text-gray-500 hover:text-gray-900 font-medium">
                          {row.status}
                        </span>
                      </p>
                      <p className="flex text-gray-500 text-sm leading-4 space-x-2">
                        {/* <span>{row.phone ? formatPhone(row.phone) : ''}</span> */}
                        <span>Created: {row.createDate}</span>
                      </p>
                    </div>
                  </div>
                </ListItem>
              </Link>
            ))}
          </React.Fragment>
        ))}
      </List>
    </>
  );
};

export default UserList;
