import { useForm } from '@chiroup/hooks';
import React, { useEffect, useState } from 'react';
import { OpenClosedStates } from '@chiroup/components';
import SearchInput from '../common/fields/inputs/SearchInput';
import Filters from '../common/Filters';
import List from '../common/list/List';
import ListItem from '../common/list/ListItem';
import Header from '../layout/Header';
import Button from '../common/Button';
import useDisabilities from '../../hooks/useDisabilities';
import DisabilityEditSlideOverPanel from './DisabilityEditSlideOverPanel';

const Disabilities = () => {
  const {
    data,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    onSearch,
  } = useDisabilities();
  const { value, onChange } = useForm<any>({ search: '' });
  const [currentDisability, setCurrentDisability] = useState<Partial<any>>();

  useEffect(() => {
    onSearch(value || '');
  }, [value, onSearch]);

  return (
    <>
      <Header
        title="Disabilities"
        rightSide={
          <Button
            text="Add new"
            onClick={() => setCurrentDisability({ name: '', region: '' })}
          />
        }
      >
        <Filters
          main={
            <SearchInput onChange={onChange('search')} value={value.search} />
          }
        />
      </Header>
      <List
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
      >
        {data?.pages?.map((page, i) => (
          <React.Fragment key={i}>
            {page.data.map((row, i) => (
              <div
                className="cursor-pointer"
                key={row.ID}
                onClick={() => setCurrentDisability(row)}
              >
                <ListItem index={i}>
                  {' '}
                  <div className="p-6 xl:py-8 h-36 overflow-hidden">
                    <div className="relative mb-8 md:mb-2">
                      <h2 className="leading-6 text-primary-500 font-semibold tracking-wide uppercase">
                        {row.name}
                      </h2>
                    </div>
                  </div>
                </ListItem>
              </div>
            ))}
          </React.Fragment>
        ))}
      </List>
      {currentDisability && (
        <DisabilityEditSlideOverPanel
          slideOverState={
            currentDisability ? OpenClosedStates.Open : OpenClosedStates.Closed
          }
          updateSlideOverState={(val) =>
            val === OpenClosedStates.Closed
              ? setCurrentDisability(undefined)
              : () => {}
          }
          defaultValues={currentDisability}
          setCurrentDisability={setCurrentDisability}
        />
      )}
    </>
  );
};

export default Disabilities;
