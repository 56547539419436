import { Form } from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';

export type FormsObj = { [key: string]: Form[] };
export type FormHistory = {
  ID: number;
  exercises: string;
  name: string;
  userId: string;
};
export type Attachment = {
  url: string;
  name: string;
};

const formService = () => {
  const list = async () => {
    const res: Form[] = await ChiroUpAPI.get('api', `/form`, {});
    const tabNames: {
      [key: string]: string;
    } = {};
    const formsObj = res?.reduce((obj: FormsObj, item: Form) => {
      const type = item.type.toLowerCase().replace(' ', '-');
      obj[type] = obj[type] || [];
      obj[type].push(item);
      tabNames[type] = item.type;
      return obj;
    }, {});
    return { data: formsObj, tabNames };
  };

  const initiateUploadAttachment = async (
    selectedClinic?: string,
    contentType?: string,
    existingKey?: string | null,
  ) => {
    return ChiroUpAPI.post('api', `/${selectedClinic}/file/pdf`, {
      body: { contentType, existingKey },
    });
  };

  const updateAttachments = async (
    selectedClinic?: string,
    crAttachments?: Attachment[],
  ) => {
    return ChiroUpAPI.patch(
      'api',
      `/${selectedClinic}/clinic/${selectedClinic}`,
      {
        body: {
          ID: selectedClinic,
          crAttachments: JSON.stringify(crAttachments),
        },
      },
    );
  };

  const updateForm = async (formId?: number, body?: Partial<Form>) => {
    return ChiroUpAPI.put('api', `/form/${formId}`, { body });
  };

  const createForm = async (body: Partial<Form>, userId?: string) => {
    return ChiroUpAPI.post('api', `/form/create/${userId}`, { body });
  };

  const del = async (formId?: number) => {
    return ChiroUpAPI.del('api', `/form/${formId}`, {});
  };

  return {
    list,
    del,
    initiateUploadAttachment,
    updateAttachments,
    updateForm,
    createForm,
  };
};

export default formService();
