import { OpenClosedStates } from '@chiroup/components';
import { ErrorResponse, ListClinic } from '@chiroup/core';
import dayjs from 'dayjs';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { UseMutateFunction } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button, { ButtonColors } from '../../common/Button';
import InfoItem from '../../common/info/InfoItem';
import MoreButton from '../../common/MoreButton';
import SectionContainer from '../../layout/SectionContainer';
import SectionHeader from '../../layout/SectionHeader';
import ClinicDeleteModal from './ClinicDeleteModal';
import ClinicGeneralEditPanel from './ClinicGeneralEditPanel';

type Props = {
  clinic: Partial<ListClinic>;
  update: UseMutateFunction<
    Partial<ListClinic>,
    ErrorResponse,
    Partial<ListClinic>,
    unknown
  >;
  del: () => Promise<void>;
  isUpdating: boolean;
};

const ClinicGeneral: React.FC<Props> = ({
  clinic,
  update,
  isUpdating,
  del,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [deleteModalState, setDeleteModalState] = useState<OpenClosedStates>(
    OpenClosedStates.Closed,
  );
  const [editSlideOverState, setEditSlideOverState] =
    useState<OpenClosedStates>(OpenClosedStates.Closed);

  useEffect(() => {
    const queryParams = qs.parse(location.search);
    const editOpen = queryParams.open?.includes('edit');
    setEditSlideOverState(
      editOpen ? OpenClosedStates.Open : OpenClosedStates.Closed,
    );
  }, [location.search]);

  let clinicPlan;
  if (clinic.plan) {
    clinicPlan = clinic.plan?.slice(0, 1).toUpperCase() + clinic.plan?.slice(1);
  }

  const updateSlideOverState = () => {
    navigate(`/clinics/${clinic.ID}`);
  };

  const editClinic = () => {
    navigate(`/clinics/${clinic.ID}?open=edit`);
  };

  const onClickDelete = () => {
    setDeleteModalState(OpenClosedStates.Open);
  };

  return (
    <>
      <SectionContainer className="sm:shadow border border-gray-300 dark:border-darkGray-800 mx-6">
        <SectionHeader
          title="General"
          subtitle="General information about the clinic."
          rightSide={
            <div className="flex flex-row items-center space-x-2.5">
              <MoreButton
                options={[
                  {
                    text: 'Edit',
                    icon: (
                      <svg
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                    ),
                    onClick: editClinic,
                    className: 'block sm:hidden',
                  },
                  {
                    text: 'Delete',
                    icon: (
                      <svg
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ),
                    onClick: onClickDelete,
                  },
                ]}
              />
              <Link to="?open=edit" className="hidden sm:block">
                <Button
                  text="Edit"
                  icon={
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  }
                  color={ButtonColors.plainWithBorder}
                />
              </Link>
            </div>
          }
        />
        <div className="px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <InfoItem
              label="Primary language"
              value={clinic.language === 'es' ? 'Spanish' : 'English'}
            />
            <InfoItem label="Plan" value={clinicPlan} />
            <InfoItem
              label="Renewal date"
              value={dayjs(clinic.billDate).format('MM/DD/YYYY')}
            />
            <InfoItem
              label="Subscription start date"
              value={dayjs(clinic.subscriptionStartDate).format('MM/DD/YYYY')}
            />
            <InfoItem label="Posting Schedule" value={clinic.postingSchedule} />
          </dl>
        </div>
      </SectionContainer>
      {/* <Button text="Subscribe" onClick={openBilling} /> */}
      <ClinicGeneralEditPanel
        editSlideOverState={editSlideOverState}
        updateSlideOverState={updateSlideOverState}
        defaultValues={clinic}
        update={update}
        isUpdating={isUpdating}
      />
      {clinic.ID && (
        <ClinicDeleteModal
          state={deleteModalState}
          setState={setDeleteModalState}
          del={del}
        />
      )}
    </>
  );
};

export default ClinicGeneral;
