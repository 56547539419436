import React from 'react';
import { Input } from '@chiroup/components';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { v4 } from 'uuid';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import HomePageSortableItem from './HomePageSortableItem';
import { HomePageSubsection, HomePageSection } from '@chiroup/core';

type Props = {
  subsection: HomePageSubsection;
  setData: React.Dispatch<React.SetStateAction<HomePageSection[]>>;
};

const HomePageLinks: React.FC<Props> = ({ subsection, setData }) => {
  const removeLink = (id: string) => {
    setData((prev) => {
      prev = prev.map((v) => {
        const subIndex = v.subsections.findIndex((s) => s.id === subsection.id);
        if (subIndex !== -1) {
          v.subsections[subIndex].typeData =
            v.subsections[subIndex].typeData || {};
          (v.subsections[subIndex].typeData as any).links =
            (v.subsections[subIndex].typeData as any).links || [];
          (v.subsections[subIndex].typeData as any).links = (
            v.subsections[subIndex].typeData as any
          ).links.filter((l: any) => l.id !== id);
        }
        return v;
      });
      return prev;
    });
  };

  return (
    <div className="col-span-1 sm:col-span-6 grid sm:grid-cols-6 gap-2">
      <div>Links</div>
      <SortableContext
        id={`links-${subsection.id}`}
        items={subsection.typeData?.links || []}
        strategy={verticalListSortingStrategy}
      >
        <div className="col-span-1 sm:col-span-6 flex flex-col gap-2">
          {subsection.typeData?.links?.map((link) => (
            <HomePageSortableItem
              key={link.id}
              id={link.id}
              removeItem={removeLink}
            >
              <div className="flex flex-col sm:flex-row gap-2 col-span-1 sm:col-span-6 w-full p-6 rounded-md bg-gray-50 border border-gray-300">
                <Input
                  name="text"
                  label="Title *"
                  className="w-full"
                  value={link.title}
                  onChange={(val) => {
                    setData((prev) => {
                      prev = prev.map((v) => {
                        const subIndex = v.subsections.findIndex(
                          (s) => s.id === subsection.id,
                        );
                        if (subIndex !== -1) {
                          v.subsections[subIndex].typeData =
                            v.subsections[subIndex].typeData || {};
                          (v.subsections[subIndex].typeData as any).links =
                            (v.subsections[subIndex].typeData as any).links ||
                            [];
                          (v.subsections[subIndex].typeData as any).links = (
                            v.subsections[subIndex].typeData as any
                          ).links?.map((l: any) => {
                            if (l.id !== link.id) {
                              return l;
                            }
                            return {
                              ...link,
                              title: val,
                            };
                          });
                        }
                        return v;
                      });
                      return prev;
                    });
                  }}
                />
                <Input
                  name="text"
                  label="URL *"
                  className="w-full"
                  value={link.url}
                  onChange={(val) => {
                    setData((prev) => {
                      prev = prev.map((v) => {
                        const subIndex = v.subsections.findIndex(
                          (s) => s.id === subsection.id,
                        );
                        if (subIndex !== -1) {
                          v.subsections[subIndex].typeData =
                            v.subsections[subIndex].typeData || {};
                          (v.subsections[subIndex].typeData as any).links =
                            (v.subsections[subIndex].typeData as any).links ||
                            [];
                          (v.subsections[subIndex].typeData as any).links = (
                            v.subsections[subIndex].typeData as any
                          ).links?.map((l: any) => {
                            if (l.id !== link.id) {
                              return l;
                            }
                            return {
                              ...link,
                              url: val,
                            };
                          });
                        }
                        return v;
                      });
                      return prev;
                    });
                  }}
                />
              </div>
            </HomePageSortableItem>
          ))}
        </div>
      </SortableContext>
      <PlusCircleIcon
        className="w-10 h-10 text-primary-500 hover:text-primary-400 cursor-pointer"
        onClick={() => {
          setData((prev) => {
            prev = prev.map((v) => {
              const subIndex = v.subsections.findIndex(
                (s) => s.id === subsection.id,
              );
              if (subIndex !== -1) {
                v.subsections[subIndex].typeData =
                  v.subsections[subIndex].typeData || {};
                (v.subsections[subIndex].typeData as any).links =
                  (v.subsections[subIndex].typeData as any).links || [];
                (v.subsections[subIndex].typeData as any).links.push({
                  id: v4(),
                  title: '',
                  url: '',
                });
              }
              return v;
            });
            return prev;
          });
        }}
      />
    </div>
  );
};

export default HomePageLinks;
