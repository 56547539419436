import { useMemo } from 'react';
import { menuItems } from '../../data/menuItems';
import SidenavMenuItem from './SidenavMenuItem';

type Props = {
  user: any;
};

const SidenavMenu: React.FC<Props> = ({ user }) => {
  const currentPath = window.location.pathname;

  const menuItemsFiltered = useMemo(() => {
    if (
      [
        'clickdoc@yahoo.com',
        'drbrown@brown-chiro.com',
        'mbracciodc@gmail.com',
      ].includes(user?.email?.toLowerCase())
    ) {
      return menuItems.filter((item) => {
        if (
          item.path === 'research' ||
          item.path === 'clinical-skills' ||
          item.path === ''
        ) {
          return true;
        }
        return false;
      });
    }

    if (
      ![
        'kara@chiroup.com',
        'tim@chiroup.com',
        'brandon@chiroup.com',
        'jeremy@chiroup.com',
        'steve@chiroup.com',
        'todd@chiroup.com',
      ].includes(user?.email?.toLowerCase())
    ) {
      return menuItems.filter((item) => {
        if (item.path === 'flags') {
          return false;
        }
        return true;
      });
    }
    return menuItems;
  }, [user]);

  return (
    <div className="space-y-1">
      {menuItemsFiltered?.map((menuItem) => (
        <SidenavMenuItem
          key={menuItem.path}
          currentPath={currentPath}
          path={menuItem.path}
          icon={menuItem.icon}
          title={menuItem.title}
          menuItems={menuItem.menuItems}
          external={menuItem.external}
        />
      ))}
      {/* <div className="pt-8">
        <h3
          className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
          id="teams-headline"
        >
          Shortcuts
        </h3>
        <div
          className="mt-1 space-y-1"
          role="group"
          aria-labelledby="teams-headline"
        >
          <a
            href="https://chiroup.com/blog/"
            target="_blank"
            rel="noreferrer"
            className="group flex items-center px-3 py-2 text-base leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
          >
            <span
              className="w-2.5 h-2.5 mr-4 bg-yellow-500 rounded-full"
              aria-hidden="true"
            />
            <span className="truncate">ChiroUp Blog</span>
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default SidenavMenu;
