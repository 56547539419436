/**
 * [2023-09-08.1119 by Brian]
 *
 *                  --- AssociateReferenceCodes ---
 *
 *   A standardized way of associating billing codes or other reference codes
 *   with _something_. Where _something_ is a test, a supplement, etc.
 *
 *   All this does is manage the UI component and what is contained in the
 *   data structures. Support for the REST calls is left as an exercise for
 *   the reader ;-)
 *
 * Setup:
 *
 *   The CUP-specific hook useForm() must be used.
 *
 *   Designed to be _inside_ of a <form> element.
 *
 *   The 'value' and 'patchValue' from useForm() must be passed in.
 *
 *   The the key on 'value' _must_ be 'referenceCodes'.
 *
 * Usage:
 *
 *   <AssociateReferenceCodes
 *      value={value}
 *      patchValue={patchValue}
 *      className="col-span-4 pt-8"
 *      codeSet={[CodeSets.CPT, CodeSets.SERVICE]}
 *      endpoint="/codes"
 *      noCodesMessage="No codes are associated with this test."
 *   />
 *
 * Security:
 *
 *   This component will always show up. Originally, the idea was for it
 *   to detect when it should or should-not appear, but that was
 *   problematic for a component shared across multiple apps. So, if it
 *   should only appear conditionally, make sure you keep this out of
 *   the DOM with:
 *
 *     { hasAccess(FeatureFlags.ehr)
 *       && (<AssociateReferenceCodes ... />) }
 *
 *   ...or something similar.
 *
 *   You may also pass it a 'hasAccess' prop to control its visibility.
 *
 * Technical Debt:
 *
 *   Maybe a decent idea to not hard code 'referenceCodes' as the key.
 *
 *   The caller could provide a component-specific patchValue instead
 *   of the one from useForm() being passed around. This would fix the
 *   hard-coded 'referenceCodes' issue.
 *
 *   The caller could provide a clone method too. But at that point,
 *   this does not simplify matters much. Hmmm...
 *
 * Parameters:
 *   Details of each parameter follow. Optional ones have sane defaults
 *   (hopefully).
 *
 *   value:
 *      The 'value' from useForm() hook.
 *
 *   patchValue:
 *      The 'patchValue' function from useForm().
 *
 *   className:
 *     Optional. A CSS class name to apply to the outermost <div>.
 *
 *   addClassName:
 *     Optional. A CSS class name to apply to the outermost <div>
 *     _in addition_ to the default ones or the ones passed in
 *     className.
 *
 *   endpoint:
 *     Optional. The endpoint used for the REST call. It defaults
 *     to the CUP settings endpoint, but to be used with CUP Admin
 *     it needs to be overridden.
 *
 *  label:
 *     Optional. Whatever you want the introductory heading to be.
 *
 *  noneText:
 *     Optional. This is the initial text that appears _before_ the
 *     user starts typing into the autocomplete field.
 *
 *  codeSet:
 *     Optional. This is a single or an array of CodeSets.
 *
 *  noCodesMessage:
 *     Optional. This message appears when the length of
 *     'value.referenceCodes' is zero.
 *
 *  compact:
 *    Optional. Makes the font smaller and gets rid of some
 *    extra spacing.
 *
 *  topGutter:
 *    Optional. A React node to be placed above the component.
 *    Depending on what type of layout this component is going
 *    into, this might be useful. If you're in a grid, 'mt-x'
 *    seem to be ignored, for instance.
 *
 *  labelTooltip:
 *    Optional. A tooltip to be displayed next to the label.
 *
 *  hasAccess:
 *    Optional. Boolean. Can the user use this component?
 *
 *  local:
 *    Optional. Boolean. When true, only the local or clinic-
 *    specific codes are searched. Otherwise, the local AND
 *    the global list is searched.
 */
import { CodeSets, ReferenceCodeDisplay } from '@chiroup/core';
import React, { useMemo, useState } from 'react';
import { CodeAutocomplete } from './CodeAutocomplete';
import { TrivialTooltip } from './TrivialTooltip';
import classNames from 'classnames';
import { ArrowUpCircleIcon, TrashIcon } from '@heroicons/react/24/outline';

type Props = {
  value: Partial<any>;
  patchValue?: (val: Partial<any>) => void;
  setVisitForm?: React.Dispatch<React.SetStateAction<any>>;
  className?: string;
  addClassName?: string;
  endpoint?: string;
  label?: string;
  noneText?: string;
  codeSet?: CodeSets | CodeSets[];
  noCodesMessage?: string;
  compact?: boolean;
  topGutter?: React.ReactNode;
  labelTooltip?: string;
  associatedCodes?: ReferenceCodeDisplay[];
  complaintIndex?: number;
  conditionIndex?: number;
  singleCode?: boolean;
  clinicId?: number;
  nonConditionReferenceCodes?: boolean;
  rightOfLabel?: React.ReactNode;
  hideNoCodesMessage?: boolean;
  local?: boolean;
  hasAccess?: boolean;
  associatedCodesLabel?: string;
};

export const AssociateReferenceCodes: React.FC<Props> = ({
  value,
  patchValue,
  setVisitForm,
  className,
  addClassName,
  endpoint,
  label,
  noneText = 'Type to search for available codes...',
  codeSet = CodeSets.ICD10CM,
  noCodesMessage = 'No codes are associated with this item.',
  compact = false,
  topGutter,
  labelTooltip,
  associatedCodes,
  complaintIndex,
  conditionIndex,
  singleCode = false,
  clinicId,
  nonConditionReferenceCodes = false,
  rightOfLabel,
  hideNoCodesMessage,
  local = false,
  hasAccess = true,
  associatedCodesLabel = 'Associated Billing Codes:',
}) => {
  const [search, setSearch] = useState('');
  label =
    label ||
    (Array.isArray(codeSet)
      ? `Associated ${local ? 'Clinic ' : ''}Billing Codes`
      : `Associated ${local ? 'Clinic ' : ''}Diagnostic Codes`);
  const [onDeck, setOnDeck] = useState<{
    codeSet: CodeSets | undefined;
    code: string;
    desc: string;
    repeat: boolean;
  }>({
    codeSet: undefined,
    code: '',
    desc: '',
    repeat: false,
  });

  const filteredCodes =
    associatedCodes?.filter(
      (ac) =>
        !value?.referenceCodes?.some(
          (v: ReferenceCodeDisplay) => v.code === ac.code,
        ),
    ) || [];

  const klasses = (
    className
      ? className.split(' ')
      : [
          'bg-gray-100',
          'rounded-md',
          'border border-gray-300',
          'h-full',
          'flex flex-col justify-between',
        ]
  ).concat((addClassName || '').split(' '));

  const updateNonConditionReferenceCodes = (
    prev: any,
    complaintIndex: number,
    onDeck: any,
  ) => {
    return {
      ...prev,
      soapCustomEditWarning: {
        ...prev.soapCustomEditWarning,
        assessment: false,
      },
      encounterInfo: {
        ...prev.encounterInfo,
        complaints: prev.encounterInfo.complaints?.map(
          (complaint: any, i: number) => {
            if (i === complaintIndex) {
              const assessment = complaint.assessment || {};
              const nonConditions = assessment.nonConditions || {};
              const referenceCodes = nonConditions.referenceCodes || [];

              return {
                ...complaint,
                assessment: {
                  ...assessment,
                  nonConditions: {
                    ...nonConditions,
                    referenceCodes: [
                      ...referenceCodes,
                      {
                        codeSet: onDeck.codeSet,
                        code: onDeck.code,
                        description: onDeck.desc,
                      },
                    ],
                  },
                },
              };
            }
            return complaint;
          },
        ),
      },
    };
  };

  const updateReferenceCodes = (
    prev: any,
    complaintIndex: number,
    conditionIndex: number,
    onDeck: any,
  ) => {
    return {
      ...prev,
      soapCustomEditWarning: {
        ...prev.soapCustomEditWarning,
        assessment: false,
      },
      encounterInfo: {
        ...prev.encounterInfo,
        complaints: prev.encounterInfo.complaints.map(
          (complaint: any, i: number) => {
            if (i === complaintIndex) {
              return {
                ...complaint,
                assessment: {
                  ...complaint.assessment,
                  conditions: complaint.assessment.conditions.map(
                    (condition: any, j: number) => {
                      if (j === conditionIndex) {
                        return {
                          ...condition,
                          referenceCodes: [
                            ...(condition.referenceCodes || []),
                            {
                              codeSet: onDeck.codeSet,
                              code: onDeck.code,
                              description: onDeck.desc,
                            },
                          ],
                        };
                      }
                      return condition;
                    },
                  ),
                },
              };
            }
            return complaint;
          },
        ),
      },
    };
  };

  const handleClick = () => {
    const existingCodes = (value?.referenceCodes || []).reduce(
      (acc: any, curr: any) => ({ ...acc, [curr.code]: curr }),
      {},
    );
    const repeat = !!existingCodes[onDeck.code];
    if (!repeat) {
      if (patchValue) {
        patchValue({
          referenceCodes: [
            ...(value?.referenceCodes || []),
            {
              codeSet: onDeck.codeSet,
              code: onDeck.code,
              description: onDeck.desc,
            },
          ],
        });
      } else if (
        setVisitForm &&
        typeof complaintIndex === 'number' &&
        typeof conditionIndex === 'number' &&
        !nonConditionReferenceCodes
      ) {
        setVisitForm((prev: any) =>
          updateReferenceCodes(prev, complaintIndex, conditionIndex, onDeck),
        );
      } else if (
        setVisitForm &&
        typeof complaintIndex === 'number' &&
        nonConditionReferenceCodes
      ) {
        setVisitForm((prev: any) =>
          updateNonConditionReferenceCodes(prev, complaintIndex, onDeck),
        );
      }
      setOnDeck({
        codeSet: onDeck.codeSet,
        code: '',
        desc: '',
        repeat: false,
      });
      setSearch('');
    }
  };

  const updateReferenceCodesForDeletion = (
    prev: any,
    complaintIndex: number,
    conditionIndex: number,
    items: any[],
  ) => {
    return {
      ...prev,
      soapCustomEditWarning: {
        ...prev.soapCustomEditWarning,
        assessment: false,
      },
      encounterInfo: {
        ...prev.encounterInfo,
        complaints: prev.encounterInfo.complaints.map(
          (complaint: any, i: number) => {
            if (i === complaintIndex) {
              return {
                ...complaint,
                assessment: {
                  ...complaint.assessment,
                  conditions: complaint.assessment.conditions.map(
                    (condition: any, j: number) => {
                      if (j === conditionIndex) {
                        return {
                          ...condition,
                          referenceCodes: [...items],
                        };
                      }
                      return condition;
                    },
                  ),
                },
              };
            }
            return complaint;
          },
        ),
      },
    };
  };

  const updateNonConditionReferenceCodesForDeletion = (
    prev: any,
    complaintIndex: number,
    items: any[],
  ) => {
    return {
      ...prev,
      soapCustomEditWarning: {
        ...prev.soapCustomEditWarning,
        assessment: false,
      },
      encounterInfo: {
        ...prev.encounterInfo,
        complaints: prev.encounterInfo.complaints.map(
          (complaint: any, i: number) => {
            if (i === complaintIndex) {
              return {
                ...complaint,
                assessment: {
                  ...complaint.assessment,
                  nonConditions: {
                    ...complaint.assessment.nonConditions,
                    referenceCodes: [...items],
                  },
                },
              };
            }
            return complaint;
          },
        ),
      },
    };
  };

  const handleItemDeletion = (
    idx: number,
    value: any,
    complaintIndex: number | undefined,
    conditionIndex: number | undefined,
    patchValue: ((val: Partial<any>) => void) | null | undefined,
    setVisitForm: any | null | undefined,
  ) => {
    const items = JSON.parse(JSON.stringify(value?.referenceCodes || []));
    items.splice(idx, 1);
    if (patchValue) {
      patchValue({ referenceCodes: [...items] });
    } else if (setVisitForm && !nonConditionReferenceCodes) {
      if (
        typeof complaintIndex !== 'number' ||
        typeof conditionIndex !== 'number'
      ) {
        // If setVisitForm is being used, complaintIndex and conditionIndex must be numbers
        console.error(
          'Both complaintIndex and conditionIndex must be defined when using setVisitForm',
        );
        return;
      }
      setVisitForm((prev: any) =>
        updateReferenceCodesForDeletion(
          prev,
          complaintIndex,
          conditionIndex,
          items,
        ),
      );
    } else if (setVisitForm && nonConditionReferenceCodes) {
      if (typeof complaintIndex !== 'number') {
        // If setVisitForm is being used, complaintIndex must be a number
        console.error('complaintIndex must be defined when using setVisitForm');
        return;
      }
      setVisitForm((prev: any) =>
        updateNonConditionReferenceCodesForDeletion(
          prev,
          complaintIndex,
          items,
        ),
      );
    }
  };

  const showCodesSection =
    !!value?.referenceCodes?.length || !hideNoCodesMessage;

  const textSize = useMemo(() => (compact ? 'text-xs' : 'text-sm'), [compact]);

  if (!hasAccess) return null;

  return (
    <>
      {topGutter}
      <div className={klasses.join(' ')}>
        <div
          className={classNames(
            textSize,
            'flex text-gray-900 bg-white rounded-t-md p-2 justify-between items-center flex-row',
            showCodesSection ? 'border-b border-gray-300' : '',
          )}
        >
          <div>
            {label || ''}
            {labelTooltip && (
              <TrivialTooltip
                text={labelTooltip}
                tipClassName="w-96"
                iconClassName="h-3 w-3 text-white inline-block ml-2 align-text-top"
              />
            )}
          </div>
          {rightOfLabel}
        </div>
        {showCodesSection && (
          <div className={classNames('flex flex-col gap-2 p-2')}>
            {(!value.referenceCodes || value.referenceCodes.length === 0) &&
              !hideNoCodesMessage && (
                <cite
                  className={classNames(
                    textSize,
                    'col-span-10 p-2 ml-2 text-gray-400',
                  )}
                >
                  {noCodesMessage}
                </cite>
              )}
            {value.referenceCodes?.map(
              (o: ReferenceCodeDisplay, idx: number) => {
                return (
                  <div
                    key={`${o.code}`}
                    className={classNames(
                      'flex flex-row gap-2 items-center',
                      textSize,
                    )}
                  >
                    <div className="flex-grow p-2 border-2 rounded-md bg-gray-50 border-grey-300">
                      {`${o.code || ''} - ${o.description || ''}`}
                    </div>
                    <div
                      className="hover:text-primary-500 pr-1 active:text-primary-600 flex justify-center flex-shrink pt-2 text-gray-500 cursor-pointer items-top"
                      style={{ top: 0, right: 0 }}
                      title="Remove this code from the list."
                      onClick={() =>
                        handleItemDeletion(
                          idx,
                          value,
                          complaintIndex,
                          conditionIndex,
                          patchValue,
                          setVisitForm,
                        )
                      }
                    >
                      <TrashIcon className="w-5 h-5" />
                    </div>
                  </div>
                );
              },
            )}
          </div>
        )}
        <div className="flex flex-row gap-2 p-2 items-center border-t border-gray-300">
          {!singleCode && (
            <CodeAutocomplete
              endpoint={endpoint}
              search={search}
              setSearch={setSearch}
              value={onDeck.code as string}
              weighted={true}
              className={`flex-grow max-w-[calc(100%-34px)]`}
              noneText={noneText}
              onChange={(val: string) => {
                const existingCodes = (value?.referenceCodes || []).reduce(
                    (acc: any, curr: any) => {
                      return { ...acc, [curr.code]: curr };
                    },
                    {},
                  ),
                  repeat = !!existingCodes[val];
                setOnDeck((p) => ({ ...p, code: val, repeat }));
              }}
              codeSet={codeSet}
              onChangeDescription={(val: any) => {
                const desc = val?.data?.description || '',
                  codeSet = val?.data?.codeSet || '';
                setOnDeck((p) => ({ ...p, desc, codeSet }));
              }}
              descriptionAsString={false}
              clinicId={clinicId}
              local={local}
            />
          )}
          {onDeck.code && !singleCode ? (
            <div
              className={
                (onDeck.repeat ? 'text-red-500' : 'text-primary-500') +
                ' flex justify-center cursor-pointer col-span-1'
              }
              title={
                onDeck.repeat
                  ? 'Code already associated with condition.'
                  : 'Associate this code with the condition.'
              }
              onClick={handleClick}
            >
              <ArrowUpCircleIcon className="w-6 h-6" />
            </div>
          ) : !singleCode ? (
            <div
              className={
                'w-6 h-6 flex justify-center flex-shrink text-gray-300'
              }
              title="Select a code from the left to activiate this button."
            >
              <ArrowUpCircleIcon className="w-6 h-6" />
            </div>
          ) : null}
        </div>
        {!!associatedCodes?.length && !!filteredCodes?.length ? (
          <div className={classNames('flex flex-col p-2 gap-1', textSize)}>
            <p className="text-sm">{associatedCodesLabel}</p>
            <div className={classNames('flex flex-col gap-1')}>
              {filteredCodes?.map((code, i) => {
                return (
                  <div key={code.code} className="flex flex-row gap-1">
                    <div
                      className={classNames(
                        textSize,
                        'flex flex-grow overflow-ellipsis bg-gray-50 border text-gray-600 text-sm px-2 py-2 rounded-md border-gray-300',
                      )}
                      // text={`${code.code}: ${code.description}`}
                    >{`${code.code}: ${code.description}`}</div>
                    <div
                      className="px-1 cursor-pointer text-primary-500 hover:text-primary-500 active:text-primary-600"
                      key={`${code.code}-2`}
                      title="Add this billing code to the order."
                      onClick={() =>
                        patchValue
                          ? patchValue({
                              referenceCodes: [
                                ...(value?.referenceCodes || []),
                                code,
                              ],
                            })
                          : setVisitForm &&
                              typeof complaintIndex == 'number' &&
                              typeof conditionIndex == 'number'
                            ? setVisitForm((prev: any) =>
                                updateReferenceCodes(
                                  prev,
                                  complaintIndex,
                                  conditionIndex,
                                  {
                                    desc: code.description,
                                    codeSet: code.codeSet,
                                    code: code.code,
                                  },
                                ),
                              )
                            : null
                      }
                    >
                      <ArrowUpCircleIcon className="w-6 h-6" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
