import { OpenClosedStates } from '@chiroup/components';
import React, { lazy, Suspense, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastProvider } from '../../contexts/toast.context';
import Loading from '../common/Loading';
import NavBar from './NavBar';
import Sidenav from './Sidenav';

const Toasts = lazy(() => import('../common/toasts/Toasts'));

const hideSidenav: string[] = [];

type Props = {
  children: any;
  user: any;
};

const Container: React.FC<Props> = ({ children, user }) => {
  const { pathname } = useLocation();
  // const [showNotifications, setShowNotifications] = useState(false);
  const [sidenavState, setSidenavState] = useState<OpenClosedStates>(
    OpenClosedStates.Closed,
  );

  const toggleSidenav = () => {
    setSidenavState((v) =>
      v === OpenClosedStates.Open
        ? OpenClosedStates.Closed
        : OpenClosedStates.Open,
    );
  };

  // const toggleNotifications = () => {
  //   const previous = showNotifications;
  //   setShowNotifications(!previous);
  //   setItem('notificationsOpen', !previous);
  //   if (!previous) {
  //     resetNewNotifications();
  //   }
  // };

  return (
    <ToastProvider>
      <Suspense fallback={<Loading />}>
        <div className="flex flex-col h-screen bg-gray-50 dark:bg-darkGray-800 dark:text-darkGray-50">
          <NavBar
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavState === OpenClosedStates.Open}
            // setShowNotifications={setShowNotifications}
            // newNotifications={newNotifications}
            // resetNewNotifications={resetNewNotifications}
            // toggleNotifications={toggleNotifications}
          />
          <div className="h-screen print:overflow-visible flex overflow-hidden w-full mx-auto lg:flex">
            <Sidenav
              sidenavState={sidenavState}
              setSidenavState={setSidenavState}
              hide={hideSidenav.includes(pathname)}
              user={user}
            />
            <div className="flex flex-col w-0 flex-1">
              <div
                className="flex-1 flex-grow min-w-0 lg:flex min-h-full overflow-auto print:overflow-visible"
                id="container"
              >
                <div className="lg:min-w-0 lg:flex-1">{children}</div>
              </div>
            </div>
            {/* <Notifications
              hidden={!showNotifications}
              data={data}
              isFetching={isFetching}
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              resetNewNotifications={resetNewNotifications}
              toggleNotifications={toggleNotifications}
            /> */}
          </div>
        </div>
        <Toasts />
      </Suspense>
    </ToastProvider>
  );
};

export default Container;
