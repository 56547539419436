import { OpenClosedStates } from '@chiroup/components';
import React, { useState } from 'react';
import Button, { ButtonColors } from '../../common/Button';
import MarketingCreateModal from './MarketingCreateModal';

type Props = {
  onCreate: () => void;
};

const MarketingCreate: React.FC<Props> = ({ onCreate }) => {
  const [state, setState] = useState<OpenClosedStates>(OpenClosedStates.Closed);

  const open = () => {
    setState(OpenClosedStates.Open);
  };

  const close = () => {
    setState(OpenClosedStates.Closed);
  };

  return (
    <>
      <MarketingCreateModal
        state={state}
        close={close}
        onCreate={onCreate}
        // lastName={lastName}
      />
      <Button
        text="Add"
        color={ButtonColors.plainWithBorder}
        className="cursor-pointer"
        onClick={open}
        icon={
          <path
            fillRule="evenodd"
            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
            clipRule="evenodd"
          />
        }
      />
    </>
  );
};

export default MarketingCreate;
