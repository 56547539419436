import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loading from '../common/Loading';

const Advice = lazy(() => import('./advice/Advice'));
const Conditions = lazy(() => import('./conditions/Conditions'));
const Evaluations = lazy(() => import('./evaluations/Evaluations'));
const Exercises = lazy(() => import('./exercises/Exercises'));
const Treatments = lazy(() => import('./treatments/Treatments'));
const Supplements = lazy(() => import('./supplements/Supplements'));
const Modalities = lazy(() => import('./modalities/Modalities'));

export type QueryKeyNames =
  | 'advice'
  | 'conditions'
  | 'evaluations'
  | 'exercises'
  | 'treatments'
  | 'common-treatments'
  | 'supplements'
  | 'modality';

export const endpoints = {
  advice: 'advice',
  conditions: 'condition',
  evaluations: 'evaluation',
  exercises: 'exercise',
  treatments: 'treatment',
  'common-treatments': 'treatment',
  supplements: 'supplement',
  modality: 'modality',
};

const ClinicalSkills = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path={`/advice/*`} element={<Advice />} />
        <Route path={`/supplements/*`} element={<Supplements />} />
        <Route path={`/conditions/*`} element={<Conditions />} />
        <Route path={`/evaluations/*`} element={<Evaluations />} />
        <Route path={`/modality/*`} element={<Modalities />} />
        <Route
          path={`/treatments/*`}
          element={<Treatments type="Clinical" />}
        />
        <Route path={`/exercises/*`} element={<Exercises />} />
        <Route
          path={`/common-treatments/*`}
          element={<Treatments type="Common" />}
        />
        <Route path="*" element={<span>no match</span>} />
      </Routes>
    </Suspense>
  );
};

export default ClinicalSkills;
