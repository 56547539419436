import React, { useMemo } from 'react';
import { ReferenceCodeDisplay } from '@chiroup/core';
import { EditorView } from './form/EditorView';

type Props = {
  label?: string;
  value?: string | ReferenceCodeDisplay[] | { type: 'doc'; content: any[] };
  star?: boolean;
  show?: boolean;
  className?: string;
  url?: boolean;
  optional?: boolean;
  useDivs?: boolean;
  removeSpacingAndColor?: boolean;
};

function removeSomeSpacingAndColor(inputString: string) {
  let newString = inputString.replace(/color\s*:\s*#[\da-fA-F]+;?/g, '');
  let removedFirstOccurrence = false;
  let removedFirstclosing = false;
  newString = newString.replace(
    /<p>\s*<span\s*style="margin-top: 8px;\s*margin-bottom: 8px;">\s*/g,
    () => {
      if (!removedFirstOccurrence) {
        removedFirstOccurrence = true;
        return '<p></p>';
      } else {
        return '';
      }
    },
  );

  newString = newString.replace(/<\/span><\/p>/g, () => {
    if (!removedFirstclosing) {
      removedFirstclosing = false;
      return '<p></p>';
    } else {
      return '';
    }
  });
  newString = newString.replace(
    /<p\s*>\s*<strong[^>]*>\s*Yellow flags\s*<\/strong>\s*<p[^>]*>/g,
    '<span><strong>Yellow flags</strong></span>',
  );

  return newString;
}

const ValueLayout: React.FC<{
  value: string | ReferenceCodeDisplay[] | { type: 'doc'; content: any[] };
  showThisValue: string | ReferenceCodeDisplay[];
  url: boolean;
  valueIsReferenceCode: (v: any) => boolean;
  removeSpacingAndColor?: boolean;
}> = ({
  value = '',
  url = false,
  valueIsReferenceCode,
  showThisValue,
  removeSpacingAndColor = false,
}) => {
  return url && typeof value === 'string' ? (
    <div className={'text-cyan-800 font-medium hover:underline'}>
      <a href={value as string} target="_blank" rel="noreferrer">
        {value}
      </a>
    </div>
  ) : valueIsReferenceCode(value) ? (
    <>
      {(value as ReferenceCodeDisplay[]).map((v: ReferenceCodeDisplay, idx) => (
        <div
          key={`${v.codeSet}.${v.code}`}
          className={
            (idx % 2 ? 'bg-gray-100 ' : 'bg-primary-100 ') + 'block flex-none'
          }
        >
          <div className="w-32 inline-block p-2">{v.code}</div>
          <div className="w-rest inline-block p-2 rounded-sm">
            {v.description}
          </div>
        </div>
      ))}
    </>
  ) : typeof showThisValue === 'object' ? (
    <EditorView value={showThisValue as any} />
  ) : (
    <div
      className="wysiwyg-html"
      dangerouslySetInnerHTML={{
        __html: removeSpacingAndColor
          ? (removeSomeSpacingAndColor(showThisValue) as string)
          : (showThisValue as string),
      }}
    />
  );
};

export const InfoItemHtml: React.FC<Props> = ({
  label = '',
  value = '',
  star = false,
  show = true,
  className = 'col-span-2 sm:col-span-1',
  url = false,
  optional = false,
  useDivs = false,
  removeSpacingAndColor = false,
}) => {
  const showThisValue = useMemo(
    () =>
      !Array.isArray(value)
        ? JSON.parse(JSON.stringify(value))
        : (value as ReferenceCodeDisplay[])
            .map((v: ReferenceCodeDisplay) => `${v.code} -- ${v.description}`)
            .join(', '),
    [value],
  );

  if (!show || !value) {
    return null;
  }

  const valueIsReferenceCode = (value: any) => {
    if (Array.isArray(value)) {
      return value.every((v) => v.code && v.description && v.codeSet);
    }
    return false;
  };

  if (optional && value && Array.isArray(value) && value.length === 0) {
    return null;
  }

  if (useDivs) {
    return (
      <div className={className}>
        <div className="text-sm font-medium leading-5 text-gray-500 dark:text-darkGray-400">
          {label}
        </div>
        <div
          className={
            (valueIsReferenceCode(value) ? '' : 'flex ') +
            'mt-1 text-sm leading-5 text-gray-900 dark:text-darkGray-100'
          }
        >
          {ValueLayout({
            value,
            showThisValue,
            url,
            valueIsReferenceCode,
            removeSpacingAndColor,
          })}
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      <dt className="text-sm font-medium leading-5 text-gray-500 dark:text-darkGray-400">
        {label}
      </dt>
      <dd
        className={
          (valueIsReferenceCode(value) ? '' : 'flex ') +
          'mt-1 text-sm leading-5 text-gray-900 dark:text-darkGray-100'
        }
      >
        {ValueLayout({
          value,
          showThisValue,
          url,
          valueIsReferenceCode,
          removeSpacingAndColor,
        })}
      </dd>
    </div>
  );
};
