import {
  Checkbox,
  Input,
  InputMasked,
  Phone,
  Select,
  Toggle,
  ZipCodeInput,
} from '@chiroup/components';
import {
  CANADIAN_PROVINCES,
  ClinicLocation,
  FormFieldErrors,
  GLOBAL_PLACE_OF_SERVICE_OPTIONS,
  STATES,
  ValueOf,
} from '@chiroup/core';
import { COUNTRIES } from '../../../../constants';
import React from 'react';
import Uploader from '../../../common/uploader/Uploader';
import SectionContainer from '../../../layout/SectionContainer';
import SectionHeader from '../../../layout/SectionHeader';

type Props = {
  value: Partial<ClinicLocation>;
  fieldErrors?: FormFieldErrors;
  onChange: (
    key: keyof Partial<ClinicLocation>,
  ) => (val: ValueOf<ClinicLocation>) => void;
};

const ClinicLocationDetailForm: React.FC<Props> = ({
  value,
  fieldErrors,
  onChange,
}) => {
  return (
    <>
      <Input
        name="name"
        className="col-span-4"
        label={value.primary ? 'Name *' : 'Name'}
        value={value.name}
        onChange={onChange('name')}
        errors={fieldErrors?.name}
        hint={
          value.primary ? '' : 'Inherited from primary location if not provided'
        }
      />
      <Input
        name="address"
        className="col-span-2"
        label="Address"
        value={value.address1}
        onChange={onChange('address1')}
        errors={fieldErrors?.address1}
      />
      <Input
        name="address2"
        className="col-span-2"
        label="Address cont."
        value={value.address2}
        onChange={onChange('address2')}
        errors={fieldErrors?.address2}
      />
      <Input
        name="city"
        className="col-span-2"
        label="City"
        value={value.city}
        onChange={onChange('city')}
        errors={fieldErrors?.city}
      />
      {['USA', 'CAN'].includes(value?.country || '') ? (
        <Select
          name="state"
          className="col-span-3"
          label={`${value?.country === 'CAN' ? 'Province *' : 'State *'}`}
          value={value.state}
          onChange={onChange('state')}
          errors={fieldErrors?.state}
          options={value?.country === 'CAN' ? CANADIAN_PROVINCES : STATES}
          limit={1}
          autocomplete
        />
      ) : (
        <Input
          name="state"
          className="col-span-3"
          label="State/Province"
          value={value.state}
          onChange={onChange('state')}
          errors={fieldErrors?.state}
        />
      )}
      <ZipCodeInput
        name="zip"
        className="col-span-2"
        label="Zip/Postal Code"
        value={value.zip}
        onChange={onChange('zip')}
        errors={fieldErrors?.zip}
        country={value.country ?? ''}
      />
      <Select
        name="country"
        className="col-span-2"
        label="Country *"
        options={COUNTRIES}
        value={value.country ?? ''}
        onChange={onChange('country')}
        errors={fieldErrors?.country}
        limit={1}
      />
      <Checkbox
        name="addressSameAsLocation"
        value={value.billingAddressSameAsLocation}
        onChange={onChange('billingAddressSameAsLocation')}
        label="Use my location address for billing"
        disabled={value.billingAddressSameAsPrimary}
        className={value.primary ? 'col-span-4' : 'col-span-2'}
      />
      {!value.primary && (
        <Checkbox
          name="addressSameAsPrimary"
          value={value.billingAddressSameAsPrimary}
          onChange={onChange('billingAddressSameAsPrimary')}
          label="Use the primary address for billing"
          disabled={value.billingAddressSameAsLocation}
          className="col-span-2"
        />
      )}
      {!value.billingAddressSameAsLocation &&
        !value.billingAddressSameAsPrimary && (
          <div className="col-span-4 border rounded-md">
            <SectionContainer>
              <SectionHeader
                title={
                  <p className="text-base font-light text-gray-600">
                    Billing Address
                  </p>
                }
              />
              <div className="p-4 space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
                <Input
                  name="billingAddress"
                  className="col-span-2"
                  label="Address"
                  value={value.billingAddress1}
                  onChange={onChange('billingAddress1')}
                  errors={fieldErrors?.billingAddress1}
                />
                <Input
                  name="billingAddress2"
                  className="col-span-2"
                  label="Address cont."
                  value={value.billingAddress2}
                  onChange={onChange('billingAddress2')}
                  errors={fieldErrors?.billingAddress2}
                />
                <Input
                  name="billingCity"
                  className="col-span-2"
                  label="City"
                  value={value.billingCity}
                  onChange={onChange('billingCity')}
                  errors={fieldErrors?.billingCity}
                />
                <Select
                  name="billingState"
                  className="col-span-2"
                  label={`${
                    value?.billingCountry === 'CAN' ? 'Province *' : 'State *'
                  }`}
                  value={value.billingState}
                  onChange={onChange('billingState')}
                  errors={fieldErrors?.billingState}
                  options={
                    value?.country === 'CAN' ? CANADIAN_PROVINCES : STATES
                  }
                  limit={1}
                  autocomplete
                />
                <ZipCodeInput
                  name="billingZip"
                  className="col-span-2"
                  label="Zip/Postal Code"
                  value={value.billingZip}
                  onChange={onChange('billingZip')}
                  errors={fieldErrors?.billingZip}
                  country={value.billingCountry ?? ''}
                />
                <Select
                  name="billingCountry"
                  className="col-span-2"
                  label="Country"
                  options={COUNTRIES}
                  value={value.billingCountry ?? ''}
                  onChange={onChange('billingCountry')}
                  errors={fieldErrors?.billingCountry}
                  limit={1}
                  autocomplete
                />
              </div>
            </SectionContainer>
          </div>
        )}
      {/*TODO: Add Timezone when scheduling goes live*/}
      {/* <Select
        name="timezone"
        className="col-span-2"
        label="Time zone *"
        options={TIMEZONES}
        value={value.timezone}
        onChange={onChange('timezone')}
        errors={fieldErrors?.timezone}
        limit={1}
        autocomplete
      /> */}
      <Input
        name="npiNumber"
        className="col-span-2"
        label="NPI number"
        value={value.npiNumber}
        onChange={onChange('npiNumber')}
        errors={fieldErrors?.npiNumber}
      />
      <InputMasked
        name="serviceTaxRate"
        label="Service tax rate"
        placeholder="#.###"
        numericOptions={{
          allowNegative: false,
          decimalScale: 3,
        }}
        value={value.serviceTaxRate}
        onChange={onChange('serviceTaxRate')}
        errors={fieldErrors?.serviceTaxRate}
        className="col-span-2"
      />
      <InputMasked
        name="productTaxRate"
        label="Product tax rate"
        placeholder="#.###"
        numericOptions={{
          allowNegative: false,
          decimalScale: 3,
        }}
        value={value.productTaxRate}
        onChange={onChange('productTaxRate')}
        errors={fieldErrors?.productTaxRate}
        className="col-span-2"
      />
      <Select
        name="placeOfService"
        className="col-span-4"
        label="Place of service"
        options={GLOBAL_PLACE_OF_SERVICE_OPTIONS}
        value={value.placeOfService}
        onChange={onChange('placeOfService')}
        errors={fieldErrors?.placeOfService}
        limit={1}
        selectedField="value"
      />
      <Input
        name="path"
        className="col-span-2"
        label="Vanity URL suffix"
        value={value.path}
        onChange={onChange('path')}
        errors={fieldErrors?.path}
        hint="This will be used to create a vanity URL for your clinic."
      />
      <Phone
        name="phone"
        className="col-span-2"
        label="Phone"
        value={value.phone}
        onChange={onChange('phone')}
        errors={fieldErrors?.phone}
      />
      <Phone
        name="fax"
        className="col-span-2"
        label="Fax"
        value={value.fax}
        onChange={onChange('fax')}
        errors={fieldErrors?.fax}
      />
      <Input
        name="email"
        className="col-span-2"
        label="Email"
        value={value.email}
        onChange={onChange('email')}
        errors={fieldErrors?.email}
      />
      <Input
        name="site"
        className="col-span-2"
        label="Site"
        value={value.site}
        onChange={onChange('site')}
        errors={fieldErrors?.site}
      />
      <Input
        name="facebook"
        className="col-span-2"
        label="Facebook"
        value={value.facebook}
        onChange={onChange('facebook')}
        errors={fieldErrors?.facebook}
      />
      <Input
        name="twitter"
        className="col-span-2"
        label="Twitter"
        value={value.twitter}
        onChange={onChange('twitter')}
        errors={fieldErrors?.twitter}
      />
      <Input
        name="youtube"
        className="col-span-2"
        label="Youtube"
        value={value.youtube}
        onChange={onChange('youtube')}
        errors={fieldErrors?.youtube}
      />
      <Input
        name="reviewURL"
        className="col-span-4"
        label="Review URL"
        value={value.reviewURL}
        onChange={onChange('reviewURL')}
        errors={fieldErrors?.reviewURL}
      />
      <Input
        name="welcomePage"
        className="col-span-4"
        label="Welcome page"
        value={value.welcomePage}
        onChange={onChange('welcomePage')}
        errors={fieldErrors?.welcomePage}
      />
      <Uploader
        name="img"
        label="Logo"
        onChange={onChange('logo')}
        className="col-span-3"
        value={value.logo}
        type="image"
        uploadCategory="logos"
        uploadType="image"
        fileTypes={['png', 'jpg', 'jpeg', 'gif']}
        fileSizeLimit={10}
        resize
      />
      <Toggle
        label="Primary location"
        className="col-span-4"
        onChange={onChange('primary')}
        value={!!value.primary}
      />
      <Toggle
        label="Active"
        className="col-span-4"
        onChange={onChange('active')}
        value={!!value.active}
      />
    </>
  );
};

export default ClinicLocationDetailForm;
