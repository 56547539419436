import React from 'react';

type Props = {
  title: string;
};

const DashboardDivider = ({ title }: Props) => {
  return (
    <div className="relative mt-4">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300 mr-8" />
      </div>
      <div className="relative flex justify-start">
        <span className="pr-2 bg-gray-50 text-sm text-gray-500 p-6">
          {title}
        </span>
      </div>
    </div>
  );
};

export default DashboardDivider;
