import React from 'react';

type Props = {
  active?: boolean;
  children: React.ReactNode;
};

const SidenavIcon: React.FC<Props> = ({ children, active }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={[
        'mr-3 h-6 w-6 transition ease-in-out duration-150',
        active
          ? 'text-primary-600 dark:text-primary-400'
          : 'text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600',
      ].join(' ')}
    >
      {children}
    </svg>
  );
};

export default SidenavIcon;
