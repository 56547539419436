import { Test } from '@chiroup/core';
import { useForm } from '@chiroup/hooks';
import { Route, Routes } from 'react-router-dom';
// import useTags from '../../../hooks/useTags';
import TestList from './TestList';
import TestDetailContainer from './detail/TestDetailContainer';

const defaultValues = {
  name: '',
  descr: '',
};

const Tests = () => {
  // const { data: tags } = useTags();
  const { value, onChange } = useForm<Test>(defaultValues, {});

  return (
    <Routes>
      <Route
        path="/"
        element={
          <TestList
            // tags={tags}
            value={value}
            onChange={onChange}
          />
        }
      />
      <Route path={`/:itemId/*`} element={<TestDetailContainer />} />
      <Route path="*" element={<span>no match</span>} />
    </Routes>
  );
};

export default Tests;
