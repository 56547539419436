import { ChiroUpAPI } from '@chiroup/client-core';
import {
  blankFilters,
  translateIncomingFilters,
  translateOutgoingFilters,
} from './helpers';

const researchService = () => {
  const list = async (params: {
    filters?: any;
    limit?: number;
    skip?: number;
  }) => {
    const jsonSearch = JSON.stringify(params.filters);
    params.filters = jsonSearch;
    const res: any = await ChiroUpAPI.get('api', `/research`, {
      queryParams: params,
    });

    const articles = res.data?.map((article: any) => {
      article = translateIncomingFilters(
        {
          unclassified: article.unclassified,
          newsFeed: article.newsFeed,
          faceBook: article.faceBook,
          protocol: article.protocol,
          CSM: article.CSM,
          blog: article.blog,
          mdNewsletter: article.mdNewsletter,
          legalNewsletter: article.legalNewsletter,
        },
        {
          ...article,
          ...blankFilters,
        },
      );
      return article;
    });
    const returnRes = { data: articles, skip: res.skip };
    return returnRes;
  };

  const findOne = async (ID?: number): Promise<any> => {
    const res = await ChiroUpAPI.get('api', `/research/${ID}`, {});
    let article = res[0][0];
    article = translateIncomingFilters(
      {
        unclassified: article.unclassified,
        newsFeed: article.newsFeed,
        faceBook: article.faceBook,
        protocol: article.protocol,
        CSM: article.CSM,
        blog: article.blog,
        mdNewsletter: article.mdNewsletter,
        legalNewsletter: article.legalNewsletter,
      },
      {
        ...article,
        ...blankFilters,
      },
    );
    return article || {};
  };

  const update = async (research: Partial<any>) => {
    const { ID, ...restOfResearch } = research;
    const body = translateOutgoingFilters({
      ...restOfResearch,
      CSM: 0,
      newsFeed: 0,
      faceBook: 0,
      protocol: 0,
      blog: 0,
      legalNewsletter: 0,
      mdNewsletter: 0,
    });
    const res = await ChiroUpAPI.put('api', `/research/${ID}`, {
      body,
    });
    return res || {};
  };

  const del = (id: number) => {
    return ChiroUpAPI.del('api', `/research/${id}`, {});
  };

  const create = async (val: Partial<any>) => {
    const body = translateOutgoingFilters({
      ...val,
      CSM: 0,
      newsFeed: 0,
      nfPriority: 0,
      faceBook: 0,
      protocol: 0,
      blog: 0,
      legalNewsletter: 0,
      mdNewsletter: 0,
    });
    const res = await ChiroUpAPI.post('api', `/research`, {
      body,
    });
    return res || {};
  };

  return { list, findOne, update, del, create };
};

export default researchService();
