import { OpenClosedStates } from '@chiroup/components';
import { TrashIcon } from '@heroicons/react/24/solid';
import React from 'react';
import Button, { ButtonColors } from '../common/Button';
import Modal from '../common/Modal';

type Props = {
  state: OpenClosedStates;
  title?: string;
  description?: string;
  confirmText?: string;
  confirm: () => void;
  close: () => void;
  loading?: boolean;
  icon?: React.ReactNode;
};

const ConfirmModal: React.FC<Props> = ({
  title = 'Are you sure?',
  description,
  confirmText = 'Yes',
  confirm,
  close,
  state,
  loading = false,
  icon = <TrashIcon className="h-6 w-6 text-gray-600 dark:text-darkGray-400" />,
}) => {
  return (
    <Modal isOpen={state === OpenClosedStates.Open} close={close}>
      <div>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-darkGray-500">
          {icon}
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3
            className="text-lg leading-6 font-medium text-gray-900 dark:text-darkGray-100"
            id="modal-headline"
          >
            {title}
          </h3>
          {description && (
            <div className="mt-2">
              <p className="text-sm leading-5 text-gray-500 dark:text-darkGray-400">
                {description}
              </p>
            </div>
          )}
        </div>

        <div className="mt-6 grid grid-cols-2 gap-3 grid-flow-row-dense">
          <Button
            text="Cancel"
            onClick={close}
            fullWidth
            color={ButtonColors.plain}
            className="border border-gray-300 dark:border-darkGray-500"
          />
          <Button
            text={confirmText}
            onClick={confirm}
            fullWidth
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
