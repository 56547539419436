import { useForm } from '@chiroup/hooks';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import useMarketingCampaigns from '../../../hooks/useMarketingCampaigns';
import SearchInput from '../../common/fields/inputs/SearchInput';
import Filters from '../../common/Filters';
import List from '../../common/list/List';
import ListItem from '../../common/list/ListItem';
import Header from '../../layout/Header';
import MarketingCreate from './MarketingCreate';

const MarketingList = () => {
  const {
    data,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
    onSearch,
  } = useMarketingCampaigns();
  const { value, onChange } = useForm<{ search: string }>(
    {
      search: '',
    },
    {},
  );

  useEffect(() => {
    onSearch(value?.search || '');
  }, [value, onSearch]);

  const onCreate = () => {
    refetch();
  };

  return (
    <>
      <Header
        title="Marketing campaigns"
        rightSide={<MarketingCreate onCreate={onCreate} />}
      >
        <Filters
          main={
            <SearchInput onChange={onChange('search')} value={value.search} />
          }
        />
      </Header>
      <List
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
      >
        {data?.pages?.map((page, pageNumber) => (
          <React.Fragment key={`marketing-page-${pageNumber}`}>
            {page?.data?.map((row, i) => (
              <Link
                to={`/practice-resources/marketing-campaigns/${row.ID}`}
                key={row.ID}
              >
                <ListItem index={i}>
                  <div className="min-w-0 w-full">
                    <div className="relative group">
                      <div className="flex flex-col gap-2 w-full">
                        <div className="flex items-center space-x-3">
                          <h2 className="text-sm font-medium leading-4 w-full flex flex-row justify-between">
                            <span className="absolute inset-0" />
                            {row.title}
                            <div className="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
                              <p className="flex items-start space-x-4">
                                <span className="relative text-sm leading-4 text-gray-500 hover:text-gray-900 font-medium">
                                  {row.type}
                                </span>
                              </p>
                            </div>
                          </h2>
                        </div>
                        <div className="text-sm leading-4 text-gray-500 group-hover:text-gray-900 font-medium w-full truncate overflow-hidden">
                          {row.descr}
                        </div>
                      </div>
                    </div>
                  </div>
                </ListItem>
              </Link>
            ))}
          </React.Fragment>
        ))}
      </List>
    </>
  );
};

export default MarketingList;
