export const addScript = (src: string) => {
  return new Promise(function (resolve, reject) {
    const script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', function () {
      resolve(true);
    });
    script.addEventListener('error', function (e) {
      reject(e);
    });
    document.body.appendChild(script);
  });
};
