import { useDebounce } from '@chiroup/hooks';
import { useState } from 'react';
import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import disabilitiesService from '../services/disabilities.service';

const getQuery = () => {
  return async (context: QueryFunctionContext) => {
    const searchTerm = context.queryKey[1] as any;
    const filters: any = searchTerm || {};
    return disabilitiesService.list({ skip: context.pageParam, filters });
  };
};

const useDisabilities = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useDebounce(searchQuery);
  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery<{ data: any[]; skip: number }>(
    ['disabilities', debouncedSearch],
    getQuery(),
    {
      getNextPageParam: (lastGroup) => lastGroup?.skip || undefined,
      refetchOnWindowFocus: false,
    },
  );

  const onSearch = (params: any) => {
    setSearchQuery(params);
  };

  return {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    onSearch,
  };
};

export default useDisabilities;
