export const LIST_LIMIT = 30;

export type Region =
  | 'C'
  | 'S'
  | 'E'
  | 'H/W'
  | 'T'
  | 'L'
  | 'H'
  | 'K'
  | 'F/A'
  | 'O'
  | 'F/B'
  | 'HEAD';

export const DEGREES = [
  { value: '', text: '' },
  { value: 'ARNP', text: 'ARNP' },
  { value: 'APRN', text: 'APRN' },
  { value: 'NP', text: 'NP' },
  { value: 'DC', text: 'DC' },
  { value: 'DDS', text: 'DDS' },
  { value: 'DMD', text: 'DMD' },
  { value: 'DNP', text: 'DNP' },
  { value: 'DO', text: 'DO' },
  { value: 'DPM', text: 'DPM' },
  { value: 'MD', text: 'MD' },
  { value: 'ND', text: 'ND' },
  { value: 'PA', text: 'PA' },
  { value: 'PT', text: 'PT' },
  { value: 'Other', text: 'Other' },
];

export const MEDICAL_SPECIALTIES = [
  { value: 'Family/Internist', text: 'Family/Internist' },
  { value: 'Neurology', text: 'Neurology' },
  { value: 'Neurosurgery', text: 'Neurosurgery' },
  { value: 'Ob/Gyn', text: 'Ob/Gyn' },
  { value: 'Orthopedic Surgery', text: 'Orthopedic Surgery' },
  { value: 'Pain Management', text: 'Pain Management' },
  { value: 'Pediatrics', text: 'Pediatrics' },
  { value: 'Physiatry', text: 'Physiatry' },
  { value: 'Sports Medicine', text: 'Sports Medicine' },
  { value: 'Rheumatology', text: 'Rheumatology' },
  { value: 'Other', text: 'Other' },
];

export const REGIONS: { value: Region; text: string }[] = [
  { value: 'C', text: 'Cervical' },
  { value: 'S', text: 'Shoulder' },
  { value: 'E', text: 'Elbow' },
  { value: 'H/W', text: 'Hand and Wrist' },
  { value: 'T', text: 'Thoracic' },
  { value: 'L', text: 'Lumbosacral' },
  { value: 'H', text: 'Hip' },
  { value: 'HEAD', text: 'Head' },
  { value: 'K', text: 'Knee' },
  { value: 'F/A', text: 'Foot and Ankle' },
  { value: 'O', text: 'Other' },
];

export const REGIONS_HASH: { [key in Region]: string } = {
  C: 'Cervical',
  S: 'Shoulder',
  E: 'Elbow',
  'H/W': 'Hand and Wrist',
  T: 'Thoracic',
  L: 'Lumbosacral',
  H: 'Hip',
  HEAD: 'Head',
  K: 'Knee',
  'F/A': 'Foot and Ankle',
  O: 'Other',
  'F/B': 'Full Body',
};

export const LANGUAGES = [
  { value: 'en', text: 'English' },
  { value: 'es', text: 'Spanish' },
];

export const SESSION_UNITS = [
  { value: '', text: '' },
  { value: 'hour', text: 'hour' },
  { value: 'day', text: 'day' },
  { value: 'week', text: 'week' },
  { value: 'month', text: 'month' },
  { value: 'as needed', text: 'PRN' },
];

// export const TREATMENT_CATEGORIES = [
//   {
//     value: 'Modality',
//     text: 'Modality',
//   },
//   {
//     value: 'SoftTissue',
//     text: 'Soft Tissue',
//   },
//   {
//     value: 'JointManipulation/Mobilization',
//     text: 'Joint Manipulation / Mobilization',
//   },
//   {
//     value: 'Misc',
//     text: 'Misc',
//   },
// ];

export const TREATMENT_CATEGORIES = [
  { value: 'Manipulation', text: 'Manipulation' },
  { value: 'Mobilization', text: 'Mobilization' },
  {
    value: 'DirectionalPreferenceTherapy',
    text: 'Directional preference therapy',
  },
  { value: 'STM', text: 'STM' },
  { value: 'IASTM', text: 'IASTM' },
  { value: 'Neurodynamics', text: 'Neurodynamics' },
  { value: 'Taping', text: 'Taping' },
  { value: 'Other', text: 'Other' },
];

export const EXERCISE_TYPES = [
  { value: 'PROM', text: 'PROM' },
  { value: 'AROM', text: 'AROM' },
  { value: 'Stretch', text: 'Stretch' },
  { value: 'C/R Stretch', text: 'C/R Stretch' },
  { value: 'Stability', text: 'Stability' },
  { value: 'Ball/Band', text: 'Ball/Band' },
  { value: 'Weights', text: 'Weights' },
  { value: 'Functiona', text: 'Functional' },
];

export const treatmentTypes = {
  Common: 'common-treatments',
  Clinical: 'treatments',
};

export const PREFERS = [
  {
    text: 'None',
    value: '',
  },
  {
    text: 'Text message',
    value: 'text',
  },
  {
    text: 'Email',
    value: 'email',
  },
];

export const TITLES = [
  { value: '', text: 'None' },
  { value: 'Dr', text: 'Dr' },
  { value: 'Mr', text: 'Mr' },
  { value: 'Ms', text: 'Ms' },
  { value: 'Mrs', text: 'Mrs' },
];

export const COUNTRIES = [
  { text: 'United States of America', value: 'USA' },
  { text: 'Afghanistan', value: 'AFG' },
  { text: 'Åland Islands', value: 'ALA' },
  { text: 'Albania', value: 'ALB' },
  { text: 'Algeria', value: 'DZA' },
  { text: 'American Samoa', value: 'ASM' },
  { text: 'Andorra', value: 'AND' },
  { text: 'Angola', value: 'AGO' },
  { text: 'Anguilla', value: 'AIA' },
  { text: 'Antarctica', value: 'ATA' },
  { text: 'Antigua and Barbuda', value: 'ATG' },
  { text: 'Argentina', value: 'ARG' },
  { text: 'Armenia', value: 'ARM' },
  { text: 'Aruba', value: 'ABW' },
  { text: 'Australia', value: 'AUS' },
  { text: 'Austria', value: 'AUT' },
  { text: 'Azerbaijan', value: 'AZE' },
  { text: 'Bahamas', value: 'BHS' },
  { text: 'Bahrain', value: 'BHR' },
  { text: 'Bangladesh', value: 'BGD' },
  { text: 'Barbados', value: 'BRB' },
  { text: 'Belarus', value: 'BLR' },
  { text: 'Belgium', value: 'BEL' },
  { text: 'Belize', value: 'BLZ' },
  { text: 'Benin', value: 'BEN' },
  { text: 'Bermuda', value: 'BMU' },
  { text: 'Bhutan', value: 'BTN' },
  { text: 'Bolivia', value: 'BOL' },
  {
    text: 'Bonaire, Sint Eustatius and Saba',
    value: 'BES',
  },
  { text: 'Bosnia and Herzegovina', value: 'BIH' },
  { text: 'Botswana', value: 'BWA' },
  { text: 'Bouvet Island', value: 'BVT' },
  { text: 'Brazil', value: 'BRA' },
  {
    text: 'British Indian Ocean Territory',
    value: 'IOT',
  },
  { text: 'Brunei Darussalam', value: 'BRN' },
  { text: 'Bulgaria', value: 'BGR' },
  { text: 'Burkina Faso', value: 'BFA' },
  { text: 'Burundi', value: 'BDI' },
  { text: 'Cabo Verde', value: 'CPV' },
  { text: 'Cambodia', value: 'KHM' },
  { text: 'Cameroon', value: 'CMR' },
  { text: 'Canada', value: 'CAN' },
  { text: 'Cayman Islands', value: 'CYM' },
  { text: 'Central African Republic', value: 'CAF' },
  { text: 'Chad', value: 'TCD' },
  { text: 'Chile', value: 'CHL' },
  { text: 'China', value: 'CHN' },
  { text: 'Christmas Island', value: 'CXR' },
  { text: 'Cocos (Keeling) Islands', value: 'CCK' },
  { text: 'Colombia', value: 'COL' },
  { text: 'Comoros', value: 'COM' },
  { text: 'Congo', value: 'COG' },
  {
    text: 'Congo (Democratic Republic of the)',
    value: 'COD',
  },
  { text: 'Cook Islands', value: 'COK' },
  { text: 'Costa Rica', value: 'CRI' },
  { text: "Côte d'Ivoire", value: 'CIV' },
  { text: 'Croatia', value: 'HRV' },
  { text: 'Cuba', value: 'CUB' },
  { text: 'Curaçao', value: 'CUW' },
  { text: 'Cyprus', value: 'CYP' },
  { text: 'Czechia', value: 'CZE' },
  { text: 'Denmark', value: 'DNK' },
  { text: 'Djibouti', value: 'DJI' },
  { text: 'Dominica', value: 'DMA' },
  { text: 'Dominican Republic', value: 'DOM' },
  { text: 'Ecuador', value: 'ECU' },
  { text: 'Egypt', value: 'EGY' },
  { text: 'El Salvador', value: 'SLV' },
  { text: 'Equatorial Guinea', value: 'GNQ' },
  { text: 'Eritrea', value: 'ERI' },
  { text: 'Estonia', value: 'EST' },
  { text: 'Ethiopia', value: 'ETH' },
  {
    text: 'Falkland Islands (Malvinas)',
    value: 'FLK',
  },
  { text: 'Faroe Islands', value: 'FRO' },
  { text: 'Fiji', value: 'FJI' },
  { text: 'Finland', value: 'FIN' },
  { text: 'France', value: 'FRA' },
  { text: 'French Guiana', value: 'GUF' },
  { text: 'French Polynesia', value: 'PYF' },
  {
    text: 'French Southern Territories',
    value: 'ATF',
  },
  { text: 'Gabon', value: 'GAB' },
  { text: 'Gambia', value: 'GMB' },
  { text: 'Georgia', value: 'GEO' },
  { text: 'Germany', value: 'DEU' },
  { text: 'Ghana', value: 'GHA' },
  { text: 'Gibraltar', value: 'GIB' },
  { text: 'Greece', value: 'GRC' },
  { text: 'Greenland', value: 'GRL' },
  { text: 'Grenada', value: 'GRD' },
  { text: 'Guadeloupe', value: 'GLP' },
  { text: 'Guam', value: 'GUM' },
  { text: 'Guatemala', value: 'GTM' },
  { text: 'Guernsey', value: 'GGY' },
  { text: 'Guinea', value: 'GIN' },
  { text: 'Guinea-Bissau', value: 'GNB' },
  { text: 'Guyana', value: 'GUY' },
  { text: 'Haiti', value: 'HTI' },
  { text: 'Honduras', value: 'HND' },
  { text: 'Hong Kong', value: 'HKG' },
  { text: 'Hungary', value: 'HUN' },
  { text: 'Iceland', value: 'ISL' },
  { text: 'India', value: 'IND' },
  { text: 'Indonesia', value: 'IDN' },
  {
    text: 'Iran (Islamic Republic of)',
    value: 'IRN',
  },
  { text: 'Iraq', value: 'IRQ' },
  { text: 'Ireland', value: 'IRL' },
  { text: 'Isle of Man', value: 'IMN' },
  { text: 'Israel', value: 'ISR' },
  { text: 'Italy', value: 'ITA' },
  { text: 'Jamaica', value: 'JAM' },
  { text: 'Japan', value: 'JPN' },
  { text: 'Jersey', value: 'JEY' },
  { text: 'Jordan', value: 'JOR' },
  { text: 'Kazakhstan', value: 'KAZ' },
  { text: 'Kenya', value: 'KEN' },
  { text: 'Kiribati', value: 'KIR' },
  {
    text: "Korea (Democratic People's Republic of)",
    value: 'PRK',
  },
  { text: 'Korea (Republic of)', value: 'KOR' },
  { text: 'Kosovo', value: 'XKX' },
  { text: 'Kuwait', value: 'KWT' },
  { text: 'Kyrgyzstan', value: 'KGZ' },
  {
    text: "Lao People's Democratic Republic",
    value: 'LAO',
  },
  { text: 'Latvia', value: 'LVA' },
  { text: 'Lebanon', value: 'LBN' },
  { text: 'Lesotho', value: 'LSO' },
  { text: 'Liberia', value: 'LBR' },
  { text: 'Libya', value: 'LBY' },
  { text: 'Liechtenstein', value: 'LIE' },
  { text: 'Lithuania', value: 'LTU' },
  { text: 'Luxembourg', value: 'LUX' },
  { text: 'Macau', value: 'MAC' },
  {
    text: 'Macedonia (the former Yugoslav Republic of)',
    value: 'MKD',
  },
  { text: 'Madagascar', value: 'MDG' },
  { text: 'Malawi', value: 'MWI' },
  { text: 'Malaysia', value: 'MYS' },
  { text: 'Maldives', value: 'MDV' },
  { text: 'Mali', value: 'MLI' },
  { text: 'Malta', value: 'MLT' },
  { text: 'Marshall Islands', value: 'MHL' },
  { text: 'Martinique', value: 'MTQ' },
  { text: 'Mauritania', value: 'MRT' },
  { text: 'Mauritius', value: 'MUS' },
  { text: 'Mayotte', value: 'MYT' },
  { text: 'Mexico', value: 'MEX' },
  {
    text: 'Micronesia (Federated States of)',
    value: 'FSM',
  },
  { text: 'Moldova (Republic of)', value: 'MDA' },
  { text: 'Monaco', value: 'MCO' },
  { text: 'Mongolia', value: 'MNG' },
  { text: 'Montenegro', value: 'MNE' },
  { text: 'Montserrat', value: 'MSR' },
  { text: 'Morocco', value: 'MAR' },
  { text: 'Mozambique', value: 'MOZ' },
  { text: 'Myanmar', value: 'MMR' },
  { text: 'Namibia', value: 'NAM' },
  { text: 'Nauru', value: 'NRU' },
  { text: 'Nepal', value: 'NPL' },
  { text: 'Netherlands', value: 'NLD' },
  { text: 'New Caledonia', value: 'NCL' },
  { text: 'New Zealand', value: 'NZL' },
  { text: 'Nicaragua', value: 'NIC' },
  { text: 'Niger', value: 'NER' },
  { text: 'Nigeria', value: 'NGA' },
  { text: 'Niue', value: 'NIU' },
  { text: 'Norfolk Island', value: 'NFK' },
  { text: 'Northern Mariana Islands', value: 'MNP' },
  { text: 'Norway', value: 'NOR' },
  { text: 'Oman', value: 'OMN' },
  { text: 'Pakistan', value: 'PAK' },
  { text: 'Palau', value: 'PLW' },
  { text: 'Palestine, State of', value: 'PSE' },
  { text: 'Panama', value: 'PAN' },
  { text: 'Papua New Guinea', value: 'PNG' },
  { text: 'Paraguay', value: 'PRY' },
  { text: 'Peru', value: 'PER' },
  { text: 'Philippines', value: 'PHL' },
  { text: 'Pitcairn', value: 'PCN' },
  { text: 'Poland', value: 'POL' },
  { text: 'Portugal', value: 'PRT' },
  { text: 'Puerto Rico', value: 'PRI' },
  { text: 'Qatar', value: 'QAT' },
  { text: 'Réunion', value: 'REU' },
  { text: 'Romania', value: 'ROU' },
  { text: 'Russia', value: 'RUS' },
  { text: 'Rwanda', value: 'RWA' },
  { text: 'Saint Barthélemy', value: 'BLM' },
  {
    text: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SHN',
  },
  { text: 'Saint Kitts and Nevis', value: 'KNA' },
  { text: 'Saint Lucia', value: 'LCA' },
  {
    text: 'Saint Martin (French part)',
    value: 'MAF',
  },
  {
    text: 'Saint Pierre and Miquelon',
    value: 'SPM',
  },
  {
    text: 'Saint Vincent and the Grenadines',
    value: 'VCT',
  },
  { text: 'Samoa', value: 'WSM' },
  { text: 'San Marino', value: 'SMR' },
  { text: 'Sao Tome and Principe', value: 'STP' },
  { text: 'Saudi Arabia', value: 'SAU' },
  { text: 'Senegal', value: 'SEN' },
  { text: 'Serbia', value: 'SRB' },
  { text: 'Seychelles', value: 'SYC' },
  { text: 'Sierra Leone', value: 'SLE' },
  { text: 'Singapore', value: 'SGP' },
  {
    text: 'Sint Maarten (Dutch part)',
    value: 'SXM',
  },
  { text: 'Slovakia', value: 'SVK' },
  { text: 'Slovenia', value: 'SVN' },
  { text: 'Solomon Islands', value: 'SLB' },
  { text: 'Somalia', value: 'SOM' },
  { text: 'South Africa', value: 'ZAF' },
  {
    text: 'South Georgia and the South Sandwich Islands',
    value: 'SGS',
  },
  { text: 'South Sudan', value: 'SSD' },
  { text: 'Spain', value: 'ESP' },
  { text: 'Sri Lanka', value: 'LKA' },
  { text: 'Sudan', value: 'SDN' },
  { text: 'Suriname', value: 'SUR' },
  { text: 'Svalbard and Jan Mayen', value: 'SJM' },
  { text: 'Sweden', value: 'SWE' },
  { text: 'Swaziland', value: 'SWZ' },
  { text: 'Switzerland', value: 'CHE' },
  { text: 'Syrian Arab Republic', value: 'SYR' },
  {
    text: 'Taiwan, Province of China',
    value: 'TWN',
  },
  { text: 'Tajikistan', value: 'TJK' },
  {
    text: 'Tanzania, United Republic of',
    value: 'TZA',
  },
  { text: 'Thailand', value: 'THA' },
  { text: 'Timor-Leste', value: 'TLS' },
  { text: 'Togo', value: 'TGO' },
  { text: 'Tokelau', value: 'TKL' },
  { text: 'Tonga', value: 'TON' },
  { text: 'Trinidad and Tobago', value: 'TTO' },
  { text: 'Tunisia', value: 'TUN' },
  { text: 'Turkey', value: 'TUR' },
  { text: 'Turkmenistan', value: 'TKM' },
  { text: 'Turks and Caicos Islands', value: 'TCA' },
  { text: 'Tuvalu', value: 'TUV' },
  { text: 'Uganda', value: 'UGA' },
  { text: 'Ukraine', value: 'UKR' },
  { text: 'United Arab Emirates', value: 'ARE' },
  {
    text: 'United Kingdom of Great Britain and Northern Ireland',
    value: 'GBR',
  },
  {
    text: 'United States Minor Outlying Islands',
    value: 'UMI',
  },
  { text: 'Uruguay', value: 'URY' },
  { text: 'Uzbekistan', value: 'UZB' },
  { text: 'Vanuatu', value: 'VUT' },
  { text: 'Vatican City', value: 'VAT' },
  {
    text: 'Venezuela (Bolivarian Republic of)',
    value: 'VEN',
  },
  { text: 'Vietnam', value: 'VNM' },
  { text: 'Virgin Islands (British)', value: 'VGB' },
  { text: 'Virgin Islands (U.S.)', value: 'VIR' },
  { text: 'Wallis and Futuna', value: 'WLF' },
  { text: 'Western Sahara', value: 'ESH' },
  { text: 'Yemen', value: 'YEM' },
  { text: 'Zambia', value: 'ZMB' },
  { text: 'Zimbabwe', value: 'ZWE' },
];

export const THEMES = [
  {
    text: 'Default',
    value: 'default-theme',
    primary: '#13547a',
    accent: '#78cac1',
    primaryText: '#ffffff',
    accentText: '#58585A',
  },
  {
    text: 'Green beach',
    value: 'green-beach-theme',
    primary: '#02aab0',
    accent: '#00cdac',
    primaryText: '#ffffff',
    accentText: '#ffffff',
  },
  {
    text: 'Blue',
    value: 'blue-theme',
    primary: '#314755',
    accent: '#26a0da',
    primaryText: '#ffffff',
    accentText: '#58585A',
  },
  {
    text: 'Green',
    value: 'original-green-theme',
    primary: '#76be40',
    accent: '#d2ff9c',
    primaryText: '#ffffff',
    accentText: '#58585A',
  },
  {
    text: 'Orange',
    value: 'orange-theme',
    primary: '#e65c00',
    accent: '#f9d423',
    primaryText: '#ffffff',
    accentText: '#58585A',
  },
  {
    text: 'Purple',
    value: 'purple-theme',
    primary: '#cc2b5e',
    accent: '#753a88',
    primaryText: '#ffffff',
    accentText: '#ffffff',
  },
  {
    text: 'Ultraviolet',
    value: 'ultra-violet-theme',
    primary: '#654ea3',
    accent: '#eaafc8',
    primaryText: '#ffffff',
    accentText: '#58585A',
  },
  {
    text: 'Blue/Orange',
    value: 'blue-orange-theme',
    primary: '#544a7d',
    accent: '#ffd452',
    primaryText: '#ffffff',
    accentText: '#58585A',
  },
  {
    text: 'Red',
    value: 'red-theme',
    primary: '#ed213a',
    accent: '#93291e',
    primaryText: '#ffffff',
    accentText: '#ffffff',
  },
  {
    text: 'Grey',
    value: 'grey-theme',
    primary: '#9a9c9e',
    accent: '#2c3e50',
    primaryText: '#ffffff',
    accentText: '#ffffff',
  },
];

export const PLAN_HASH: { [key: string]: string } = {
  premium: 'Premium',
  basic: 'Basic',
  plus: 'Plus',
};

export const FORM_CATEGORIES = [
  {
    value: 'Miscellaneous Business Forms',
    text: 'Miscellaneous Business Forms',
  },
  { value: 'Business Accounting Forms', text: 'Business Accounting Forms' },
  { value: 'Marketing Sample Library', text: 'Marketing Sample Library' },
  { value: 'Office Procedures', text: 'Office Procedures' },
  { value: 'Reference', text: 'Reference' },
  { value: 'Communication', text: 'Communication' },
  { value: 'Employee Policy', text: 'Employee Policy' },
  {
    value: 'Goals Tracking And Statistics',
    text: 'Goals Tracking And Statistics',
  },
  {
    value: 'Sample Business Correspondence',
    text: 'Sample Business Correspondence',
  },
  { value: 'New Hire Forms', text: 'New Hire Forms' },
  { value: 'Patient Financial Forms', text: 'Patient Financial Forms' },
  { value: 'Patient Handouts', text: 'Patient Handouts' },
  { value: 'Impairment Questionnaires', text: 'Impairment Questionnaires' },
  { value: 'Screenings And Evaluations', text: 'Screenings And Evaluations' },
  { value: 'Referrals And Orders', text: 'Referrals And Orders' },
  { value: 'Sample Clinical Letters', text: 'Sample Clinical Letters' },
  { value: 'Marketing Sample Library', text: 'Marketing Sample Library' },
];
