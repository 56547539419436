import { OpenClosedStates } from '@chiroup/components';
import { useForm } from '@chiroup/hooks';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import useUsers from '../../../hooks/useUsers';
import Button, { ButtonColors } from '../../common/Button';
import SearchInput from '../../common/fields/inputs/SearchInput';
import Filters from '../../common/Filters';
import List from '../../common/list/List';
import ListItem from '../../common/list/ListItem';
import Modal from '../../common/Modal';
import UserCreateModal from '../../users/UserCreateModal';
import RoleSelectModal from './RoleSelectModal';

type Props = {
  isOpen: OpenClosedStates;
  setIsOpen: (arg: OpenClosedStates) => void;
  clinicID?: number;
  refetch: () => void;
  isEHR?: boolean;
};

const AddUserModal = ({
  isOpen,
  setIsOpen,
  clinicID,
  refetch,
  isEHR,
}: Props) => {
  const [confirmModalState, setConfirmModalState] = useState<OpenClosedStates>(
    OpenClosedStates.Closed,
  );
  const [createUserModalState, setCreateUserModalState] =
    useState<OpenClosedStates>(OpenClosedStates.Closed);
  const [userToAdd, setUserToAdd] = useState<any>();
  const {
    data,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    onSearch,
  } = useUsers();
  const { value, onChange } = useForm<{ search: string }>(
    {
      search: '',
    },
    {},
  );

  useEffect(() => {
    onSearch(value?.search || '');
  }, [value, onSearch]);

  const addUserToClinic = (user: any) => {
    setUserToAdd(user);
    setConfirmModalState(OpenClosedStates.Open);
  };

  const onSuccess = () => {
    setConfirmModalState(OpenClosedStates.Closed);
    setIsOpen(OpenClosedStates.Closed);
    setCreateUserModalState(OpenClosedStates.Closed);
    refetch();
  };

  return (
    <Modal
      isOpen={isOpen === OpenClosedStates.Open}
      close={() => setIsOpen(OpenClosedStates.Closed)}
    >
      <div>
        <Filters
          main={
            <SearchInput onChange={onChange('search')} value={value.search} />
          }
        />

        <Button
          text="Create new user"
          onClick={() => {
            setCreateUserModalState(OpenClosedStates.Open);
          }}
          className="w-full h-1/2"
          color={ButtonColors.plainWithBorder}
        />
      </div>
      <List
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
      >
        {data?.pages?.map((page, pageNumber) => (
          <React.Fragment key={`users-page-${pageNumber}`}>
            {page?.data?.map((row, i) => (
              <div
                onClick={() => addUserToClinic(row)}
                key={row.ID}
                className="cursor-pointer"
              >
                <ListItem index={i}>
                  <div className="flex items-center justify-between space-x-4">
                    <div className="min-w-0">
                      <div className="relative group flex items-center space-x-4">
                        <svg
                          className="flex-shrink-0 w-12 h-12 text-gray-400 group-hover:text-gray-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="2 2 16 16"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <div className="space-y-3">
                          <div className="flex items-center space-x-3">
                            <span className="block">
                              <h2 className="text-sm font-medium leading-4">
                                <span className="absolute inset-0" />
                                {row.lastFirst}
                              </h2>
                            </span>
                          </div>
                          <div className="text-sm leading-4 text-gray-500 group-hover:text-gray-900 font-medium truncate">
                            {row.email}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sm:hidden">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
                      <p className="flex items-center space-x-4">
                        <span className="relative text-sm leading-4 text-gray-500 hover:text-gray-900 font-medium">
                          {row.status}
                        </span>
                      </p>
                      <p className="flex text-gray-500 text-sm leading-4 space-x-2">
                        {/* <span>{row.phone ? formatPhone(row.phone) : ''}</span> */}
                        <span>
                          Created:{' '}
                          {row.createDate
                            ? dayjs(row.createDate).format('DD/MM/YYYY')
                            : ''}
                        </span>
                      </p>
                    </div>
                  </div>
                </ListItem>
              </div>
            ))}
          </React.Fragment>
        ))}
      </List>
      <RoleSelectModal
        isOpen={confirmModalState}
        setIsOpen={setConfirmModalState}
        clinicID={clinicID}
        user={userToAdd}
        closeAddUserModal={() => setIsOpen(OpenClosedStates.Closed)}
        onSuccess={onSuccess}
        isEHR={isEHR}
      />
      <UserCreateModal
        state={createUserModalState}
        close={() => setCreateUserModalState(OpenClosedStates.Closed)}
        onCreate={onSuccess}
        clinicID={clinicID}
        isEHR={isEHR}
      />
    </Modal>
  );
};

export default AddUserModal;
