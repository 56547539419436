import React, { useContext, useState } from 'react';
import { ToastContext, ToastTypes } from '../../../contexts/toast.context';
import formService from '../../../services/form.service';
import Button, { ButtonColors } from '../../common/Button';
import Modal from '../../common/Modal';

type Props = {
  isOpen: boolean;
  id: number | null;
  close: () => void;
  refetch: () => void;
};

const DeleteFormModal: React.FC<Props> = ({ isOpen, id, close, refetch }) => {
  const { createToast } = useContext(ToastContext);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onDelete = async () => {
    if (!id) return;
    setIsDeleting(true);
    try {
      await formService.del(id);
      refetch();
    } catch (err) {
      createToast({
        title: 'Error!',
        description: <>Failed to delete Form!</>,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    }
    setIsDeleting(false);
    close();
  };

  return (
    <Modal isOpen={isOpen} close={close}>
      <div className="mt-3 text-center sm:mt-5">
        <h3
          className="text-lg leading-6 font-medium text-gray-900 dark:text-darkGray-100"
          id="modal-headline"
        >
          Do you want to delete this form?
        </h3>
      </div>

      <div className="mt-5 sm:mt-6">
        <span className="flex w-full gap-x-4">
          <Button
            text="Yes"
            onClick={() => onDelete()}
            fullWidth
            color={ButtonColors.plain}
            disabled={isDeleting}
            className="border border-gray-300 dark:border-darkGray-500"
          />
          <Button text="No" onClick={close} fullWidth />
        </span>
      </div>
    </Modal>
  );
};

export default DeleteFormModal;
