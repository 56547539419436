import {
  Checkbox,
  Input,
  InputMasked,
  Phone,
  Select,
  Toggle,
  ZipCodeInput,
} from '@chiroup/components';
import {
  enumToArrayOfOptions,
  FlagGroups,
  FormFieldErrors,
  ListClinic,
  ValueOf,
} from '@chiroup/core';
import React from 'react';
import { LANGUAGES } from '../../../constants';

type Props = {
  fieldErrors?: FormFieldErrors;
  value: Partial<ListClinic>;
  onChange: (
    key: keyof Partial<ListClinic>,
  ) => (val: ValueOf<ListClinic>) => void;
  patchValue: (val: Partial<ListClinic>) => void;
};

const ClinicGeneralFormElements: React.FC<Props> = ({
  fieldErrors,
  value,
  onChange,
  patchValue,
}) => {
  const handlePlanChange = (val: ValueOf<ListClinic>) => {
    if (val === 'basic') {
      patchValue({ postingSchedule: null });
    }
    patchValue({ plan: val });
  };

  const handlePostingScheduleChange = (val: ValueOf<ListClinic>) => {
    if (value.plan === 'basic') {
      return;
    } else patchValue({ postingSchedule: val });
  };
  return (
    <>
      {!value.ID && (
        <>
          <Input
            name="name"
            className="col-span-2"
            label="Name *"
            value={value.name}
            onChange={onChange('name')}
            errors={fieldErrors?.name}
          />
          <Input
            name="address1"
            className="col-span-2"
            label="Address 1"
            value={value.address1}
            onChange={onChange('address1')}
            errors={fieldErrors?.address1}
          />
          <Input
            name="address2"
            className="col-span-2"
            label="Address 2"
            value={value.address2}
            onChange={onChange('address2')}
            errors={fieldErrors?.address2}
          />
          <Input
            name="city"
            className="col-span-2"
            label="City"
            value={value.city}
            onChange={onChange('city')}
            errors={fieldErrors?.city}
          />
          <Input
            name="state"
            className="col-span-2"
            label="State/Province"
            value={value.state}
            onChange={onChange('state')}
            errors={fieldErrors?.state}
          />
          <ZipCodeInput
            name="zip"
            className="col-span-2"
            label="Zip/Postal code"
            value={value.zip}
            onChange={onChange('zip')}
            country={value.country ?? ''}
            errors={fieldErrors?.zip}
          />
          <Input
            name="reviewURL"
            className="col-span-2"
            label="Google review URL"
            value={value.reviewURL}
            onChange={onChange('reviewURL')}
            errors={fieldErrors?.reviewURL}
          />
          <Input
            name="site"
            className="col-span-2"
            label="Website"
            value={value.site}
            onChange={onChange('site')}
            errors={fieldErrors?.site}
          />
          <Input
            name="facebook"
            className="col-span-2"
            label="Facebook username"
            value={value.facebook}
            onChange={onChange('facebook')}
            errors={fieldErrors?.facebook}
          />
          <Input
            name="twitter"
            className="col-span-2"
            label="Twitter username"
            value={value.twitter}
            onChange={onChange('twitter')}
            errors={fieldErrors?.twitter}
          />
          <Input
            name="youtube"
            className="col-span-2"
            label="Youtube"
            value={value.youtube}
            onChange={onChange('youtube')}
            errors={fieldErrors?.youtube}
          />
          <Phone
            name="phone"
            className="col-span-2"
            label="Phone number"
            value={value.phone}
            onChange={onChange('phone')}
            errors={fieldErrors?.phone}
          />
          <Phone
            name="fax"
            className="col-span-2"
            label="Fax number"
            value={value.fax}
            onChange={onChange('fax')}
            errors={fieldErrors?.fax}
          />
          <Input
            name="email"
            className="col-span-2"
            label="Email"
            value={value.email}
            onChange={onChange('email')}
            errors={fieldErrors?.email}
          />
        </>
      )}
      <Select
        name="language"
        label="Primary language *"
        options={LANGUAGES}
        value={value.language}
        onChange={onChange('language')}
        className="col-span-2"
        errors={fieldErrors?.language}
        limit={1}
      />
      <Select
        name={'postingSchedule'}
        label={'Posting schedule'}
        className={'col-span-2'}
        onChange={handlePostingScheduleChange}
        clearable={true}
        value={value.postingSchedule}
        limit={1}
        options={[
          { text: 'A', value: 'A' },
          {
            text: 'B',
            value: 'B',
          },
        ]}
      />
      <Select
        name="flagGroup"
        options={enumToArrayOfOptions(FlagGroups)}
        label="Flag group"
        className="col-span-2"
        value={value.flagGroup}
        onChange={onChange('flagGroup')}
        limit={1}
      />
      <InputMasked
        className="col-span-2"
        value={value.plusUntil as string}
        label={'Plus until'}
        name={'plusUntil'}
        onChange={onChange('plusUntil')}
        patternFormat="##/##/####"
        placeholder="MM/DD/YYYY"
      />
      <InputMasked
        className="col-span-2"
        value={value.premiumUntil as string}
        label={'Premium until'}
        name={'premiumUntil'}
        onChange={onChange('premiumUntil')}
        patternFormat="##/##/####"
        placeholder="MM/DD/YYYY"
      />
      <Select
        name="plan"
        className="col-span-4"
        label="Plan *"
        options={[
          { value: 'basic', text: 'Basic' },
          { value: 'plus', text: 'Plus' },
          { value: 'premium', text: 'Premium' },
          { value: 'education', text: 'Education' },
          { value: 'trial', text: 'Trial' },
        ]}
        value={value.plan}
        onChange={handlePlanChange}
        errors={fieldErrors?.plan}
        limit={1}
      />
      <InputMasked
        className="col-span-2"
        name="subscriptionStartDate"
        label="Subscription start date"
        value={value.subscriptionStartDate}
        onChange={onChange('subscriptionStartDate')}
        errors={fieldErrors?.subscriptionStartDate}
        patternFormat="##/##/####"
        placeholder="MM/DD/YYYY"
      />
      <InputMasked
        className="col-span-2"
        name="billDate"
        label="Bill date"
        value={value.billDate}
        onChange={onChange('billDate')}
        errors={fieldErrors?.billDate}
        patternFormat="##/##/####"
        placeholder="MM/DD/YYYY"
      />
      <InputMasked
        className="col-span-2"
        name="trialEndDate"
        label="Trial end date"
        value={value.trialEndDate}
        onChange={onChange('trialEndDate')}
        errors={fieldErrors?.trialEndDate}
        patternFormat="##/##/####"
        placeholder="MM/DD/YYYY"
      />
      <Input
        name="trialCount"
        className="col-span-2"
        label="Trial count"
        value={value.trialCount}
        onChange={onChange('trialCount')}
        errors={fieldErrors?.trialCount}
        hint="To not allow more trials, set to 3"
      />
      <Input
        name="referralCode"
        className="col-span-2"
        label="Referral code"
        value={value.referralCode}
        onChange={onChange('referralCode')}
        errors={fieldErrors?.referralCode}
      />
      <Input
        name="stripeSubscriptionId"
        className="col-span-2"
        label="Stripe subscription ID"
        value={value.stripeSubscriptionId}
        onChange={onChange('stripeSubscriptionId')}
        errors={fieldErrors?.stripeSubscriptionId}
      />
      <Input
        name="stripeCustomerId"
        className="col-span-2"
        label="Stripe customer ID"
        value={value.stripeCustomerId}
        onChange={onChange('stripeCustomerId')}
        errors={fieldErrors?.stripeCustomerId}
      />
      <div className="col-span-2 flex-column self-end justify-self-start">
        <Checkbox
          name="bypassPaymentGateway"
          className="col-span-1 font-medium leading-5 text-gray-900 dark:text-darkGray-200 sm:mt-px sm:pt-2"
          label="Bypass payment gateway"
          value={value.bypassPaymentGateway}
          onChange={onChange('bypassPaymentGateway')}
          errors={fieldErrors?.bypassPaymentGateway}
        />
        <Checkbox
          name="freeAccount"
          className="col-span-1 font-medium leading-5 text-gray-900 dark:text-darkGray-200 sm:mt-px sm:pt-2"
          label="Free account"
          value={value.freeAccount}
          onChange={onChange('freeAccount')}
          errors={fieldErrors?.freeAccount}
        />
        <Checkbox
          name="ehrMigrationAllowed"
          className="col-span-1 font-medium leading-5 text-gray-900 dark:text-darkGray-200 sm:mt-px sm:pt-2"
          label="EHR migration allowed"
          value={value.ehrMigrationAllowed}
          onChange={onChange('ehrMigrationAllowed')}
          errors={fieldErrors?.ehrMigrationAllowed}
        />
        <Checkbox
          name="patientMergeAllowed"
          className="col-span-1 font-medium leading-5 text-gray-900 dark:text-darkGray-200 sm:mt-px sm:pt-2"
          label="Patient merge allowed"
          value={value.patientMergeAllowed}
          onChange={onChange('patientMergeAllowed')}
          errors={fieldErrors?.patientMergeAllowed}
        />
        <Checkbox
          name="ehr"
          className="col-span-1 font-medium leading-5 text-gray-900 dark:text-darkGray-200 sm:mt-px sm:pt-2"
          label="EHR"
          value={value.ehr}
          onChange={onChange('ehr')}
          errors={fieldErrors?.ehr}
        />
      </div>
      {(value.freeAccount || value.bypassPaymentGateway) && (
        <Input
          name="additionalLocations"
          className="col-span-2"
          label="Additional locations"
          value={value.additionalLocations}
          onChange={onChange('additionalLocations')}
          errors={fieldErrors?.additionalLocations}
          hint="Number of additional locations, not including the main location"
        />
      )}
      <Toggle
        label="Outcome tracker"
        onChange={(val) => onChange('surveyOptOut')(!val ? 1 : 0)}
        value={Number(value.surveyOptOut) === 0}
      />
      {/* <Uploader
        name="img"
        label="Logo"
        onChange={onChange('logo')}
        className="col-span-4"
        value={value.logo}
        type="image"
        uploadCategory="logos"
        uploadType="image"
        fileTypes={['png', 'jpg', 'jpeg', 'gif']}
        fileSizeLimit={10}
        resize
      /> */}
    </>
  );
};

export default ClinicGeneralFormElements;
