import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ClinicList from './ClinicList';
import ClinicContainer from './detail/ClinicContainer';

const Clinics = () => {
  return (
    <Routes>
      <Route path="/" element={<ClinicList />} />
      <Route path={`/:clinicID/*`} element={<ClinicContainer />} />
      <Route path="*" element={<span>no match</span>} />
    </Routes>
  );
};

export default Clinics;
