import { ChiroUpAPI } from '@chiroup/client-core';

const disabilitiesService = () => {
  const list = async (params: {
    filters?: any;
    limit?: number;
    skip?: number;
  }) => {
    const jsonSearch = JSON.stringify(params.filters);
    params.filters = jsonSearch;
    return await ChiroUpAPI.get('api', `/disability`, {
      queryParams: params,
    });
  };

  const findOne = async (ID?: number): Promise<any> => {
    const res = await ChiroUpAPI.get('api', `/disability/${ID}`, {});
    return res[0][0];
  };

  const update = async (disability: Partial<any>) => {
    const { ID, ...restOfDisability } = disability;
    const res = await ChiroUpAPI.put('api', `/disability/${ID}`, {
      body: restOfDisability,
    });
    return res || {};
  };

  const create = async (val: Partial<any>) => {
    const res = await ChiroUpAPI.post('api', `/disability`, {
      body: val,
    });
    return res || {};
  };

  return { list, findOne, update, create };
};

export default disabilitiesService();
