import { OpenClosedStates, Select } from '@chiroup/components';
import { BASE_ROLES, ROLES, UserRoles } from '@chiroup/core';
import { useForm } from '@chiroup/hooks';
import userService from '../../../services/user.service';
import Button, { ButtonColors } from '../../common/Button';
import Modal from '../../common/Modal';

type Props = {
  isOpen: OpenClosedStates;
  setIsOpen: (arg: OpenClosedStates) => void;
  clinicID?: number;
  user: any;
  closeAddUserModal: () => void;
  onSuccess: () => void;
  isEHR?: boolean;
};

const RoleSelectModal = ({
  isOpen,
  setIsOpen,
  clinicID,
  user,
  closeAddUserModal,
  onSuccess,
  isEHR,
}: Props) => {
  const { value, patchValue, registerSubmit } = useForm<{
    userID: string;
    clinicID: number;
    role: string[];
  }>({
    role: [UserRoles.Admin],
  });

  const onSubmit = async () => {
    const res = await userService.addUserToClinic(
      value,
      user.ID,
      clinicID,
      user.salesforceID,
    );
    return res;
  };

  const onChangeRole = (val: string[]) => {
    const valToUse: any = { role: val };
    patchValue(valToUse);
  };

  return (
    <Modal
      isOpen={isOpen === OpenClosedStates.Open}
      close={() => setIsOpen(OpenClosedStates.Closed)}
    >
      <form>
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-darkGray-500">
            <svg
              className="h-6 w-6 text-gray-600 dark:text-darkGray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-lg leading-6 font-medium text-gray-900 dark:text-darkGray-100"
              id="modal-headline"
            >
              Add user to clinic
            </h3>
            <div className="flex justify-center items-center">
              <Select
                options={isEHR ? ROLES : BASE_ROLES}
                name="role"
                value={value.role}
                className="flex flex-row col-span-4 mr-4"
                onChange={onChangeRole}
                label="Role"
              />
            </div>
            <div className="mt-2">
              <p className="text-sm leading-5 text-gray-500 dark:text-darkGray-400">
                Are you sure you wish to add this user to this clinic?
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6 grid grid-cols-2 gap-3 grid-flow-row-dense">
          <Button
            text="Close"
            onClick={() => setIsOpen(OpenClosedStates.Closed)}
            fullWidth
            color={ButtonColors.plainWithBorder}
          />
          <Button
            text="Confirm"
            onClick={registerSubmit(onSubmit, { onSuccess })}
            fullWidth
            color={ButtonColors.primary}
          />
        </div>
      </form>
    </Modal>
  );
};

export default RoleSelectModal;
