import { Input, Select, Textarea } from '@chiroup/components';
import {
  FormFieldErrors,
  MarketingCampaignDetail,
  ValueOf,
} from '@chiroup/core';
import React from 'react';
import { TiptapEditor } from '../../../tiptap/TiptapEditor';

type Props = {
  fieldErrors?: FormFieldErrors;
  value: Partial<MarketingCampaignDetail>;
  onChange: (
    key: keyof Partial<MarketingCampaignDetail>,
  ) => (val: ValueOf<MarketingCampaignDetail>) => void;
};

const types = [
  { value: 'Professional', text: 'Professional' },
  { value: 'Internal', text: 'Internal' },
  { value: 'External', text: 'External' },
];

const MarketingGeneralFormElements: React.FC<Props> = ({
  fieldErrors,
  value,
  onChange,
}) => {
  return (
    <>
      <Select
        name="type"
        className="col-span-2"
        label="Type"
        value={value.type}
        onChange={onChange('type')}
        errors={fieldErrors?.type}
        options={types}
        limit={1}
      />
      <Input
        name="title"
        className="col-span-4"
        label="Title *"
        value={value.title}
        onChange={onChange('title')}
        errors={fieldErrors?.title}
      />
      <Input
        name="url"
        className="col-span-4"
        label="URL"
        value={value.url}
        onChange={onChange('url')}
        errors={fieldErrors?.url}
      />
      <Textarea
        name="descr"
        className="col-span-4"
        label="Description"
        value={value.descr}
        onChange={onChange('descr')}
        errors={fieldErrors?.descr}
      />
      <TiptapEditor
        name="ImplementationProtocol"
        containerClassName="col-span-4"
        label="Implementation protocol"
        value={value.ImplementationProtocol}
        onChange={onChange('ImplementationProtocol')}
        errors={fieldErrors?.ImplementationProtocol}
      />
    </>
  );
};

export default MarketingGeneralFormElements;
