import { useSortable } from '@dnd-kit/sortable';
import { ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { CSS } from '@dnd-kit/utilities';

type SortableProps = {
  id: string;
  children?: React.ReactNode;
  removeItem?: (id: string) => void;
};

const HomePageSortableItem: React.FC<SortableProps> = ({
  id,
  children,
  removeItem,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="relative">
      <ChevronUpDownIcon
        className="h-6 w-6 text-gray-400 focus:outline-none absolute left-1 top-2 z-40"
        {...listeners}
        {...attributes}
      />
      {removeItem && (
        <XMarkIcon
          className="h-6 w-6 text-gray-400 focus:outline-none absolute right-1 top-2 z-40 cursor-pointer"
          onClick={() => removeItem(id)}
        />
      )}
      {children}
    </div>
  );
};

export default HomePageSortableItem;
