import { ChiroUpAPI } from '@chiroup/client-core';

const filesService = () => {
  const get = async (key: string): Promise<any> => {
    return ChiroUpAPI.get('api', `/files`, {
      queryParams: {
        key,
      },
    });
  };

  return { get };
};

export default filesService();
