import { FieldErrors } from '@chiroup/components';
import { FormError } from '@chiroup/core';
import 'cropperjs/dist/cropper.css';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import useUpload from '../../../hooks/useUpload';
import { getExtension } from '../helpers/getExtension';
import { resizeImage } from '../helpers/resizeImage';
import { sleep } from '../helpers/sleep';
import Loading from '../Loading';
import UploadedItem from './UploadedItem';

type Props = {
  name: string;
  label: string;
  onChange: (val: string | null) => void;
  className: string;
  value?: string | null;
  fileTypes: string[];
  fileSizeLimit: number;
  type: 'image' | 'video';
  uploadCategory: string;
  uploadType: string;
  resize?: boolean;
  errors?: FormError;
  disabled?: boolean;
  square?: boolean;
  status?: string;
  onChangeStatus?: (status: string) => void;
  customLabel?: string;
  onClickCustom?: () => void;
  setImageAwaitingCrop?: (
    val: { blob: any; extension: string | undefined } | null,
  ) => void;
};

const Uploader: React.FC<Props> = ({
  name,
  label,
  onChange,
  className,
  value,
  fileTypes,
  fileSizeLimit,
  type,
  uploadCategory,
  uploadType,
  resize = true,
  errors,
  disabled,
  square = false,
  status,
  onChangeStatus,
  customLabel,
  onClickCustom,
  setImageAwaitingCrop,
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const cropperRef = useRef<HTMLImageElement>(null);
  const { upload, getStatus } = useUpload();
  const [uploading, setUploading] = useState(false);
  const [temp, setTemp] = useState<string | null>(null);
  const [blobName, setBlobName] = useState<string>();

  const onChangeInput = async (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }
    let file: any = (e.target.files || [])[0];
    if (!file) {
      return;
    }
    setBlobName(file.name);
    const extension = getExtension(file.name?.toLowerCase())?.toLowerCase();
    if (!extension || !fileTypes.includes(extension)) {
      return;
    }
    if (square) {
      const config = {
        file,
        maxSize: 1000,
        extension,
      };
      file = await resizeImage(config);
      const reader = new FileReader();
      reader.onload = async () => {
        setTemp(reader.result as any);
      };
      reader.readAsDataURL(file);
      return;
    } else {
      uploadFile(file, extension);
    }
  };

  const uploadFile = async (file: any, extension: any) => {
    try {
      setUploading(true);

      if (type === 'image' && resize) {
        const config = {
          file,
          maxSize: 1000,
          extension,
        };
        file = await resizeImage(config);
        setImageAwaitingCrop?.(null);
      }
      let uploadUrl = await upload(uploadCategory, uploadType, file);
      if (uploadUrl.includes('/svgs/')) {
        // TODO: default is the theme name. Replace with clinic theme.
        uploadUrl = uploadUrl
          .replace('/svgs/', '/default-theme/')
          .replace('.svg', '.png');
      }
      if (type === 'image') {
        await sleep(5000);
      } else if (type === 'video') {
        onChangeStatus?.('processing');
      }
      onChange(uploadUrl);
      setTemp(null);
      setUploading(false);
    } catch (err) {
      console.error(err);
      setUploading(false);
      if (inputFileRef.current) {
        inputFileRef.current.value = '';
      }
    }
  };

  useEffect(() => {
    let isDestroyed = false;
    let interval: NodeJS.Timeout;
    const doIt = async () => {
      if (value && (status === 'uploading' || status === 'processing')) {
        interval = setInterval(async () => {
          try {
            const statusGot = await getStatus(value);
            if (
              statusGot?.status === 'complete' ||
              statusGot?.status === 'failed'
            ) {
              if (interval) {
                clearInterval(interval);
              }
              if (!isDestroyed) {
                onChangeStatus?.(statusGot.status);
              }
            }
          } catch (err) {
            if (interval) {
              clearInterval(interval);
            }
            console.error({ err });
            if (!isDestroyed) {
              onChangeStatus?.('failed');
            }
          }
        }, 5000);
      }
    };
    doIt();
    return () => {
      isDestroyed = true;
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [status, value, getStatus, onChangeStatus, onChange]);

  const onClickReplace = () => {
    if (disabled) {
      return;
    }
    onChange(null);
    onChangeStatus?.('');
  };

  const getCroppedFileData = async (upload = false) => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    const croppedData = cropper.getCroppedCanvas();
    const blob: File = await new Promise((resolve) =>
      croppedData.toBlob((blob: any) => {
        if (blob) {
          blob.name = blobName;
        }
        resolve(blob);
      }),
    );
    const extension = getExtension(blobName || '');
    setImageAwaitingCrop?.({ blob, extension });

    if (upload) {
      uploadFile(blob, extension);
    }
  };
  return (
    <div className={className}>
      <label className="text-sm font-medium text-gray-700 dark:text-darkGray-200 flex justify-start items-center gap-4">
        <span>{label}</span>
        {temp && (
          <span className="font-medium text-primary-600 hover:text-primary-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500 cursor-pointer">
            Select new image
            <input
              id={`upload-${name}`}
              name={`upload-${name}`}
              type="file"
              className="sr-only"
              onSelect={onChangeInput}
            />
          </span>
        )}
        {value && (
          <span
            onClick={onClickReplace}
            className="font-medium text-primary-600 hover:text-primary-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500 cursor-pointer"
          >
            Replace file
          </span>
        )}
        {customLabel && (
          <span
            onClick={onClickCustom}
            className="font-medium text-primary-600 hover:text-primary-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500 cursor-pointer"
          >
            {customLabel}
          </span>
        )}
      </label>
      {value &&
      status !== 'uploading' &&
      status !== 'processing' &&
      status !== 'failed' ? (
        <div className="w-full h-auto flex">
          <UploadedItem url={value} type={type} />
        </div>
      ) : temp && square ? (
        <div className="flex justify-center bg-transparent flex-col w-auto">
          <Cropper
            src={temp}
            style={{
              width: '100%',
              display: 'block',
              background: 'transparent',
            }}
            guides
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive
            autoCropArea={1}
            checkOrientation={false}
            highlight
            zoomOnWheel={false}
            preview=""
            aspectRatio={1}
            zoomable={false}
            crop={() => getCroppedFileData(false)}
            ref={cropperRef}
          />
          <span
            onClick={() => getCroppedFileData(true)}
            className="font-medium text-primary-600 hover:text-primary-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500 cursor-pointer"
          >
            Upload cropped image
          </span>
          {uploading ? (
            <Loading color="text-gray-400" size={12} className="mx-auto" />
          ) : null}
        </div>
      ) : (
        <div
          className={[
            'mt-2 border-2 border-dashed rounded-md px-6 pt-5 pb-6 flex justify-center',
            errors
              ? 'border-red-300'
              : 'border-gray-300 dark:border-darkGray-600',
          ].join(' ')}
        >
          <div className="space-y-1 text-center">
            {uploading || status === 'uploading' || status === 'processing' ? (
              <Loading
                color="text-gray-400"
                size={12}
                className="mx-auto mb-2"
              />
            ) : (
              <svg
                className="mx-auto h-12 w-12 text-gray-500 dark:text-darkGray-600"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            <div className="flex text-sm text-gray-500 dark:text-darkGray-600 mt-6">
              <label
                htmlFor={`upload-${name}`}
                className="w-full relative cursor-pointer bg-white dark:bg-darkGray-700 rounded-md font-medium text-primary-600 hover:text-primary-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
              >
                {uploading || status === 'uploading' ? (
                  <span>Uploading file</span>
                ) : status === 'processing' ? (
                  <span>Processing file</span>
                ) : (
                  <>
                    <span>Upload a file</span>
                    <input
                      id={`upload-${name}`}
                      name={`upload-${name}`}
                      type="file"
                      className="sr-only"
                      onChange={onChangeInput}
                      ref={inputFileRef}
                    />
                  </>
                )}
              </label>
            </div>
            <p className="text-xs text-gray-500 dark:text-darkGray-600">
              {uploading || status === 'uploading' ? (
                <>Please wait while your file is being uploaded.</>
              ) : status === 'processing' ? (
                <>
                  Your video is processing. You can save now and the video will
                  be available shortly.
                </>
              ) : (
                <>
                  {fileTypes ? fileTypes.join(', ') : ''}
                  {fileSizeLimit ? ` up to ${fileSizeLimit}MB` : ''}
                </>
              )}
            </p>
            {status === 'failed' && !uploading && (
              <p className="text-red-500 text-xs">
                The file you attempted to upload failed conversion. Please try
                processing to mp4 before uploading for better results.
              </p>
            )}
          </div>
        </div>
      )}
      <FieldErrors errors={errors} />
    </div>
  );
};

export default Uploader;
