import { ConfirmModal, OpenClosedStates } from '@chiroup/components';
import { ClinicLocation } from '@chiroup/core';
import { EmailValidator, useForm } from '@chiroup/hooks';
import { ToastContext, ToastTypes } from '../../../../contexts/toast.context';
import React, { useContext, useEffect, useState } from 'react';
import Button, { ButtonColors } from '../../../common/Button';
import SlideOver from '../../../common/SlideOver';
import ClinicLocationDetailForm from './ClinicLocationDetailForm';

const validation = {
  //Todo: add timezone validation when scheduling is implemented
  // timezone: {
  //   required: {
  //     message: 'Time zone is required.',
  //   },
  // },
  country: {
    required: {
      message: 'Country is required.',
    },
  },
  phone: {
    function: {
      value: (value: Partial<ClinicLocation>) => {
        const { phone } = value;
        if (
          phone?.[1] === '1' &&
          phone?.split(' ')?.[0]?.length === 6 &&
          phone?.length < 13
        ) {
          return 'Phone number must be 7 digits.';
        } else if (phone?.[1] === '1' && phone?.length < 13) {
          return 'Phone number must be 10 digits.';
        }
        return false;
      },
    },
  },
  fax: {
    function: {
      value: (value: Partial<ClinicLocation>) => {
        const { fax } = value;
        if (
          fax?.[1] === '1' &&
          fax?.split(' ')?.[0]?.length === 6 &&
          fax?.length < 13
        ) {
          return 'Fax must be 7 digits.';
        } else if (fax?.[1] === '1' && fax?.length < 13) {
          return 'Fax must be 10 digits.';
        }
        return false;
      },
    },
  },
  email: {
    pattern: EmailValidator,
  },
  zip: {
    function: {
      value: (value: Partial<ClinicLocation>) => {
        const { zip, country } = value;
        //Zip is not a required field here right now so...
        // if (!zip)
        //   return country === 'USA'
        //     ? 'Zip code is required.'
        //     : 'Postal code is required.';
        if (country === 'USA' && !!zip) {
          if (zip.length !== 5 && zip.length !== 10) {
            return 'Zip code must be 5 or 9 digits.';
          }
        }
        return false;
      },
    },
  },
};

type Props = {
  location: Partial<ClinicLocation> | null;
  close: () => void;
  onSubmit: (val: Partial<ClinicLocation>) => Promise<ClinicLocation>;
  onSuccess: (val: Partial<ClinicLocation>) => void;
  removeLocation: () => Promise<void>;
  removingLocation: boolean;
};

const ClinicLocationDetail: React.FC<Props> = ({
  location,
  close,
  onSubmit,
  onSuccess,
  removeLocation,
  removingLocation,
}) => {
  const { createToast } = useContext(ToastContext);
  const [deleteOpen, setDeleteOpen] = useState(OpenClosedStates.Closed);
  const {
    value,
    onChange,
    errors,
    registerSubmit,
    isDirty,
    isSubmitting,
    setValue,
  } = useForm<ClinicLocation>(
    location || {
      country: 'USA',
    },
    validation,
  );

  useEffect(() => {
    if (location !== null) {
      setValue(location);
    }
  }, [location, setValue]);

  const onFail = (err: any) => {
    createToast({
      title: 'Error',
      description: (
        <span>
          {err?.response?.data?.message || 'Failed to save location.'}
        </span>
      ),
      type: ToastTypes.Fail,
      duration: 5000,
    });
  };

  return (
    <SlideOver
      title={location?.ID ? 'Edit Location' : 'Add Location'}
      slideOverState={
        location ? OpenClosedStates.Open : OpenClosedStates.Closed
      }
      updateSlideOverState={close}
      buttons={
        <>
          <Button text="Close" onClick={close} color={ButtonColors.plain} />
          {!!value.ID && (
            <Button
              text="Delete"
              onClick={() => setDeleteOpen(OpenClosedStates.Open)}
              color={ButtonColors.plain}
            />
          )}
          <Button
            text="Save"
            disabled={!isDirty}
            loading={isSubmitting}
            onClick={registerSubmit(onSubmit, {
              onSuccess,
              onFail,
            })}
          />
        </>
      }
    >
      <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5">
        <ClinicLocationDetailForm
          value={value}
          onChange={onChange}
          fieldErrors={errors.fieldErrors}
        />
      </div>
      <ConfirmModal
        isOpen={deleteOpen === OpenClosedStates.Open}
        confirm={async () => {
          try {
            await removeLocation();
            setDeleteOpen(OpenClosedStates.Closed);
            close();
          } catch (err) {
            setDeleteOpen(OpenClosedStates.Closed);
          }
        }}
        close={() => setDeleteOpen(OpenClosedStates.Closed)}
        loading={removingLocation}
        confirmText="Delete"
        description="Are you sure you want to delete this location?"
      />
    </SlideOver>
  );
};

export default ClinicLocationDetail;
