import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  title: string;
  description: string;
  link: {
    text: string;
    url: string;
  };
};

const jokes = [
  {
    line1: 'I never believed that chiropractors could solve my back problems….',
    line2: '2 weeks later I stand corrected.',
  },
  {
    line1: 'What do you call a chiropractor that loves his job?',
    line2: 'A crack addict',
  },
  {
    line1: 'Anyone need some old copies of Chiropractor Monthly?',
    line2: 'We have a lot of back issues.',
  },
  {
    line1:
      '"But Quasimodo, what makes you think you need to see a chiropractor?"',
    line2: '"Oh it’s just a hunch…"',
  },
  {
    line1: 'Why is a chiropractor a good interrogator?',
    line2: 'They always get the suspect to crack',
  },
  {
    line1: 'I broke up with my chiropractic girlfriend.',
    line2: 'She was too manipulative',
  },
  {
    line1: 'My chiropractor is serious as hell.',
    line2: 'But he still cracks me up',
  },
  {
    line1: 'How many chiropractors does it take to change a light bulb?',
    line2: 'Just one but it will take about 8-10 visits',
  },
  {
    line1:
      'What do you call two male chiropractors who’ve got each others back?',
    line2: 'Vertebros',
  },
  {
    line1: 'Why did the chiropractor go bankrupt?',
    line2: 'They owed too much in back taxes',
  },
];

const ErrorPage: React.FC<Props> = ({ title, description, link }) => {
  const random = Math.floor(Math.random() * jokes.length);
  const joke = jokes[random];

  return (
    <div className="flex items-center justify-center px-8">
      <div className="max-w-4xl py-8">
        <div className="flex flex-row justify-between">
          <div>
            <h2 className="text-4xl tracking-tight leading-8 font-extrabold text-gray-600 sm:text-5xl sm:leading-none md:text-6xl flex-row">
              Oops!
            </h2>
            <h3 className="text-xl tracking-tight leading-6 font-extrabold text-gray-400 sm:text-2xl sm:leading-none md:text-4xl mt-2">
              {title ||
                `This is embarrassing - it looks like something went wrong.`}
            </h3>
          </div>
          <div>
            <svg
              className="h-20 w-20 text-red-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
        <p className="mt-6 text-1xl text-gray-600 sm:text-2xl font-light">
          <span className="font-medium">Why did this happen? </span>
          {description ||
            `Well, it
          could be a lot of things. If we really knew what the problem was, we
          would let you know - but unfortunately, sometimes you just can't find
          the cause of the pain.`}
        </p>
        <p className="mt-6 text-1xl text-gray-600 sm:text-2xl font-light">
          <span className="font-medium">Pro tip:</span> A good way to fix most
          issues on a website is to refresh the browser and see if that fixes
          it. Don&apos;t you wish chiropractic care was that easy?
        </p>

        <div className="bg-white sm:rounded-lg mt-8">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-xl leading-9 font-extrabold tracking-tight text-gray-600 sm:text-4xl sm:leading-10">
              {joke.line1}
            </h3>
            <div className="mt-2 text-xl leading-9 font-medium tracking-tight text-gray-400 sm:text-4xl sm:leading-10">
              {joke.line2}
            </div>
            <div className="mt-12 border-t border-gray-300 pt-4">
              <div>Think you have a better chiropractic joke?</div>
              <div>
                <span className="text-primary-600 hover:text-primary-500">
                  Send it in
                </span>{' '}
                and we&apos;ll make an{' '}
                <span className="italic text-gray-600">adjustment</span> to our{' '}
                <span className="italic text-gray-600">back</span>log of{' '}
                <span className="italic text-gray-600">crack</span>ups. (haha,
                hrm...)
              </div>
            </div>
          </div>
        </div>

        <p className="mt-6 text-1xl sm:text-2xl font-light text-primary-600 hover:text-primary-500">
          <Link to={link?.url || '/'} className="flex items-center">
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="ml-4">{link?.text || 'Go back home'}</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ErrorPage;
