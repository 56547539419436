import { ChiroUpAPI } from '@chiroup/client-core';

const flagService = () => {
  const list = async () => {
    return ChiroUpAPI.get('api', `/flags`, {});
  };

  const update = async (flag: string, group: string, value: boolean) => {
    return ChiroUpAPI.post('api', `/flags/${flag}`, {
      body: {
        group,
        value,
      },
    });
  };

  return { list, update };
};

export default flagService();
