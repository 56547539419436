import { User } from '@chiroup/core';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import Header from '../../layout/Header';
import UserClinics from './UserClinics';
import UserGeneral from './UserGeneral';
import UserTabs from './UserTabs';

const UserContainer = () => {
  const { userId } = useParams();
  const { data, update, refetch, isUpdating, del } = useUser(userId);
  const { pathname } = useLocation();
  const [user, setUser] = useState<Partial<User>>();
  const [clinics, setClinics] = useState<any>();

  useEffect(() => {
    if (data) {
      const { clinics: userClinics, ...theUser } = data;
      setUser(theUser);
      setClinics(userClinics);
    }
  }, [data]);

  return user ? (
    <>
      <Header
        title={`${user.title || ''} ${user.fname || ''} ${user.lname || ''}${
          user.degree ? `, ${user.degree}` : ''
        }`}
      />
      <UserTabs active={pathname} id={userId || ''} />
      <div className="px-6">
        <Routes>
          <Route
            path="/"
            element={
              <UserGeneral
                user={user}
                update={update}
                isUpdating={isUpdating}
                del={del}
                refetch={refetch}
              />
            }
          />
          <Route
            path={`/clinics`}
            element={
              <UserClinics
                clinics={clinics}
                userID={user.ID}
                refetch={refetch}
              />
            }
          />
        </Routes>
      </div>
    </>
  ) : null;
};

export default UserContainer;
