import { Marketing } from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';

const marketingAddOnService = () => {
  const create = async (val: Partial<Marketing>) => {
    return ChiroUpAPI.post('api', `/marketing-add-on`, {
      body: val,
    });
  };
  const deleteMarketing = async (id: number) => {
    return ChiroUpAPI.del('api', `/marketing-add-on/${id}`, {});
  };
  const list = async (params: {
    search?: string;
    limit?: number;
    skip?: number;
  }) => {
    return ChiroUpAPI.get('api', `/marketing-add-on`, {
      queryParams: params,
    });
  };

  const update = async (id: number, val: Partial<Marketing>) => {
    const { id: _id, ...rest } = val;
    return ChiroUpAPI.put('api', `/marketing-add-on/${id}`, {
      body: rest,
    });
  };

  return {
    create,
    list,
    update,
    deleteMarketing,
  };
};

export default marketingAddOnService();
