import { dashboardLinks } from '../../data/dashboardLinks';
import Header from '../layout/Header';
import DashboardSection from './DashboardSection';

const Dashboard = () => {
  return (
    <>
      <Header title="Statistics" />
      <div>
        {dashboardLinks.map((section, i) => (
          <DashboardSection
            title={section.title}
            links={section.links}
            key={section.title}
          />
        ))}
      </div>
    </>
  );
};

export default Dashboard;
