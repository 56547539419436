import React from 'react';

type Props = {
  index: number;
  newItem?: boolean;
  children: React.ReactNode;
};

const ListItem: React.FC<Props> = ({ children, index, newItem = false }) => {
  return (
    <li
      className={[
        'relative pl-4 pr-6 py-5 sm:py-6 sm:pl-6 dark:border-darkGray-700',
        index % 2
          ? newItem
            ? 'bg-primary-100 dark:bg-primary-800 hover:bg-primary-300 dark:hover:bg-primary-700'
            : 'bg-gray-100 dark:bg-darkGray-600 hover:bg-gray-300 dark:hover:bg-darkGray-500'
          : newItem
            ? 'bg-primary-200 dark:bg-primary-900 hover:bg-primary-300 dark:hover:bg-primary-700'
            : 'bg-gray-50 dark:bg-darkGray-700 hover:bg-gray-300 dark:hover:bg-darkGray-500',
      ].join(' ')}
    >
      {children}
    </li>
  );
};

export default ListItem;
