export const dashboardLinks = [
  {
    title: 'Subscriber metrics',
    links: [
      {
        icon: 'salesforce-soh',
        link: 'https://chiroup2020.lightning.force.com/lightning/o/Dashboard/home?queryScope=mru',
      },
      {
        icon: 'salesforce-sd',
        link: '/',
      },
      {
        icon: 'chameleon',
        link: 'https://app.trychameleon.com/tours',
      },
    ],
  },
  {
    title: 'Sales & Marketing',
    links: [
      {
        icon: 'semrush',
        link: 'https://www.semrush.com/projects/',
      },
      {
        icon: 'crazyegg',
        link: 'https://app.crazyegg.com/login',
      },
      {
        icon: 'blog',
        link: 'https://docs.google.com/spreadsheets/d/1dNO1c36POUIU_CGiQ09HWQA0PO9ZV2MmeSbhV0YCSe0/edit#gid=999825327',
      },
      {
        icon: 'monday',
        link: 'https://chiroup.monday.com/boards/1858674212',
      },
      {
        icon: 'zeemaps',
        link: 'https://www.zeemaps.com/',
      },
      {
        icon: 'squarespace',
        link: 'https://chiroup.squarespace.com/config/analytics',
      },
      {
        icon: 'google-analytics',
        link: 'https://analytics.google.com/analytics/web',
      },
      {
        icon: 'helpjuice',
        link: 'https://chiroup.helpjuice.com/dashboard',
      },
    ],
  },
  {
    title: 'Financial',
    links: [
      {
        icon: 'financials',
        link: 'https://docs.google.com/spreadsheets/d/1RHN8l2q1BVGvEjW3n-_5WL2jBqbQlgAp/edit#gid=2082523362',
      },
      {
        icon: 'stripe',
        link: 'https://dashboard.stripe.com/dashboard',
      },
    ],
  },
];
