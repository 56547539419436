import React from 'react';

type Props = {
  url: string;
  logo?: boolean;
};

const UploadedImage: React.FC<Props> = ({ url }) => {
  return <img src={url} className="h-auto w-full" alt={url} />;
};

export default UploadedImage;
