import { Input, OpenClosedStates } from '@chiroup/components';
import { useForm } from '@chiroup/hooks';
import { useContext, useEffect, useState } from 'react';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import researchService from '../../services/research/research.service';
import Button, { ButtonColors } from '../common/Button';
import ConfirmModal from '../common/ConfirmModal';
import SlideOver from '../common/SlideOver';
import ResearchEditPanelFormElements from './ResearchEditPanelFormElements';

type Props = {
  defaultValues?: Partial<any>;
  slideOverState: OpenClosedStates;
  updateSlideOverState: (val: OpenClosedStates) => void;
  refetch: () => void;
};

const validation = {
  link: {
    required: {
      message: 'Article must have a link',
    },
  },
};

const ResearchEditPanel = ({
  defaultValues,
  slideOverState,
  updateSlideOverState,
  refetch,
}: Props) => {
  const { value, onChange, patchValue, isDirty, registerSubmit, errors } =
    useForm<Partial<any>>(defaultValues || {}, validation);
  const { createToast } = useContext(ToastContext);
  const [saving, setSaving] = useState(false);
  const [newArticle, setNewArticle] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [deleteModalState, setDeleteModalState] = useState(
    OpenClosedStates.Closed,
  );
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (!defaultValues?.ID) {
      setNewArticle(true);
    } else {
      setNewArticle(false);
    }
  }, [defaultValues?.ID]);

  const onSubmit = async (val: Partial<any>) => {
    setSaving(true);
    if (!newArticle) {
      return researchService.update(val);
    } else {
      return researchService.create(val);
    }
  };

  const onSuccess = async (val: any) => {
    setSaving(false);
    updateSlideOverState(OpenClosedStates.Closed);
    refetch();
  };

  const onDelete = async () => {
    if (!defaultValues?.ID) {
      return;
    }
    try {
      setIsDeleting(true);
      await researchService.del(defaultValues.ID);
      setDeleteModalState(OpenClosedStates.Closed);
      setIsDeleting(false);
      refetch();
      updateSlideOverState(OpenClosedStates.Closed);
    } catch (err) {
      setIsDeleting(false);
      createToast({
        title: 'Error!',
        description: <>Failed to delete research!</>,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    }
  };

  return (
    <SlideOver
      title="Edit article"
      slideOverState={slideOverState}
      updateSlideOverState={updateSlideOverState}
      buttons={
        <>
          <Button
            text="Close"
            onClick={() => updateSlideOverState(OpenClosedStates.Closed)}
            color={ButtonColors.plain}
          />
          {!!defaultValues?.ID && (
            <Button
              text="Delete"
              disabled={saving}
              loading={isDeleting}
              onClick={() => setDeleteModalState(OpenClosedStates.Open)}
              color={ButtonColors.red}
            />
          )}
          <Button
            text="Save"
            disabled={!isDirty || saving}
            loading={saving}
            onClick={registerSubmit(onSubmit, { onSuccess })}
          />
        </>
      }
    >
      <form>
        <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-300">
          <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
            <Input
              label="Scholar search"
              type="text"
              name="scholarSearch"
              value={searchString}
              onChange={(e) => setSearchString(e)}
              onEnter={() => {
                window.open(
                  `https://scholar.google.com/scholar?hl=en&as_sdt=0%2C14&q=${searchString}`,
                  '_blank',
                );
              }}
              className="col-span-4"
              clickIcon
              iconRight
              icon={
                <div className="bg-white h-6 -mr-2">
                  <a
                    href={`https://scholar.google.com/scholar?hl=en&as_sdt=0%2C14&q=${searchString}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary-600"
                  >
                    Search
                  </a>
                </div>
              }
            />
            <ResearchEditPanelFormElements
              fieldErrors={errors.fieldErrors}
              value={value}
              onChange={onChange}
              patchValue={patchValue}
            />
          </div>
        </div>
      </form>{' '}
      <ConfirmModal
        state={deleteModalState}
        confirm={onDelete}
        close={() => setDeleteModalState(OpenClosedStates.Closed)}
      />
    </SlideOver>
  );
};

export default ResearchEditPanel;
