/* eslint-disable react/jsx-no-useless-fragment */
import React, { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MenuItem } from '../../data/MenuItem.interface';
import SidenavIcon from './SidenavIcon';

type Props = {
  path: string;
  currentPath: string;
  icon: ReactNode;
  title: string;
  menuItems?: MenuItem[];
  external?: boolean;
};

const SidenavMenuItem: React.FC<Props> = ({
  path,
  currentPath,
  icon,
  title,
  menuItems,
  external,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [topLevelCurrentPath, setTopLevelCurrentPath] = useState<string>();
  const [secondLevelCurrentPath, setSecondLevelCurrentPath] =
    useState<string>();

  useEffect(() => {
    const currentPathSplit = currentPath?.split('/');
    const topLevel = currentPathSplit[1] || '';
    const secondLevel = currentPathSplit[2] || '';
    setTopLevelCurrentPath(topLevel);
    setSecondLevelCurrentPath(
      secondLevel ? `${topLevel}/${secondLevel}` : undefined,
    );
  }, [currentPath]);

  useEffect(() => {
    const currentPathSplit = currentPath?.split('/');
    const topLevel = currentPathSplit[1] || '';
    if (topLevel === path) {
      setExpanded(true);
    }
  }, [currentPath, path]);

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <>
      {menuItems?.length ? (
        <div className="space-y-1">
          <button
            className={[
              'group w-full flex items-center pl-2 pr-1 py-2 text-sm font-medium rounded-md bg-white dark:bg-darkGray-800 text-gray-600 dark:text-darkGray-400 hover:text-gray-900 hover:bg-gray-50 dark:hover:bg-darkGray-700 focus:outline-none focus:ring-2 focus:ring-primary-500',
              topLevelCurrentPath === path
                ? 'bg-gray-100 dark:bg-darkGray-700 dark:text-darkGray-50 dark:hover:text-gray-50'
                : '',
            ].join(' ')}
            onClick={toggleExpanded}
          >
            <SidenavIcon>{icon}</SidenavIcon>
            {title}
            <svg
              className={[
                'ml-auto h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
                expanded ? 'text-gray-400 rotate-90' : 'text-gray-300',
              ].join(' ')}
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
            </svg>
          </button>
          {expanded && (
            <div className="space-y-1">
              {menuItems.map((menuItem, i) => (
                <Link
                  key={i}
                  to={`/${path}/${menuItem.path}`}
                  className={[
                    'group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50',
                    secondLevelCurrentPath === `${path}/${menuItem.path}`
                      ? 'bg-gray-100 text-primary-600 hover:text-primary-600 dark:bg-darkGray-700 dark:text-darkGray-50 dark:hover:text-gray-50'
                      : '',
                  ].join(' ')}
                >
                  {menuItem.title}
                </Link>
              ))}
            </div>
          )}
        </div>
      ) : (
        <>
          {external ? (
            <a
              href="/"
              rel="noopener noreferrer"
              target="_blank"
              className="group flex items-center px-2 py-2 text-sm leading-5 font-medium rounded-md text-gray-700 dark:text-darkGray-400 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150 hover:bg-gray-100 hover:text-gray-900 dark:hover:text-gray-400 dark:focus:bg-darkGray-700 dark:hover:bg-darkGray-700"
            >
              <SidenavIcon active={topLevelCurrentPath === path}>
                {icon}
              </SidenavIcon>
              {title}
            </a>
          ) : (
            <Link
              to={`/${path}`}
              className={[
                'group flex items-center px-2 py-2 text-sm leading-5 font-medium rounded-md text-gray-700 dark:text-darkGray-400 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150 hover:bg-gray-100 hover:text-gray-900 dark:hover:text-gray-400 dark:focus:bg-darkGray-700 dark:hover:bg-darkGray-700',
                topLevelCurrentPath === path
                  ? 'bg-gray-100 text-primary-600 hover:text-primary-600 dark:bg-darkGray-700 dark:text-darkGray-50 dark:hover:text-gray-50'
                  : '',
              ].join(' ')}
            >
              <SidenavIcon active={topLevelCurrentPath === path}>
                {icon}
              </SidenavIcon>
              {title}
            </Link>
          )}
        </>
      )}
    </>
  );
};

export default SidenavMenuItem;
