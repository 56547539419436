import { QueryFunctionContext, useQuery } from 'react-query';
import studentService from '../services/student.service';
import { useState } from 'react';

const getQuery = () => {
  return async (context: QueryFunctionContext) => {
    return studentService.list();
  };
};

const useStudentVerification = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { status, data, error, isFetching, refetch } = useQuery<any[]>(
    ['students'],
    getQuery(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const accept = async (id: string) => {
    setIsSubmitting(true);
    await studentService.update(id, 'accept');
    await refetch();
    setIsSubmitting(false);
  };

  const reject = async (id: string) => {
    setIsSubmitting(true);
    await studentService.update(id, 'reject');
    await refetch();
    setIsSubmitting(false);
  };

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
    isSubmitting,
    accept,
    reject,
  };
};

export default useStudentVerification;
