import React, { Dispatch, lazy, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';

const UserDropdown = lazy(() => import('./UserDropdown'));

type Props = {
  toggleSidenav: () => void;
  sidenavOpen: boolean;
  setShowNotifications?: Dispatch<SetStateAction<boolean>>;
  newNotifications?: boolean;
  resetNewNotifications?: () => void;
  toggleNotifications?: () => void;
};

const NavBar: React.FC<Props> = ({
  toggleSidenav,
  sidenavOpen,
  setShowNotifications,
  newNotifications,
  resetNewNotifications,
  toggleNotifications,
}) => {
  // const { getItem } = useLocalStorage();
  // const [showFeedback, setShowFeedback] = useState(false);

  // useEffect(() => {
  //   const openOrNot = getItem('notificationsOpen');
  //   if (openOrNot) {
  //     setShowNotifications(openOrNot);
  //   }
  // }, [getItem, setShowNotifications]);

  // const toggleFeedback = () => {
  //   setShowFeedback((prev) => !prev);
  // };

  return (
    <nav className="flex-shrink-0 bg-white border-gray-300 dark:bg-darkGray-900  dark:border-darkGray-700 border-b print:hidden">
      <div className="px-2 sm:px-4 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="flex items-center px-2 lg:w-52">
            <div className="flex-shrink-0 print:hidden">
              <Link to="/">
                <Logo />
              </Link>
            </div>
          </div>
          <div className="flex-1 flex">{/* <Search /> */}</div>
          <div className="flex lg:hidden">
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-600 focus:outline-none focus:bg-gray-600 focus:text-white transition duration-150 ease-in-out"
              aria-label="Main menu"
              aria-expanded="false"
              onClick={toggleSidenav}
            >
              <svg
                className={['h-6 w-6', sidenavOpen ? 'hidden' : 'block'].join(
                  ' ',
                )}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
              <svg
                className={['h-6 w-6', sidenavOpen ? 'block' : 'hidden'].join(
                  ' ',
                )}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="hidden lg:block lg:w-32">
            <div className="flex items-center justify-end">
              {/* <div className="ml-2 relative flex-shrink-0">
                  <div className="flex text-sm rounded-full text-white focus:outline-none focus:shadow-solid transition duration-150 ease-in-out">
                    <span className="inline-block relative">
                      <button
                        className="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-gray-500 focus:outline-none transition duration-150 ease-in-out"
                        aria-label="Notifications"
                        onClick={toggleNotifications}
                      >
                        <BellIcon className="h-8 w-8" />
                      </button>
                      {newNotifications && (
                        <span className="absolute top-1 right-1 block h-2.5 w-2.5 rounded-full text-white shadow-solid bg-pink-400">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-pink-400 opacity-75 right-0" />
                        </span>
                      )}
                    </span>
                  </div>
                </div> */}
              <UserDropdown />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
