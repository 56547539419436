import { resizeImage } from '../components/common/helpers/resizeImage';
import uploadService from '../services/upload.service';

const useUpload = () => {
  const upload = (category: string, type: string, file: any) => {
    return uploadService.upload(category, type, file);
  };

  const getStatus = (file: string) => {
    return uploadService.status(file);
  };
  const autoCropUpload = async (
    file: any,
    extension: any,
    uploadCategory: string,
    uploadType: string,
  ) => {
    const config = {
      file,
      maxSize: 1000,
      extension,
    };
    file = await resizeImage(config);
    return await upload(uploadCategory, uploadType, file);
  };

  return {
    upload,
    getStatus,
    autoCropUpload,
  };
};

export default useUpload;
