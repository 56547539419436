import { useQuery } from 'react-query';
import { CustomizationListItem } from '../components/practice-resources/forms-library/CustomizationListItem.type';
import testService from '../services/test.service';

const useTests = () => {
  const { data, isFetching } = useQuery<{
    data: CustomizationListItem[];
  }>('test', () => testService.list(), {
    refetchOnWindowFocus: false,
  });

  return { isFetching, data };
};

export default useTests;
