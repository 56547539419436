import {
  Input,
  InputMasked,
  Phone,
  Select,
  Textarea,
} from '@chiroup/components';
import {
  BASE_ROLES,
  FormFieldErrors,
  GLOBAL_SUFFIX_OPTIONS,
  ROLES,
  SORTED_SCHOOLS,
  User,
  ValueOf,
  isEmpty,
} from '@chiroup/core';
import dayjs from 'dayjs';
import React from 'react';
import { TITLES } from '../../../constants';

type Props = {
  fieldErrors?: FormFieldErrors;
  value: Partial<User>;
  onChange: (key: keyof Partial<User>) => (val: ValueOf<User>) => void;
  patchValue: (values: Partial<User>) => void;
  clinicID?: number;
  isEHR?: boolean;
};

const statuses = [
  { value: 'active', text: 'Active' },
  { value: 'inactive', text: 'Inactive' },
  // { value: 'Pending Basic', text: 'Pending Basic' },
  // { value: 'Pending Plus', text: 'Pending Plus' },
  // { value: 'Pending Premium', text: 'Pending Premium' },
  // { value: 'Pending Trial', text: 'Pending Trial' },
];

const UserGeneralFormElements: React.FC<Props> = ({
  fieldErrors,
  value,
  onChange,
  patchValue,
  clinicID,
  isEHR,
}) => {
  return (
    <>
      <Select
        name="title"
        className="col-span-2"
        label="Title *"
        value={value.title}
        onChange={onChange('title')}
        errors={fieldErrors?.title}
        options={TITLES}
        limit={1}
      />
      <Input
        name="fname"
        className="col-span-2"
        label="First name *"
        value={value.fname}
        onChange={onChange('fname')}
        errors={fieldErrors?.fname}
      />
      <Input
        name="middleInitial"
        className="col-span-2"
        label="Middle initial"
        maxLength={1}
        value={value.middleInitial}
        onChange={onChange('middleInitial')}
        errors={fieldErrors?.middleInitial}
      />
      <Input
        name="lname"
        className="col-span-2"
        label="Last name *"
        value={value.lname}
        onChange={onChange('lname')}
        errors={fieldErrors?.lname}
      />
      <Select
        name="suffix"
        className="col-span-2"
        label="Suffix"
        value={value.suffix}
        onChange={onChange('suffix')}
        errors={fieldErrors?.suffix}
        options={GLOBAL_SUFFIX_OPTIONS}
        limit={1}
      />
      <Input
        name="email"
        className="col-span-2"
        label="Email *"
        value={value.email}
        onChange={onChange('email')}
        errors={fieldErrors?.email}
      />
      <Phone
        name="phone"
        className="col-span-2"
        label="Phone"
        value={value.phone}
        onChange={onChange('phone')}
        errors={fieldErrors?.phone}
      />

      {value.ID && (
        <Input
          name="referral"
          className="col-span-2"
          label="Referral"
          value={value.referral}
          onChange={onChange('referral')}
          errors={fieldErrors?.referral}
        />
      )}

      <Input
        name="referralCode"
        className="col-span-2"
        label="Referral code"
        value={value.referralCode}
        onChange={onChange('referralCode')}
        errors={fieldErrors?.referralCode}
      />
      {((isEmpty(clinicID) && !value.ID) || value.schoolName) && (
        <>
          <Select
            className="col-span-2"
            name="schoolName"
            label="School name"
            options={SORTED_SCHOOLS}
            valueField="name"
            labelField="name"
            value={value.schoolName}
            onChange={onChange('schoolName')}
            errors={fieldErrors?.schoolName}
            limit={1}
          />
          <InputMasked
            name="GraduationDate"
            className="col-span-2"
            label="Graduation date"
            value={
              value.GraduationDate && value?.GraduationDate?.length < 8
                ? value.GraduationDate
                : value.GraduationDate
                  ? dayjs(value.GraduationDate).format('MM/YYYY')
                  : ''
            }
            onChange={onChange('GraduationDate')}
            errors={fieldErrors?.GraduationDate}
            patternFormat="##/####"
            placeholder="MM/YYYY"
          />
        </>
      )}

      {value.ID && (
        <Select
          name="Status"
          options={statuses}
          value={value.status}
          className="col-span-2"
          onChange={onChange('status')}
          limit={1}
          label="Status"
        />
      )}

      {!isEmpty(clinicID) && (
        <Select
          name="role"
          className="col-span-2"
          options={isEHR ? ROLES : BASE_ROLES}
          label="Role"
          value={value.role}
          onChange={onChange('role')}
        />
      )}
      {value.ID && (
        <Textarea
          name="notes"
          className="col-span-4"
          label="Notes"
          value={value.notes}
          onChange={onChange('notes')}
          errors={fieldErrors?.notes}
        />
      )}
    </>
  );
};

export default UserGeneralFormElements;
