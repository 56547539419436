import { OpenClosedStates } from '@chiroup/components';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContext, ToastTypes } from '../../../../contexts/toast.context';
import Button, { ButtonColors } from '../../../common/Button';
import Modal from '../../../common/Modal';

type Props = {
  state: OpenClosedStates;
  setState: React.Dispatch<React.SetStateAction<OpenClosedStates>>;
  del: () => Promise<void>;
};

const MarketingDeleteModal: React.FC<Props> = ({ state, setState, del }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { createToast } = useContext(ToastContext);
  const navigate = useNavigate();

  const onDelete = async () => {
    setIsDeleting(true);
    try {
      await del();
      setState(OpenClosedStates.Closed);
      createToast({
        title: 'Success!',
        description: <>Successfully deleted user!</>,
        type: ToastTypes.Success,
        duration: 5000,
      });
      navigate('/admin/healthcare-providers');
    } catch (err) {
      createToast({
        title: 'Error!',
        description: <>Failed to delete user!</>,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    }
    setIsDeleting(false);
  };

  return (
    <Modal
      isOpen={state === OpenClosedStates.Open}
      close={() => setState(OpenClosedStates.Closed)}
    >
      <form>
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-darkGray-500">
            <svg
              className="h-6 w-6 text-gray-600 dark:text-darkGray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-lg leading-6 font-medium text-gray-900 dark:text-darkGray-100"
              id="modal-headline"
            >
              Delete user
            </h3>
            <div className="mt-2">
              <p className="text-sm leading-5 text-gray-500 dark:text-darkGray-400">
                Are you sure you wish to delete this user? This will delete all
                of the user data and cannot be undone.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6 grid grid-cols-2 gap-3 grid-flow-row-dense">
          <Button
            text="Close"
            onClick={() => setState(OpenClosedStates.Closed)}
            fullWidth
            color={ButtonColors.plainWithBorder}
          />
          <Button
            text="Delete"
            onClick={onDelete}
            fullWidth
            loading={isDeleting}
            color={ButtonColors.red}
          />
        </div>
      </form>
    </Modal>
  );
};

export default MarketingDeleteModal;
