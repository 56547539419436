import flagService from '../../../services/flag.service';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';

const getQuery = () => {
  return async (context: QueryFunctionContext) => {
    return flagService.list();
  };
};

const useFlags = () => {
  const queryClient = useQueryClient();
  const { status, data, error, isFetching, refetch } = useQuery<any[]>(
    ['flags'],
    getQuery(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const updateFlag = async (
    flag: string,
    flagGroup: string,
    value: boolean,
  ) => {
    const res = await flagService.update(flag, flagGroup, value);
    queryClient.setQueryData(['flags'], res);
    return res;
  };

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
    updateFlag,
  };
};

export default useFlags;
